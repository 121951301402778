@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
#header {
  height: 65px;
  background: rgba(18, 24, 35, 0.7);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 8px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  transition: 0.5s ease-in-out;
}
@media all and (max-width: 640px) {
  #header {
    padding: 8px 20px;
  }
}
#header.background {
  background: rgb(18, 24, 35);
  transition: 0.5s ease-in-out;
}
@media all and (max-width: 1400px) {
  #header ul.links {
    display: none;
  }
}
#header ul.links li.link {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--audrey-font-family);
  position: relative;
}
html[lang=ar] #header ul.links li.link {
  font-size: calc(14px + 1px);
}
#header ul.links li.link.dropDownExist:hover ul.subLinks {
  display: block;
}
#header ul.links li.link ul.subLinks {
  position: absolute;
  width: 15rem;
  top: 60px;
  background-color: var(--primary-bg-color);
  display: none;
  left: 50%;
  transform: translateX(-50%);
}
#header ul.links li.link ul.subLinks li {
  text-align: center;
  padding: unset;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  text-transform: capitalize;
  font-family: var(--lato-font-family);
}
html[lang=ar] #header ul.links li.link ul.subLinks li {
  font-size: calc(14px + 1px);
}
#header ul.links li.link ul.subLinks li:hover {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
#header ul.links li.link ul.subLinks li:hover a {
  color: white;
}
#header ul.links li.link ul.subLinks li a {
  color: var(--light-text-color);
  display: block;
  width: 100%;
  padding: 10px 20px;
}
#header ul.links li.link a {
  cursor: pointer;
  border: 2px solid transparent;
}
#header ul.links li.link span {
  color: white;
  cursor: pointer;
}
#header ul.links li.link.active a.mainLink {
  border-bottom: 2px solid #fff;
  padding-bottom: 4px;
}
#header ul.links li.link.menuIcon {
  position: relative;
  cursor: pointer;
}
#header ul.links li.link.menuIcon div.menuBarlist {
  position: absolute;
  top: 70px;
  width: 15rem;
  right: -10px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}
#header ul.links li.link.menuIcon div.menuBarlist::after {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 9px 15px;
  content: "";
  right: 10px;
  position: absolute;
  top: -8px;
}
#header ul.links li.link.menuIcon div.menuBarlist ul.sublinks li.link {
  padding: 6px 0;
}
#header ul.links li.link.menuIcon div.menuBarlist ul.sublinks li.link a {
  font-family: var(--lato-font-family);
  color: var(--secondary-bg-color);
  letter-spacing: 1px;
  font-size: 16px;
}
html[lang=ar] #header ul.links li.link.menuIcon div.menuBarlist ul.sublinks li.link a {
  font-size: calc(16px + 1px);
}
#header div.left {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
#header div.left.ar {
  order: 2;
}
#header div.left .stage-logo {
  width: 93px;
  height: 39px;
  margin-right: 30px;
  margin-top: 3px;
}
#header div.left .stage-logo.ar {
  margin-right: unset;
}
#header div.left li.link {
  border-left: 1px solid #fff;
  padding: 20px 20px 20px;
  display: block;
}
#header div.right {
  height: 100%;
  display: flex;
  align-items: center;
}
#header div.right.ar {
  order: 1;
}
#header div.right div.menuIcon {
  display: none;
}
@media all and (max-width: 1400px) {
  #header div.right div.menuIcon {
    display: block;
  }
}
#header div.right ul.links {
  display: flex;
  align-items: center;
}
@media all and (max-width: 1400px) {
  #header div.right ul.links {
    display: none;
  }
}
#header div.right ul.links li.link {
  padding: 20px 20px 20px;
  display: flex;
  align-items: center;
}
#header div.right ul.links li.link span.icon {
  display: block;
  margin-left: 10px;
  padding-bottom: 4px;
}
#header div.right ul.links li.link span.icon.ar {
  padding-top: 10px;
  padding-right: 10px;
}
#header div.right ul.links li.link:first-child {
  padding: 20px 20px 20px;
}
#header div.menuResponsiveContainer {
  position: absolute;
  background-color: rgb(18, 23, 33);
  top: 0;
  bottom: 0;
  right: 0;
  width: 20rem;
  height: 100vh;
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
}
#header div.menuResponsiveContainer div.closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
}
@media all and (max-width: 1400px) {
  #header div.menuResponsiveContainer.active {
    right: 0;
    transform: translateX(0);
  }
}
@media all and (max-width: 640px) {
  #header div.menuResponsiveContainer.active {
    width: 100%;
  }
}
#header div.menuResponsiveContainer ul.responsiveLinks {
  padding-top: 50px;
}
#header div.menuResponsiveContainer ul.responsiveLinks li div.linkContainer {
  display: flex;
  align-items: center;
}
#header div.menuResponsiveContainer ul.responsiveLinks li.dropDownExist:hover ul.subLinks {
  display: block;
}
#header div.menuResponsiveContainer ul.responsiveLinks li span.label {
  padding: 15px 30px;
  display: block;
  color: var(--text-color);
}
#header div.menuResponsiveContainer ul.responsiveLinks li span.label.ar {
  padding: 15px 30px 15px 15px;
}
#header div.menuResponsiveContainer ul.responsiveLinks li a {
  padding: 15px 30px;
  display: block;
}
#header div.menuResponsiveContainer ul.responsiveLinks li ul.subLinks {
  display: none;
}
#header div.menuResponsiveContainer ul.responsiveLinks li ul.subLinks.gradientBorder {
  border-left: none;
  border-right: none;
}
#header .icon.closed {
  transform: rotate(0deg); /* Arrow in default (closed) state */
}
#header .icon.open {
  transform: rotate(180deg); /* Arrow flipped (open) state */
}
#header .language-link {
  display: flex;
  align-items: flex-start;
}
#header .language-link .flag {
  margin-right: 8px;
}
#header .language-link .flag.ar {
  margin-left: 8px;
}
#header .isDesktop {
  display: flex;
}
@media all and (max-width: 1400px) {
  #header .isDesktop {
    display: none;
  }
}

#lc_widgetTooltip-close {
  scale: 1.3;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

@media all and (max-width: 576px) {
  .lc_button_tooltip--container {
    width: 270px !important;
  }
  #lc_widgetTooltip-text {
    font-size: 12px !important;
  }
  html[lang=ar] #lc_widgetTooltip-text {
    font-size: calc(12px + 1px) !important;
  }
  .lc_button_tooltip {
    padding: 12px 15px !important;
  }
  .lc_button_tooltip--container.lc_button_tooltip--right {
    right: -270px !important;
  }
  .lc_button_tooltip--container {
    width: 262px !important;
  }
}
@media all and (max-width: 320px) {
  #lc_widgetTooltip-close {
    scale: 1.3;
    margin-top: 0.2rem;
    margin-right: 0.2rem;
  }
  #lc_widgetTooltip-text {
    font-size: 11px !important;
  }
  html[lang=ar] #lc_widgetTooltip-text {
    font-size: calc(11px + 1px) !important;
  }
  .lc_button_tooltip {
    padding: 10px 15px !important;
  }
  .lc_button_tooltip--container.lc_button_tooltip--right {
    right: -225px !important;
  }
  .lc_button_tooltip--container {
    width: 202px !important;
  }
}
@media all and (max-width: 480px) {
  #homePage {
    padding-top: 60px;
  }
}
#homePage div.videoContainer {
  position: relative;
  width: 100%;
  min-height: 300px;
  background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  min-height: calc(100vh - 200px);
}
@media all and (min-width: 320px) {
  #homePage div.videoContainer {
    min-height: calc(100vh - 400px);
  }
}
@media all and (min-width: 360px) {
  #homePage div.videoContainer {
    min-height: calc(100vh - 250px);
  }
}
@media all and (min-width: 640px) {
  #homePage div.videoContainer {
    min-height: calc(100vh - 150px);
  }
}
@media all and (min-width: 980px) {
  #homePage div.videoContainer {
    min-height: calc(100vh - 0px);
    background-position-y: -30px;
  }
}
@media all and (min-width: 1600px) {
  #homePage div.videoContainer {
    min-height: 1012px;
    background-position-y: -60px;
  }
}
@media all and (min-width: 1900px) {
  #homePage div.videoContainer {
    min-height: calc(100vh - 20px);
    background-position: 50%;
  }
}
@media all and (min-width: 2200px) {
  #homePage div.videoContainer {
    min-height: calc(100vh - 20px);
    background-position: 50%;
  }
}
#homePage div.videoContainer img.hero {
  position: absolute;
  width: 100%;
  object-fit: cover;
}
@media all and (max-width: 980px) {
  #homePage div.videoContainer img.hero {
    width: 100%;
  }
}
#homePage div.videoContainer h1.headline {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -40%);
  font-size: 37px;
  z-index: 9;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 200;
}
html[lang=ar] #homePage div.videoContainer h1.headline {
  font-size: calc(37px + 1px);
}
@media all and (min-width: 1980px) {
  #homePage div.videoContainer h1.headline {
    font-size: 6em;
  }
  html[lang=ar] #homePage div.videoContainer h1.headline {
    font-size: calc(6em + 1px);
  }
}
@media all and (max-width: 1979px) {
  #homePage div.videoContainer h1.headline {
    font-size: 4em;
  }
  html[lang=ar] #homePage div.videoContainer h1.headline {
    font-size: calc(4em + 1px);
  }
}
@media all and (max-width: 1768px) {
  #homePage div.videoContainer h1.headline {
    font-size: 3em;
  }
  html[lang=ar] #homePage div.videoContainer h1.headline {
    font-size: calc(3em + 1px);
  }
}
@media all and (max-width: 980px) {
  #homePage div.videoContainer h1.headline {
    font-size: 2em;
  }
  html[lang=ar] #homePage div.videoContainer h1.headline {
    font-size: calc(2em + 1px);
  }
}
@media all and (max-width: 768px) {
  #homePage div.videoContainer h1.headline {
    font-size: 1.8em;
  }
  html[lang=ar] #homePage div.videoContainer h1.headline {
    font-size: calc(1.8em + 1px);
  }
}
@media all and (max-width: 640px) {
  #homePage div.videoContainer h1.headline {
    font-size: 27px;
    line-height: 43px;
  }
  html[lang=ar] #homePage div.videoContainer h1.headline {
    font-size: calc(27px + 1px);
  }
}
@media all and (max-width: 360px) {
  #homePage div.videoContainer h1.headline {
    font-size: 27px;
    line-height: 43px;
  }
  html[lang=ar] #homePage div.videoContainer h1.headline {
    font-size: calc(27px + 1px);
  }
}
#homePage div.videoContainer h1.title {
  line-height: normal;
}
#homePage div.videoContainer div.gradient {
  position: absolute;
  position: absolute;
  width: 100%;
  background: linear-gradient(360deg, rgb(18, 24, 35) 0%, rgba(18, 24, 35, 0.01) 40%), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  height: 100%;
  bottom: 0;
}
@media all and (max-width: 1980px) {
  #homePage div.videoContainer div.gradient {
    display: block;
  }
}
@media all and (min-width: 2400px) {
  #homePage div.videoContainer div.gradient {
    height: 100%;
    bottom: 0;
    bottom: 0;
  }
}
#homePage div.videoContainer div.searchComponent {
  position: absolute;
  text-align: center;
  bottom: -150px;
  width: 100%;
  padding: 220px 0 0;
  z-index: 9;
  background: linear-gradient(360deg, rgb(18, 24, 35) 30%, rgba(18, 24, 35, 0.01) 88%);
}
@media all and (max-width: 768px) {
  #homePage div.videoContainer div.searchComponent {
    bottom: -374px;
  }
}
@media all and (max-width: 480px) {
  #homePage div.videoContainer div.searchComponent {
    bottom: -500px;
  }
}
#homePage div.videoContainer div.searchComponent h1 {
  color: var(--main-heading-color);
  font-size: 25px;
}
html[lang=ar] #homePage div.videoContainer div.searchComponent h1 {
  font-size: calc(25px + 1px);
}
@media all and (max-width: 768px) {
  #homePage div.videoContainer div.searchComponent h1 {
    font-size: 21px;
  }
  html[lang=ar] #homePage div.videoContainer div.searchComponent h1 {
    font-size: calc(21px + 1px);
  }
}
@media all and (max-width: 360px) {
  #homePage div.videoContainer div.searchComponent h1 {
    font-size: 16px;
  }
  html[lang=ar] #homePage div.videoContainer div.searchComponent h1 {
    font-size: calc(16px + 1px);
  }
}
#homePage div.videoContainer div.searchComponent span.downArrow {
  display: block;
  margin-bottom: 30px;
}
#homePage div.videoContainer div.searchComponent span.downArrow svg {
  width: 12px !important;
  height: 12px !important;
}
#homePage div.featuredLuxuriesProperties {
  padding: 160px 0 90px;
}
@media all and (max-width: 980px) {
  #homePage div.featuredLuxuriesProperties {
    padding: 160px 0 0;
  }
}
@media all and (max-width: 768px) {
  #homePage div.featuredLuxuriesProperties {
    padding: 380px 0 0;
  }
}
@media all and (max-width: 480px) {
  #homePage div.featuredLuxuriesProperties {
    padding: 500px 0 0;
  }
}

#searchSection {
  padding-bottom: 90px;
}
#searchSection.bgColor {
  background-color: var(--primary-bg-color);
}
#searchSection div.buttonDropDown {
  display: none;
  margin-bottom: 20px;
  width: 13rem;
  margin: 0 auto 20px;
  position: relative;
}
@media all and (max-width: 768px) {
  #searchSection div.buttonDropDown {
    display: block;
  }
}
#searchSection div.buttonDropDown div.dropDown {
  position: absolute;
  left: -20px;
  width: 100%;
  background-color: #fff;
  top: 60px;
  border-radius: 6px;
  z-index: 9;
}
@media all and (max-width: 768px) {
  #searchSection div.buttonDropDown div.dropDown {
    width: 100%;
    left: 0;
  }
}
@media all and (max-width: 640px) {
  #searchSection div.buttonDropDown div.dropDown {
    left: revert;
    right: 0;
  }
}
#searchSection div.buttonDropDown div.dropDown ul.listDropdownitems {
  background-color: var(--primary-bg-color);
}
#searchSection div.buttonDropDown div.dropDown ul.listDropdownitems li {
  text-align: center;
  padding: 15px 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
#searchSection div.buttonDropDown div.dropDown ul.listDropdownitems li span.text {
  color: var(--text-color);
  font-size: 14px;
}
html[lang=ar] #searchSection div.buttonDropDown div.dropDown ul.listDropdownitems li span.text {
  font-size: calc(14px + 1px);
}
#searchSection div.buttonDropDown div.dropDown ul.listDropdownitems li:hover {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
#searchSection div.buttonDropDown div.dropDown ul.listDropdownitems li:last-child {
  margin-bottom: 0;
}
#searchSection div.buttonDropDown button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 18px 15px;
  background-color: transparent;
  color: var(--btn-text-color);
  letter-spacing: 1.6px;
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
html[lang=ar] #searchSection div.buttonDropDown button {
  font-size: calc(14px + 1px);
}
#searchSection div.buttonDropDown button span.icon {
  display: block;
  margin-left: 6px;
}
#searchSection div.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 56%;
  margin: 0 auto 40px;
}
@media all and (max-width: 980px) {
  #searchSection div.buttonContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  #searchSection div.buttonContainer {
    display: none;
  }
}
#searchSection div.buttonContainer button.button {
  width: 13rem;
  padding: 18px 15px;
  background-color: transparent;
  color: var(--btn-text-color);
  letter-spacing: 1.6px;
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
html[lang=ar] #searchSection div.buttonContainer button.button {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 768px) {
  #searchSection div.buttonContainer button.button {
    margin-bottom: 10px;
  }
}
#searchSection div.buttonContainer button.button.active {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
}
#searchSection div.buttonContainer button.button.active span {
  color: #000;
}
#searchSection div.buttonContainer button.button:hover {
  background: var(--tertiary-color);
}
#searchSection div.buttonContainer button.button:hover span {
  color: #000;
}
#searchSection div.buttonContainer button.button span {
  transition: 0.3s ease-in-out;
  font-family: var(--lato-font-family) !important;
}
#searchSection div.searchContainer {
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  width: 85%;
  margin: 30px auto 0;
  padding: 22px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 1200px) {
  #searchSection div.searchContainer {
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  #searchSection div.searchContainer {
    padding: 12px 20px;
  }
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer {
    flex-wrap: wrap;
    padding: 22px;
  }
}
#searchSection div.searchContainer div.left {
  display: flex;
  width: 100%;
  line-height: 0;
  /* Specifically target the .searchInput on small screens */
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.left {
    display: flex; /* ensures children can wrap */
    flex-wrap: wrap; /* allow wrapping */
  }
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.left input.searchInput {
    border: 1px solid var(--border-color);
    padding: 15px 20px;
    width: 100%; /* take full width */
    display: block; /* ensures it sits on its own line */
    margin-bottom: 1rem;
  }
}
#searchSection div.searchContainer div.left div.search-common {
  margin-right: 30px;
  border-right: 1px solid #fff;
  padding: 5px 30px 5px 0;
  position: relative;
  cursor: pointer;
  line-height: normal;
  /*
    AREA DROPDOWN STYLES
    (Make it exactly like PRICE)
  */
}
#searchSection div.searchContainer div.left div.search-common.commercial {
  border-right: unset;
  margin-right: unset;
}
@media all and (max-width: 980px) {
  #searchSection div.searchContainer div.left div.search-common {
    margin-right: 13px;
    padding: 5px 13px 5px 0;
  }
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.left div.search-common {
    width: 33.33%;
    border: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color) !important;
    padding: 20px 15px;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media all and (max-width: 480px) {
  #searchSection div.searchContainer div.left div.search-common {
    width: 100%;
  }
}
#searchSection div.searchContainer div.left div.search-common.price div.dropDown {
  width: 20rem;
  padding: 10px;
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.left div.search-common.price div.dropDown {
    width: 100%;
    left: 0;
  }
}
#searchSection div.searchContainer div.left div.search-common.price div.dropDown div.selectContainer {
  width: 100%;
  margin-bottom: 20px;
}
#searchSection div.searchContainer div.left div.search-common.price div.dropDown div.selectContainer .css-13cymwt-control {
  border: none;
}
#searchSection div.searchContainer div.left div.search-common.price div.dropDown div.selectContainer:last-child {
  margin-bottom: 0;
}
#searchSection div.searchContainer div.left div.search-common.area div.dropDown {
  width: 20rem;
  padding: 10px;
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.left div.search-common.area div.dropDown {
    width: 100%;
    left: 0;
  }
}
#searchSection div.searchContainer div.left div.search-common.area div.dropDown div.selectContainer {
  width: 100%;
  margin-bottom: 20px;
}
#searchSection div.searchContainer div.left div.search-common.area div.dropDown div.selectContainer .css-13cymwt-control {
  border: none;
}
#searchSection div.searchContainer div.left div.search-common.area div.dropDown div.selectContainer:last-child {
  margin-bottom: 0;
}
#searchSection div.searchContainer div.left div.search-common div.dropDown {
  position: absolute;
  width: 6rem;
  background-color: #fff;
  top: 60px;
  border-radius: 6px;
  z-index: 9;
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.left div.search-common div.dropDown {
    width: 100%;
    left: 0;
  }
  #searchSection div.searchContainer div.left div.search-common div.dropDown.ar {
    right: unset;
    left: 0px;
  }
}
@media all and (max-width: 640px) {
  #searchSection div.searchContainer div.left div.search-common div.dropDown {
    left: revert;
    right: 0;
  }
}
#searchSection div.searchContainer div.left div.search-common div.dropDown ul.listDropdownitems {
  padding-top: 10px;
  padding-bottom: 10px;
}
#searchSection div.searchContainer div.left div.search-common div.dropDown ul.listDropdownitems li {
  text-align: center;
  padding: 10px 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
#searchSection div.searchContainer div.left div.search-common div.dropDown ul.listDropdownitems li:hover {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
#searchSection div.searchContainer div.left div.search-common div.dropDown ul.listDropdownitems li:last-child {
  margin-bottom: 0;
}
#searchSection div.searchContainer div.left div.search-common:last-child {
  border-right: none;
}
#searchSection div.searchContainer div.left div.search-common.price div.list span.details {
  border-right: none;
}
#searchSection div.searchContainer div.left div.search-common div.list {
  display: flex;
  justify-content: space-between;
}
#searchSection div.searchContainer div.left div.search-common div.list span {
  font-size: 14px;
  color: var(--btn-text-color);
  font-family: var(--lato-font-family);
  font-weight: 400;
  letter-spacing: 1.6px;
}
html[lang=ar] #searchSection div.searchContainer div.left div.search-common div.list span {
  font-size: calc(14px + 1px);
}
#searchSection div.searchContainer div.left div.search-common div.list span.details {
  width: 100px;
}
@media all and (max-width: 480px) {
  #searchSection div.searchContainer div.left div.search-common div.list span.details {
    width: unset;
  }
}
#searchSection div.searchContainer div.left div.search-common div.list span.icon {
  margin-left: 10px;
}
#searchSection div.searchContainer div.left div.search-common div.list span.icon.ar {
  margin-right: 10px;
}
#searchSection div.searchContainer div.left div.search-common div.list span.icon svg {
  width: 10px !important;
  height: 10px !important;
}
#searchSection div.searchContainer div.right {
  width: 4.5%;
  border-left: 1px solid #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
#searchSection div.searchContainer div.right.ar {
  border-left: unset;
  border-right: 1px solid #fff;
  padding-left: unset;
  padding-right: 20px;
}
@media all and (max-width: 1200px) {
  #searchSection div.searchContainer div.right {
    width: 5.5%;
  }
}
@media all and (max-width: 980px) {
  #searchSection div.searchContainer div.right {
    width: 6.5%;
  }
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.right {
    width: 100%;
    border: none;
    padding-left: 0;
  }
  #searchSection div.searchContainer div.right.ar {
    border: none;
    padding-right: unset;
  }
}
#searchSection div.searchContainer div.right button.search {
  display: none;
  width: 100%;
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.right button.search {
    display: block;
    color: #000;
    background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  }
}
#searchSection div.searchContainer div.right svg {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  #searchSection div.searchContainer div.right svg {
    display: none;
  }
}
#searchSection .searchInput {
  padding-left: 30px;
  width: 100%;
  padding: 6px;
  padding-left: unset;
  background-color: transparent;
  color: var(--text-color);
  font-size: 16px;
  text-transform: capitalize;
}
html[lang=ar] #searchSection .searchInput {
  font-size: calc(16px + 1px);
}
#searchSection .searchInput::placeholder {
  color: var(--placeholder-color);
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-family: var(--lato-font-family);
  font-size: 15px;
}
html[lang=ar] #searchSection .searchInput::placeholder {
  font-size: calc(15px + 1px);
}
#searchSection .searchInput.ar {
  padding-right: 30px;
}
#searchSection .searchInput.ar::placeholder {
  letter-spacing: normal;
}
#searchSection .areaInputs {
  display: flex;
}
#searchSection .price-dropdowns {
  width: 100%;
}
#searchSection .disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
@media (max-width: 480px) {
  #searchSection .mobile-only {
    display: block;
  }
}
@media (min-width: 481px) {
  #searchSection .mobile-only {
    display: none;
  }
}
@media (min-width: 480px) {
  #searchSection .tablet-desktop-only {
    display: block;
  }
}
@media (max-width: 481px) {
  #searchSection .tablet-desktop-only {
    display: none;
  }
}

div.featuredLuxuriesProperties {
  background-color: var(--primary-bg-color);
  border-bottom: 6px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  overflow-x: hidden;
}
div.featuredLuxuriesProperties h2.mainHeading {
  margin-bottom: 60px;
}
div.featuredLuxuriesProperties ul.tabs {
  display: flex;
  width: 40%;
  margin: 0 auto 50px;
  justify-content: space-between;
  margin-top: 20px;
}
div.featuredLuxuriesProperties ul.tabs li.tab {
  text-transform: uppercase;
  color: var(--text-color);
  font-weight: 300;
}
div.featuredLuxuriesProperties ul.tabs li.tab.active {
  font-weight: 400;
  padding-bottom: 6px;
  border-bottom: 1px solid #fff;
}
div.featuredLuxuriesProperties ul.tabs li.tab span {
  font-size: 14px;
}
html[lang=ar] div.featuredLuxuriesProperties ul.tabs li.tab span {
  font-size: calc(14px + 1px);
}

div#propertyCard {
  width: 100%;
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  background-color: var(--primary-bg-color);
  cursor: pointer;
}
div#propertyCard:hover div.imageContainer img {
  transform: scale(1.2);
}
div#propertyCard div.imageContainer {
  width: 100%;
  position: relative;
  height: 220px;
  overflow: hidden;
}
div#propertyCard div.imageContainer img {
  transition: 0.3s ease-in-out;
  object-fit: cover;
}
div#propertyCard div.imageContainer div.stickers {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  z-index: 9;
}
div#propertyCard div.imageContainer div.stickers div.sticker {
  margin-right: 10px;
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  padding: 3px 10px;
  z-index: 9;
}
div#propertyCard div.imageContainer div.stickers div.sticker.ar {
  margin-right: unset;
  margin-left: 10px;
}
div#propertyCard div.imageContainer div.stickers div.sticker.propertyType {
  background: var(--secondary-bg-color);
}
div#propertyCard div.imageContainer div.stickers div.sticker.propertyType span.text {
  color: var(--text-color);
}
div#propertyCard div.imageContainer div.stickers div.sticker:last-child {
  margin-right: 0;
}
div#propertyCard div.imageContainer div.stickers div.sticker span.text {
  font-size: 11px;
  color: var(--primary-bg-color);
  line-height: 0;
  text-transform: uppercase;
}
html[lang=ar] div#propertyCard div.imageContainer div.stickers div.sticker span.text {
  font-size: calc(11px + 1px);
}
div#propertyCard div.imageContainer div.loadingImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--secondary-bg-color);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
div#propertyCard div.imageContainer div.loadingImage div.image {
  width: 130px;
  height: 50px;
  position: relative;
}
div#propertyCard div.detailsContainer {
  padding: 20px 20px 0;
}
div#propertyCard div.detailsContainer span.price {
  color: var(--text-color);
  display: block;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 600;
}
html[lang=ar] div#propertyCard div.detailsContainer span.price {
  font-size: calc(16px + 1px);
}
div#propertyCard div.detailsContainer h3.title {
  font-weight: 300;
  color: var(--text-color);
  font-size: 15px;
  margin-bottom: 15px;
  min-height: 40px;
}
html[lang=ar] div#propertyCard div.detailsContainer h3.title {
  font-size: calc(15px + 1px);
}
div#propertyCard div.detailsContainer span.location {
  font-size: 15px;
  color: var(--light-text-color);
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-left: -6px;
  min-height: 42px;
}
html[lang=ar] div#propertyCard div.detailsContainer span.location {
  font-size: calc(15px + 1px);
}
div#propertyCard div.detailsContainer span.location span.icon {
  line-height: 0;
}
div#propertyCard div.detailsContainer span.location span.icon svg {
  width: 20px !important;
}
div#propertyCard div.detailsContainer span.location span.icon svg g {
  stroke: var(--light-text-color) !important;
}
div#propertyCard div.detailsContainer div.extraInfo div.top {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--light-text-color);
  padding-bottom: 15px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bed {
  display: flex;
  align-items: center;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bed span.icon {
  line-height: 0;
  margin-right: 6px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bed span.icon.ar {
  margin-left: 6px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bed span.icon svg g {
  stroke: var(--light-text-color) !important;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bed span.text {
  color: var(--light-text-color);
  text-transform: uppercase;
  font-weight: 300;
  margin-left: 6px;
  padding-right: 10px;
  border-right: 1px solid #fff;
  font-size: 13px;
}
html[lang=ar] div#propertyCard div.detailsContainer div.extraInfo div.top span.bed span.text {
  font-size: calc(13px + 1px);
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bed span.text.ar {
  margin-left: unset;
  margin-right: 6px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bathroom {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bathroom.ar {
  margin-left: unset;
  margin-right: 10px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bathroom span.icon {
  line-height: 0;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bathroom span.icon svg g {
  stroke: var(--light-text-color) !important;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bathroom span.text {
  color: var(--light-text-color);
  text-transform: uppercase;
  font-weight: 300;
  margin-left: 6px;
  border-right: 1px solid #fff;
  padding-right: 10px;
  font-size: 13px;
}
html[lang=ar] div#propertyCard div.detailsContainer div.extraInfo div.top span.bathroom span.text {
  font-size: calc(13px + 1px);
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.bathroom span.text.ar {
  margin-left: unset;
  margin-right: 6px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.area {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.area.ar {
  margin-left: unset;
  margin-right: 10px;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.area span.icon {
  line-height: 0;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.area span.icon.ar {
  margin-left: 0.3rem;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.area span.icon svg g {
  stroke: var(--light-text-color) !important;
}
div#propertyCard div.detailsContainer div.extraInfo div.top span.area span.text {
  color: var(--light-text-color);
  font-weight: 300;
  margin-left: 6px;
  text-transform: lowercase;
  font-size: 13px;
}
html[lang=ar] div#propertyCard div.detailsContainer div.extraInfo div.top span.area span.text {
  font-size: calc(13px + 1px);
}
div#propertyCard div.bottom {
  padding: 12px 0;
}
@media all and (max-width: 320px) {
  div#propertyCard div.bottom {
    padding: 12px 0;
  }
}
div#propertyCard div.bottom div.contactInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div#propertyCard div.bottom div.contactInfo a:nth-child(2) span.icon {
  width: 10rem;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
@media all and (max-width: 1400px) {
  div#propertyCard div.bottom div.contactInfo a:nth-child(2) span.icon {
    width: 8rem;
  }
}
@media all and (max-width: 980px) {
  div#propertyCard div.bottom div.contactInfo a:nth-child(2) span.icon {
    width: 8rem;
  }
}
@media all and (max-width: 640px) {
  div#propertyCard div.bottom div.contactInfo a:nth-child(2) span.icon {
    width: 8rem;
  }
}
div#propertyCard div.bottom div.contactInfo a:first-child span.icon {
  padding-left: 0;
}
div#propertyCard div.bottom div.contactInfo a:last-child span.icon {
  border-right: none;
  padding-right: 0;
}
@media all and (max-width: 640px) {
  div#propertyCard div.bottom div.contactInfo a:last-child span.icon {
    width: 5rem;
  }
}
div#propertyCard div.bottom div.contactInfo span.icon {
  padding: 0 10px;
  cursor: pointer;
  display: block;
  width: 6rem;
  text-align: center;
}
@media all and (max-width: 980px) {
  div#propertyCard div.bottom div.contactInfo span.icon {
    width: 5rem;
  }
}
@media all and (max-width: 320px) {
  div#propertyCard div.bottom div.contactInfo span.icon {
    width: 4rem;
  }
}
div#propertyCard div.bottom div.contactInfo span.icon svg {
  width: 13px !important;
  height: 13px !important;
}

#homePage div.ourStorySection {
  overflow: hidden;
}
#homePage div.ourStorySection div.imageContainer {
  width: 100%;
  height: 750px;
  position: relative;
}
@media all and (min-width: 1980px) {
  #homePage div.ourStorySection div.imageContainer {
    height: 1076px;
  }
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer {
    height: 1200px;
  }
}
@media all and (max-width: 640px) {
  #homePage div.ourStorySection div.imageContainer {
    height: 950px;
  }
}
#homePage div.ourStorySection div.imageContainer img {
  object-fit: cover;
  z-index: -1;
}
#homePage div.ourStorySection div.imageContainer div.gradient {
  position: absolute;
  background: linear-gradient(360deg, rgb(20, 27, 39) 15%, rgba(20, 27, 39, 0.8758096989) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 1;
}
#homePage div.ourStorySection div.imageContainer div.contentContainer {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  padding-top: 60px;
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper {
    flex-direction: column-reverse;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer {
  width: 48%;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer {
    position: relative;
    left: -22px;
  }
}
@media all and (max-width: 480px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer {
    left: -45px;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle {
  width: 650px;
  height: 650px;
  border-radius: 50%;
  border: 1px solid #ac836d;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
  position: relative;
}
@media all and (max-width: 1400px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle {
    width: 570px;
    height: 570px;
  }
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle {
    margin: 0 auto;
  }
}
@media all and (max-width: 640px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle {
    width: 400px;
    height: 400px;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle .title {
  color: rgb(205, 174, 158);
  font-weight: 300;
  font-size: 26px;
}
html[lang=ar] #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle .title {
  font-size: calc(26px + 1px);
}
@media all and (max-width: 1400px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle .title {
    font-size: 20px;
  }
  html[lang=ar] #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle .title {
    font-size: calc(20px + 1px);
  }
}
@media all and (max-width: 640px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle .title {
    font-size: 16px;
  }
  html[lang=ar] #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle .title {
    font-size: calc(16px + 1px);
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle span {
  color: rgb(172, 131, 109);
  font-weight: 500;
  font-size: 12px;
  display: block;
  text-align: center;
}
html[lang=ar] #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle span {
  font-size: calc(12px + 1px);
}
@media all and (max-width: 1400px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle span {
    font-size: 11px;
  }
  html[lang=ar] #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle span {
    font-size: calc(11px + 1px);
  }
}
@media all and (max-width: 640px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle span {
    font-size: 10px;
  }
  html[lang=ar] #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle span {
    font-size: calc(10px + 1px);
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.texts {
  position: absolute;
  top: -20px;
  width: 191px;
  text-align: center;
  left: 52%;
  transform: translateX(-50%);
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle {
  width: 490px;
  height: 490px;
  border-radius: 50%;
  border: 1px solid #ac836d;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: none;
}
@media all and (max-width: 1400px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle {
    width: 390px;
    height: 390px;
  }
}
@media all and (max-width: 640px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle {
    width: 300px;
    height: 300px;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.texts {
  left: 53%;
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle {
  width: 330px;
  height: 330px;
  border-radius: 50%;
  border: 1px solid #ac836d;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: none;
}
@media all and (max-width: 1400px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle {
    width: 230px;
    height: 230px;
  }
}
@media all and (max-width: 1400px) and (max-width: 640px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle {
    width: 190px;
    height: 190px;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle div.texts {
  background: transparent;
  left: 55%;
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle div.innerCircle {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 1px solid #ac836d;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 1400px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle div.innerCircle {
    width: 100px;
    height: 100px;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle div.innerCircle div.logo {
  width: 80px;
  height: 90px;
  position: relative;
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.leftContainer div.circleContainer div.outerCircle div.secondCircle div.thirdCircle div.innerCircle div.logo img {
  object-fit: contain;
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer {
  width: 40%;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer {
    width: 100%;
    margin-bottom: 80px;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer h2.mainHeading {
  text-align: left;
  margin-bottom: 20px;
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer h2.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer h2.mainHeading {
    text-align: center;
  }
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer h2.mainHeading.ar {
    text-align: center;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer h2.mainHeading {
    line-height: normal;
  }
}
#homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer p.description {
  color: var(--text-color);
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  max-width: 78%;
  margin-bottom: 20px;
  line-height: 2em;
}
html[lang=ar] #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer p.description {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 1400px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer p.description {
    max-width: 100%;
  }
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer p.description {
    text-align: center;
    max-width: 100%;
  }
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.imageContainer div.contentContainer div.wrapper div.rightContainer div.buttonContainer {
    text-align: center;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection {
  background-color: var(--secondary-bg-color);
  padding-bottom: 80px;
  padding-top: 70px;
}
@media all and (min-width: 1980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection {
    margin-top: -10px;
  }
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection {
    padding-top: 10px;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer {
    border: none;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.slick-list {
  padding: 0 !important;
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.slick-list {
    overflow: visible;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.slick-slide {
    padding: 0 20px;
  }
}
@media all and (max-width: 480px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.slick-slide {
    padding: 0 0 0 20px;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-right {
  position: absolute;
  bottom: 25px;
  font-size: 35px;
  left: 350px;
  position: absolute;
  cursor: pointer;
  top: unset !important;
  cursor: pointer;
}
html[lang=ar] #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-right {
  font-size: calc(35px + 1px);
}
@media all and (min-width: 1700px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-right {
    left: 450px;
  }
}
@media all and (min-width: 2300px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-right {
    left: 480px;
  }
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-right {
    right: 40%;
    bottom: -60px;
    left: auto;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-right {
    display: none;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-left {
  position: absolute;
  bottom: 25px;
  font-size: 35px;
  left: 110px;
  z-index: 9;
  cursor: pointer;
  cursor: pointer;
  top: unset !important;
}
html[lang=ar] #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-left {
  font-size: calc(35px + 1px);
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-left {
    left: 40%;
    bottom: -60px;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.arrow-left {
    display: none;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem {
  display: flex !important;
  justify-content: space-between;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem {
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem {
    border: 1px solid transparent; /* Set the border width and make it transparent */
    border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
    border-image-slice: 1;
    margin: 0 20px;
    min-height: 430px;
  }
}
@media all and (max-width: 480px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem {
    margin: 0 0;
    width: 100%;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.left {
  width: 50%;
  padding: 80px 40px 30px;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.left {
    width: 100%;
    padding: 20px 10px;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.left {
    flex: 1 1;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.left p.description {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300;
  color: var(--text-color);
  line-height: 2em;
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.left p.description.ar {
  text-align: right;
}
html[lang=ar] #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.left p.description {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 480px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.left p.description {
    max-height: 200px;
    overflow-y: scroll;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.right {
  width: 50%;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.right {
    width: 100%;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.right div.imageContainer {
  width: 100%;
  height: 350px;
}
@media all and (max-width: 980px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.right div.imageContainer {
    height: 220px;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.carouselItem div.right div.imageContainer img {
  object-fit: cover;
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.navController {
  position: absolute;
  display: flex;
  align-items: center;
  left: 15%;
  bottom: 25px;
}
@media all and (max-width: 1200px) {
  #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.navController {
    display: none;
  }
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.navController span.icon svg {
  width: 40px !important;
  height: 40px !important;
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.navController div.previousNav {
  margin-right: 30px;
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.navController h2 {
  font-size: 20px;
  font-family: var(--audrey-font-family);
  color: var(--text-color);
  font-weight: 400;
}
html[lang=ar] #homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.navController h2 {
  font-size: calc(20px + 1px);
}
#homePage div.ourStorySection div.ourStoryCarouselSection div.carouselMainContainer div.navController div.nextNav {
  margin-left: 30px;
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#homePage div.ourServicesSection {
  background-color: var(--primary-bg-color);
  padding: 120px 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 6px;
  position: relative;
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection {
    background-color: var(--secondary-bg-color);
    border-top: inset;
    border-top-width: 6px;
    padding: 40px 0;
  }
}
#homePage div.ourServicesSection h2.mainHeading {
  margin-top: 80px;
  margin-bottom: 10px;
}
#homePage div.ourServicesSection h2.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection h2.mainHeading {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
#homePage div.ourServicesSection h3.mainHeading {
  margin-bottom: 20px;
}
#homePage div.ourServicesSection h3.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection h3.mainHeading {
    margin-bottom: 20px;
  }
}
#homePage div.ourServicesSection p.tagline {
  color: var(--light-text-color);
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 70px;
}
html[lang=ar] #homePage div.ourServicesSection p.tagline {
  font-size: calc(14px + 1px);
}
#homePage div.ourServicesSection p.tagline.ar {
  font-size: 16px;
}
html[lang=ar] #homePage div.ourServicesSection p.tagline.ar {
  font-size: calc(16px + 1px);
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection p.tagline {
    margin-bottom: 30px;
  }
}
#homePage div.ourServicesSection div.serviceList {
  position: relative;
}
@media all and (max-width: 1200px) {
  #homePage div.ourServicesSection div.serviceList {
    margin-bottom: 60px;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection div.serviceList {
    margin-bottom: 40px;
  }
}
#homePage div.ourServicesSection div.serviceList div.addtionalInformation {
  position: absolute;
  right: 0;
  bottom: 50px;
  width: 30%;
}
@media all and (max-width: 1200px) {
  #homePage div.ourServicesSection div.serviceList div.addtionalInformation {
    width: 85%;
    margin: 0 auto;
    max-width: 1600px;
    position: static;
  }
}
#homePage div.ourServicesSection div.serviceList div.addtionalInformation ul li {
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 25px 0;
}
#homePage div.ourServicesSection div.serviceList div.addtionalInformation ul li:last-child {
  border-bottom: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
}
#homePage div.ourServicesSection div.serviceList div.addtionalInformation ul li span {
  font-size: 14px;
  color: var(--text-color);
}
html[lang=ar] #homePage div.ourServicesSection div.serviceList div.addtionalInformation ul li span {
  font-size: calc(14px + 1px);
}
#homePage div.ourServicesSection div.scrollContainer {
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
@media all and (max-width: 1200px) {
  #homePage div.ourServicesSection div.scrollContainer {
    margin-bottom: 60px;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection div.scrollContainer {
    margin-bottom: 40px;
  }
}
#homePage div.ourServicesSection div.scrollContainer::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media all and (max-width: 1056px) {
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer {
    flex-wrap: nowrap;
    overflow: scroll;
    width: 1600px;
  }
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem {
  width: 29%;
  margin-right: 70px;
  margin-bottom: 80px;
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem.ar {
  margin-right: unset;
  margin-left: 70px;
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem:nth-child(3n) {
  margin-right: 0;
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem:nth-child(3n).ar {
  margin-right: unset;
  margin-left: 0;
}
@media all and (max-width: 1400px) {
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem {
    width: 31%;
    margin-right: 32px;
  }
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem.ar {
    margin-left: 32px;
    margin-bottom: 64px;
  }
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem:nth-child(3n).ar {
    margin-right: unset;
    margin-left: unset;
  }
}
@media all and (max-width: 1200px) {
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem {
    margin-right: 30px;
  }
}
@media all and (max-width: 1056px) {
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem {
    width: 263px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem:nth-child(3n).ar {
    margin-right: unset;
    margin-left: 30px;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.serviceItem {
    width: 263px;
    margin-right: 18px;
  }
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.addtionalInformation {
  position: absolute;
  right: -100px;
  bottom: 50px;
  width: 40%;
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.addtionalInformation ul li {
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 25px 0;
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.addtionalInformation ul li:last-child {
  border-bottom: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
}
#homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.addtionalInformation ul li span {
  font-size: 14px;
  color: var(--text-color);
}
html[lang=ar] #homePage div.ourServicesSection div.scrollContainer div.servicesContainer div.addtionalInformation ul li span {
  font-size: calc(14px + 1px);
}
#homePage div.ourServicesSection div.line {
  display: block;
  width: 50%;
  margin: 30px auto;
  height: 1px;
  position: relative;
}
@media all and (max-width: 1200px) {
  #homePage div.ourServicesSection div.line {
    display: none;
  }
}
#homePage div.ourServicesSection div.mortgageCalculatorFormContainer h1.heading {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: var(--text-color);
  text-transform: uppercase;
  margin-bottom: 30px;
}
html[lang=ar] #homePage div.ourServicesSection div.mortgageCalculatorFormContainer h1.heading {
  font-size: calc(14px + 1px);
}
#homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 90px 40px 80px;
  align-items: flex-end;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  #homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm {
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm {
    flex-direction: column;
    background-color: var(--dark-blue);
    padding: 30px 30px 30px;
  }
}
#homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm input {
  width: 23%;
  display: block;
  background-color: transparent;
  border-bottom: 1px solid var(--link-color);
  padding-bottom: 8px;
  caret-color: #fff;
  color: var(--text-color);
  font-size: 16px;
}
html[lang=ar] #homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm input {
  font-size: calc(16px + 1px);
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm input {
    width: 100%;
    margin-bottom: 30px;
  }
}
#homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm input::placeholder {
  letter-spacing: 1.6px;
  font-size: 14px;
}
html[lang=ar] #homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm input::placeholder {
  font-size: calc(14px + 1px);
}
#homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm button {
  width: 11rem;
}
@media all and (max-width: 980px) {
  #homePage div.ourServicesSection div.mortgageCalculatorFormContainer div.calculatorForm button {
    width: 100%;
  }
}
#homePage div.ourServicesSection .mortgageCalculator {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#homePage div.ourServicesSection .mortgageCalculator h3 {
  font-size: 16.53px;
  text-align: center;
}
html[lang=ar] #homePage div.ourServicesSection .mortgageCalculator h3 {
  font-size: calc(16.53px + 1px);
}
#homePage div.ourServicesSection .mortgageCalculator h2 {
  text-align: center;
}
#homePage div.ourServicesSection .mortgageCalculator button {
  width: -moz-fit-content;
  width: fit-content;
}

button.showMoreBtn {
  background-color: transparent;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
button.showMoreBtn:hover {
  background-color: var(--tertiary-color);
  color: #000;
}
@media all and (max-width: 768px) {
  button.showMoreBtn {
    font-size: 14px;
    padding: 12px 10px;
    margin-bottom: 20px;
  }
  html[lang=ar] button.showMoreBtn {
    font-size: calc(14px + 1px);
  }
}
@media all and (max-width: 640px) {
  button.showMoreBtn {
    width: 8rem;
    padding: 10px 10px;
    font-size: 13px;
    margin-bottom: 20px;
  }
  html[lang=ar] button.showMoreBtn {
    font-size: calc(13px + 1px);
  }
}

div.ourServicesCard {
  padding: 10px 20px;
  min-height: 220px;
  background-color: var(--dark-blue);
}
@media all and (max-width: 1200px) {
  div.ourServicesCard {
    min-height: 292px;
  }
}
@media all and (max-width: 768px) {
  div.ourServicesCard {
    min-height: 300px;
  }
}
div.ourServicesCard span.icon {
  display: inline-block;
  margin-bottom: 10px;
}
div.ourServicesCard span.icon svg {
  width: 38px !important;
  height: 38px !important;
}
div.ourServicesCard h3.title {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color);
  margin-bottom: 15px;
}
html[lang=ar] div.ourServicesCard h3.title {
  font-size: calc(14px + 1px);
}
div.ourServicesCard p.description {
  font-size: 14px;
  font-weight: 300;
  color: var(--light-text-color);
  margin-bottom: 15px;
  text-transform: none;
}
html[lang=ar] div.ourServicesCard p.description {
  font-size: calc(14px + 1px);
}
div.ourServicesCard button.learnMore {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  padding-bottom: 8px;
  color: var(--link-color);
  text-transform: uppercase;
  letter-spacing: 1.6px;
  border-width: 0.5px;
}

div.meetOurTeamSection {
  overflow: hidden;
}
div.meetOurTeamSection div.imageContainerBg {
  height: 750px;
  width: 100%;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.meetOurTeamSection div.imageContainerBg {
    height: 800px;
  }
}
div.meetOurTeamSection div.imageContainerBg img {
  object-fit: cover;
  z-index: -1;
}
div.meetOurTeamSection div.imageContainerBg div.gradient {
  position: absolute;
  background: linear-gradient(360deg, rgb(20, 27, 39) 15%, rgba(20, 27, 39, 0.8758096989) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper {
    display: flex;
    flex-direction: column;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer {
  width: 48%;
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer {
    width: 100%;
    margin-bottom: 70px;
  }
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer {
    margin-bottom: 20px;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer h2.mainHeading {
  text-align: left;
  margin-bottom: 20px;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer h2.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer h2.mainHeading {
    text-align: center;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer p.description {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 2em;
  font-weight: 300;
  padding-bottom: 15px;
  max-width: 85%;
}
html[lang=ar] div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer p.description {
  font-size: calc(14px + 1px);
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer p.description.ar {
  max-width: 100%;
  text-align: right;
  margin-bottom: 1rem;
}
@media all and (max-width: 1200px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer p.description {
    max-width: 100%;
  }
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer p.description {
    text-align: center;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers {
  display: flex;
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers.hideOnResponsive {
    display: none;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer {
  display: flex;
  border: 1px solid var(--secondary-border-color);
  width: 70%;
  padding: 20px 20px;
  margin-bottom: 10px;
  justify-content: space-between;
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer {
    width: 45%;
  }
}
@media all and (min-width: 1200px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer {
    width: 79%;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer span.text {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 20px;
}
html[lang=ar] div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer span.text {
  font-size: calc(14px + 1px);
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer span.icon svg {
  width: 10px !important;
  height: 10px !important;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.specializationContainer {
  border-right: 1px solid #fff;
  padding-right: 23px;
  cursor: pointer;
  position: relative;
  width: 52%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.specializationContainer div.dropDown {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 0;
  right: 0;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.specializationContainer div.dropDown ul.lists li {
  padding: 10px;
  transition: 0.2s ease-in-out;
  text-align: center;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.specializationContainer div.dropDown ul.lists li:hover {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.languageContainer {
  cursor: pointer;
  position: relative;
  width: 40%;
  display: flex;
  justify-content: space-between;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.languageContainer div.dropDown {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 0;
  right: 0;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.languageContainer div.dropDown ul.lists li {
  padding: 10px;
  transition: 0.2s ease-in-out;
  text-align: center;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.selectContainer div.languageContainer div.dropDown ul.lists li:hover {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}
@media all and (min-width: 1200px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.buttonContainer {
    width: 40%;
  }
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.buttonContainer {
    width: 45%;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.leftContainer div.actionControllers div.buttonContainer button {
  width: 100%;
  display: block;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
  width: 56%;
  position: absolute;
  right: 70px;
  display: flex;
  justify-content: right;
  top: 0;
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 100%;
    position: static;
    flex-direction: column;
  }
}
@media all and (min-width: 1200px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 60%;
    right: 70px;
  }
}
@media all and (min-width: 1500px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 57%;
  }
}
@media all and (min-width: 1600px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 50%;
    right: 130px;
  }
}
@media all and (min-width: 1768px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 45%;
  }
}
@media all and (min-width: 1980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 40%;
    right: 100px;
  }
}
@media all and (min-width: 2200px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 37%;
  }
}
@media all and (min-width: 2500px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 29%;
    right: 250px;
  }
}
@media all and (min-width: 2768px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer {
    width: 25%;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slider {
  transform: rotate(90deg);
  width: 90%;
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slider {
    transform: rotate(0);
    width: 100%;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide {
  transform: scale(0.7) !important;
  filter: blur(3px);
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide {
    transform: scale(1) !important;
    padding: 0 20px;
  }
}
@media all and (max-width: 768px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide {
    filter: none;
    padding: 0 0 0 20px;
  }
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide div.teamItem {
    width: 15rem !important;
    margin: unset;
  }
}
@media all and (max-width: 768px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide div.teamItem {
    width: 100% !important;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide div.teamCard {
  transform: rotate(-90deg);
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide div.teamCard {
    transform: rotate(0);
  }
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide div.teamCard {
    border: 1px solid transparent;
  }
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide div.teamCard div.info {
    display: block;
  }
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-slide div.teamCard div.imageContainer {
    height: 324px;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.arrow-right {
  position: absolute;
  right: 220px !important;
  top: -172px !important;
  font-size: 35px;
  cursor: pointer;
  display: none;
  transform: unset !important;
}
html[lang=ar] div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.arrow-right {
  font-size: calc(35px + 1px);
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.arrow-right {
    top: unset !important;
    bottom: -30px !important;
    right: 100px !important;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.arrow-left {
  position: absolute;
  left: 240px !important;
  top: -172px !important;
  font-size: 35px;
  cursor: pointer;
  transform: unset !important;
  display: none;
}
html[lang=ar] div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.arrow-left {
  font-size: calc(35px + 1px);
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.arrow-left {
    top: unset !important;
    bottom: -30px !important;
    left: 100px !important;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-list {
  padding: 0 0 !important;
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-list {
    padding: 0 20px !important;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-center.slick-slide {
  filter: none !important;
  transition: 0.4s;
  transform: scale(1) !important;
  position: relative;
  z-index: 1;
}
@media all and (max-width: 1400px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-center.slick-slide div.teamItem {
    width: 15rem !important;
    margin: 0 -30px;
  }
}
@media all and (max-width: 980px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-center.slick-slide div.teamItem {
    width: 15rem !important;
    margin: unset;
  }
}
@media all and (max-width: 768px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-center.slick-slide div.teamItem {
    width: 100% !important;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-center.slick-slide div.teamCard {
  width: 100%;
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-center.slick-slide div.teamCard div.imageContainer {
  height: 331px;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.slick-center.slick-slide div.teamCard div.info {
  display: block;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.teamContainer div.teamItem {
  width: 50%;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController {
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  display: none;
}
@media all and (max-width: 1199px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController {
    transform: rotate(0);
    position: relative;
    display: none;
  }
}
@media all and (min-width: 1200px) {
  div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController {
    right: 20px;
  }
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController span.icon svg {
  width: 40px !important;
  height: 40px !important;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController div.previousNav {
  margin-right: 20px;
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController h6 {
  font-size: 20px;
  font-family: var(--audrey-font-family);
  color: var(--text-color);
  font-weight: 400;
  text-transform: uppercase;
}
html[lang=ar] div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController h6 {
  font-size: calc(20px + 1px);
}
div.meetOurTeamSection div.imageContainerBg div.contentContainer div.wrapper div.rightContainer div.navController div.nextNav {
  margin-left: 20px;
}

div.listingProperties {
  padding: 100px 0;
  border-left: none;
  border-right: none;
  border-width: 5px;
  overflow-x: hidden;
}
div.listingProperties div.headingSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 80px;
}
@media all and (max-width: 1200px) {
  div.listingProperties div.headingSection {
    padding: 0;
  }
}
@media all and (max-width: 768px) {
  div.listingProperties div.headingSection {
    justify-content: center;
  }
}
div.listingProperties div.headingSection h2.mainHeading {
  font-size: 30px;
}
html[lang=ar] div.listingProperties div.headingSection h2.mainHeading {
  font-size: calc(30px + 1px);
}
@media all and (max-width: 768px) {
  div.listingProperties div.headingSection h2.mainHeading {
    font-size: 25px;
  }
  html[lang=ar] div.listingProperties div.headingSection h2.mainHeading {
    font-size: calc(25px + 1px);
  }
}
@media all and (max-width: 480px) {
  div.listingProperties div.headingSection h2.mainHeading {
    font-size: 20px;
  }
  html[lang=ar] div.listingProperties div.headingSection h2.mainHeading {
    font-size: calc(20px + 1px);
  }
}
div.listingProperties div.headingSection div.buttonContainer {
  text-align: center;
}
@media all and (max-width: 768px) {
  div.listingProperties div.headingSection div.buttonContainer {
    display: none;
  }
}
div.listingProperties div.headingSection div.buttonContainer button.showMoreBtn {
  display: block;
  background-color: transparent;
  color: var(--text-color);
  padding: 15px 10px;
  margin-bottom: 0;
  transition: 0.3s ease-in-out;
}
div.listingProperties div.headingSection div.buttonContainer button.showMoreBtn:hover {
  background-color: var(--tertiary-color);
  color: #000;
}
@media all and (max-width: 768px) {
  div.listingProperties div.headingSection div.buttonContainer button.showMoreBtn {
    font-size: 14px;
    padding: 12px 10px;
  }
  html[lang=ar] div.listingProperties div.headingSection div.buttonContainer button.showMoreBtn {
    font-size: calc(14px + 1px);
  }
}
@media all and (max-width: 640px) {
  div.listingProperties div.headingSection div.buttonContainer button.showMoreBtn {
    width: 8rem;
    padding: 10px 10px;
    font-size: 13px;
  }
  html[lang=ar] div.listingProperties div.headingSection div.buttonContainer button.showMoreBtn {
    font-size: calc(13px + 1px);
  }
}
div.listingProperties div.buttonContainer.responsive {
  display: none;
  text-align: center;
}
@media all and (max-width: 768px) {
  div.listingProperties div.buttonContainer.responsive {
    display: block;
  }
}
div.listingProperties div.buttonContainer.responsive button {
  padding: 10px 10px;
}
div.listingProperties div#propertyCard div.detailsContainer h1.title {
  font-size: 15px;
}
html[lang=ar] div.listingProperties div#propertyCard div.detailsContainer h1.title {
  font-size: calc(15px + 1px);
}

div.teamCard {
  width: 100%;
  margin: 0 0 0 auto;
}
div.teamCard.active {
  filter: none;
}
div.teamCard div.imageContainer {
  height: 218px;
  position: relative;
  width: 100%;
}
div.teamCard div.imageContainer img {
  object-fit: cover;
}
div.teamCard div.imageContainer div.info {
  background: rgba(20, 27, 39, 0.9);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 15px 15px;
  display: none;
  position: absolute;
  width: 100%;
  bottom: 0;
}
div.teamCard div.imageContainer div.info h3.name {
  text-transform: uppercase;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 300;
  margin-bottom: 10px;
}
html[lang=ar] div.teamCard div.imageContainer div.info h3.name {
  font-size: calc(15px + 1px);
}
div.teamCard div.imageContainer div.info h6 {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 300;
}
html[lang=ar] div.teamCard div.imageContainer div.info h6 {
  font-size: calc(14px + 1px);
}
div.teamCard div.imageContainer div.info h6.title {
  margin-bottom: 6px;
}

div.communityCard {
  text-align: center;
  cursor: pointer;
}
div.communityCard div.imageContainer {
  position: relative;
  height: 500px;
  width: 100%;
  margin-bottom: 15px;
}
@media all and (max-width: 768px) {
  div.communityCard div.imageContainer {
    height: 700px;
  }
}
div.communityCard div.imageContainer img {
  object-fit: cover;
}
div.communityCard h3.name {
  font-size: 15px;
  font-weight: 300;
  color: var(--text-color);
  text-transform: uppercase;
  margin-bottom: 15px;
  line-height: 0;
}
html[lang=ar] div.communityCard h3.name {
  font-size: calc(15px + 1px);
}
div.communityCard span.guide {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 10px;
  display: inline-block;
  text-transform: uppercase;
}
html[lang=ar] div.communityCard span.guide {
  font-size: calc(14px + 1px);
}
div.communityCard div.contactInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.communityCard div.contactInfo a {
  padding: 0 10px;
  border-right: 1px solid #fff;
  cursor: pointer;
}
div.communityCard div.contactInfo a:first-child {
  padding-left: 0;
}
div.communityCard div.contactInfo a:last-child {
  border-right: none;
}
div.communityCard div.contactInfo span.icon {
  cursor: pointer;
}
div.communityCard div.contactInfo span.icon svg {
  width: 13px !important;
  height: 13px !important;
}

#homePage div.communitySection {
  padding: 120px 0;
  background-color: var(--secondary-bg-color);
  border-left: none;
  border-right: none;
  border-top: none;
  border-width: 6px;
  margin-top: -10px;
}
@media all and (max-width: 768px) {
  #homePage div.communitySection {
    padding: 10px 0 90px;
  }
}
#homePage div.communitySection h2.mainHeading {
  margin-bottom: 40px;
}
#homePage div.communitySection p.tagline {
  color: var(--light-text-color);
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 70px;
}
html[lang=ar] #homePage div.communitySection p.tagline {
  font-size: calc(14px + 1px);
}
@media all and (min-width: 1200px) {
  #homePage div.communitySection div.slick-slide {
    transform: scale(0.7) !important;
  }
}
@media all and (min-width: 1050px) and (max-width: 1200px) {
  #homePage div.communitySection div.slick-slide {
    transform: scale(0.6) !important;
  }
}
@media all and (min-width: 768px) {
  #homePage div.communitySection div.slick-slide {
    transform: scale(1);
    filter: none;
  }
  #homePage div.communitySection div.slick-slide :nth-child(odd) .communityCard {
    margin-right: 10px;
  }
}
#homePage div.communitySection div.arrow-left {
  scale: 1.2;
  position: absolute;
  right: calc(40% - 10px);
  left: calc(40% - 10px);
  top: 105%;
}
@media all and (min-width: 1050px) {
  #homePage div.communitySection div.arrow-left {
    right: calc(17% - 10px);
    left: calc(17% - 10px);
    top: 100%;
  }
}
#homePage div.communitySection div.arrow-right {
  scale: 1.2;
  position: absolute;
  right: 55%;
  left: 55%;
  top: 105%;
}
@media all and (min-width: 1050px) {
  #homePage div.communitySection div.arrow-right {
    right: 80%;
    left: 80%;
    top: 100%;
  }
}
#homePage div.communitySection div.slick-center.slick-slide {
  filter: none !important;
  transform: scale(1) !important;
  transition: 0.4s ease-in;
}
#homePage div.communitySection div.slick-center div.propertyItem {
  z-index: 999;
}
#homePage div.communitySection div.communityListingContainer div.communityListItem {
  width: 30%;
  filter: blur(6px);
}
#homePage div.communitySection div.communityListingContainer div.communityListItem.active {
  filter: none;
}

div.marketOverviewSection {
  background-color: var(--primary-bg-color);
  padding: 80px 0;
}
div.marketOverviewSection div.wrapper span.heading {
  color: var(--main-heading-color);
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  display: block;
}
html[lang=ar] div.marketOverviewSection div.wrapper span.heading {
  font-size: calc(13px + 1px);
}
div.marketOverviewSection div.wrapper span.date {
  font-family: var(--audrey-font-family);
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  display: block;
  text-transform: uppercase;
  font-weight: 400;
}
html[lang=ar] div.marketOverviewSection div.wrapper span.date {
  font-size: calc(20px + 1px);
}
div.marketOverviewSection div.wrapper h2.totalCount {
  font-size: 80px;
  text-align: center;
  color: var(--text-color);
  font-weight: 400;
}
html[lang=ar] div.marketOverviewSection div.wrapper h2.totalCount {
  font-size: calc(80px + 1px);
}
div.marketOverviewSection div.wrapper span.subheading {
  font-size: 14px;
  text-align: center;
  color: var(--light-text-color);
  font-weight: 400;
  display: block;
  text-transform: uppercase;
}
html[lang=ar] div.marketOverviewSection div.wrapper span.subheading {
  font-size: calc(14px + 1px);
}
div.marketOverviewSection div.wrapper div.salesOverviewSection {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 1200px) {
  div.marketOverviewSection div.wrapper div.salesOverviewSection {
    justify-content: center;
  }
}
div.marketOverviewSection div.wrapper div.salesOverviewSection div.overviewCardItem {
  width: 32%;
}
@media all and (max-width: 1200px) {
  div.marketOverviewSection div.wrapper div.salesOverviewSection div.overviewCardItem {
    width: 47%;
    margin-bottom: 25px;
    margin-right: 25px;
  }
  div.marketOverviewSection div.wrapper div.salesOverviewSection div.overviewCardItem:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  div.marketOverviewSection div.wrapper div.salesOverviewSection div.overviewCardItem:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.marketOverviewSection div.wrapper div.salesOverviewSection div.overviewCardItem {
    width: 100%;
    margin-right: 0;
  }
}
div.marketOverviewSection div.wrapper div.line {
  display: block;
  width: 50%;
  margin: 80px auto 40px;
  height: 1px;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.marketOverviewSection div.wrapper div.line {
    margin: 30px auto 30px;
  }
}

div.overviewCard div.top {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
@media all and (max-width: 1600px) {
  div.overviewCard div.top {
    min-height: 108px;
    align-items: center;
  }
}
div.overviewCard div.top h3 {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 400;
}
html[lang=ar] div.overviewCard div.top h3 {
  font-size: calc(20px + 1px);
}
div.overviewCard div.top span {
  color: var(--light-text-color);
  text-transform: uppercase;
  font-size: 12px;
}
html[lang=ar] div.overviewCard div.top span {
  font-size: calc(12px + 1px);
}
@media all and (max-width: 1200px) {
  div.overviewCard div.top span {
    font-size: 11px;
  }
  html[lang=ar] div.overviewCard div.top span {
    font-size: calc(11px + 1px);
  }
}
div.overviewCard div.top div.left {
  padding-right: 20px;
  border-right: 1px solid #fff;
  width: 50%;
}
div.overviewCard div.top div.right {
  width: 50%;
  text-align: right;
}
div.overviewCard div.bottom {
  padding: 20px 15px;
  font-size: 14px;
}
html[lang=ar] div.overviewCard div.bottom {
  font-size: calc(14px + 1px);
}
div.overviewCard div.bottom span {
  color: var(--light-text-color);
  text-transform: uppercase;
  font-weight: 300;
}
div.overviewCard div.bottom span.price {
  font-weight: 700;
  margin-left: 6px;
  color: var(--text-color);
}

#homePage div.blogSection div.imageContainer {
  width: 100%;
  height: 900px;
  position: relative;
}
@media all and (max-width: 1980px) {
  #homePage div.blogSection div.imageContainer {
    height: 1100px;
  }
}
@media all and (max-width: 1768px) {
  #homePage div.blogSection div.imageContainer {
    height: 900px;
  }
}
@media all and (max-width: 1400px) {
  #homePage div.blogSection div.imageContainer {
    height: 800px;
  }
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer {
    height: 700px;
  }
}
#homePage div.blogSection div.imageContainer img {
  object-fit: cover;
}
#homePage div.blogSection div.imageContainer div.gradient {
  position: absolute;
  background: linear-gradient(360deg, rgb(20, 27, 39) 15%, rgba(20, 27, 39, 0.8758096989) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
#homePage div.blogSection div.imageContainer div.contentContainer {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
#homePage div.blogSection div.imageContainer div.contentContainer h2.mainHeading {
  margin-bottom: 40px;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers {
    flex-direction: column;
  }
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left {
  width: 75%;
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left {
    width: 100%;
  }
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left div.imageContainer {
  position: relative;
  width: 100%;
  height: unset;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left div.imageContainer img {
  position: static !important;
  display: block;
  width: 100%;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left div.buttonContainer {
  margin-top: 20px;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left div.buttonContainer button {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left div.buttonContainer button {
    display: none;
  }
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left div.buttonContainer button span.icon {
  margin-left: 10px;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.left div.buttonContainer button span.icon svg {
  width: 20px !important;
  height: 20px !important;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right {
  width: 20%;
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right {
    display: flex;
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right a {
  margin-bottom: 30px;
  display: block;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right a:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right a:last-child div.imageContainer {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right a {
    width: 49%;
  }
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right a:nth-child(3) {
    display: none;
  }
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.imageContainer {
  position: relative;
  width: 100%;
  height: unset;
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.imageContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.imageContainer {
    width: 100%;
  }
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.imageContainer img {
  position: static !important;
  object-fit: cover;
  display: block;
  width: 100%;
}
#homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.buttonContainer {
  display: none;
}
@media all and (max-width: 768px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.buttonContainer {
    display: block;
  }
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.buttonContainer button {
    width: 9rem;
    font-size: 11px;
  }
  html[lang=ar] #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.buttonContainer button {
    font-size: calc(11px + 1px);
  }
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.buttonContainer button svg {
    display: none;
  }
}
@media all and (max-width: 768px) and (max-width: 640px) {
  #homePage div.blogSection div.imageContainer div.contentContainer div.containers div.right div.buttonContainer button {
    width: 100%;
  }
}

#homePage div.reviewSection {
  background-color: var(--secondary-bg-color);
  padding-bottom: 130px;
}
#homePage div.reviewSection h1.mainHeading {
  margin-bottom: 40px;
}
#homePage div.reviewSection div.reviews {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}
#homePage div.reviewSection div.reviews div.reviewCard {
  width: 92%;
  margin: 0 auto;
}
#homePage div.reviewSection div.reviews .slick-next::before {
  content: "";
}
#homePage div.reviewSection div.reviews div.arrow-left {
  position: absolute;
  left: -70px;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
}
html[lang=ar] #homePage div.reviewSection div.reviews div.arrow-left {
  font-size: calc(30px + 1px);
}
#homePage div.reviewSection div.reviews div.arrow-right {
  position: absolute;
  right: -70px;
  font-size: 30px !important;
  cursor: pointer;
  top: 50%;
}
html[lang=ar] #homePage div.reviewSection div.reviews div.arrow-right {
  font-size: calc(30px + 1px) !important;
}
#homePage div.reviewSection div.reviews div.arrow-right.slick-disabled {
  opacity: 0.3;
}
#homePage div.reviewSection div.reviews div.reviewItem {
  width: 31%;
}

div.reviewCard {
  padding: 20px;
}
div.reviewCard h1.name {
  text-transform: uppercase;
  color: var(--text-color);
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 20px;
}
html[lang=ar] div.reviewCard h1.name {
  font-size: calc(20px + 1px);
}
div.reviewCard p.description {
  text-transform: uppercase;
  color: var(--light-text-color);
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5em;
}
html[lang=ar] div.reviewCard p.description {
  font-size: calc(14px + 1px);
}
div.reviewCard div.stars {
  display: flex;
}
div.reviewCard div.stars span.icon {
  display: inline-block;
  margin-right: 6px;
}
div.reviewCard div.stars span.icon svg {
  width: 15px !important;
  height: 15px !important;
}

#footer {
  background-color: var(--primary-bg-color);
  padding: 70px 0 100px 0;
  border-top: 3px solid transparent;
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  position: relative;
  z-index: 2;
}
#footer div.map {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 90%;
  height: 100%;
  transform: translateX(-50%);
}
#footer div.map img {
  object-fit: contain;
  opacity: 0.2;
}
#footer div.map svg {
  width: 100%;
  height: 100%;
}
#footer div.map svg g {
  fill: #333333;
}
#footer div.wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 768px) {
  #footer div.wrapper {
    flex-direction: column;
  }
}
@media all and (max-width: 768px) {
  #footer div.wrapper div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
#footer div.wrapper div.left div.logo {
  position: relative;
  width: 100px;
  height: 40px;
  margin-bottom: 30px;
}
#footer div.wrapper div.left div.logo img {
  object-fit: contain;
}
#footer div.wrapper div.left div.social {
  display: flex;
  margin-bottom: 10px;
}
#footer div.wrapper div.left div.social span.icon {
  display: block;
  margin-right: 15px;
  cursor: pointer;
}
#footer div.wrapper div.left div.social span.icon svg {
  width: 25px !important;
  height: 25px !important;
}
#footer div.wrapper div.left div.links {
  display: flex;
}
#footer div.wrapper div.left div.links span.link {
  font-size: 13px;
  font-weight: 300;
  color: var(--text-color);
}
html[lang=ar] #footer div.wrapper div.left div.links span.link {
  font-size: calc(13px + 1px);
}
#footer div.wrapper div.left div.links span.link:first-child {
  padding-right: 10px;
  border-right: 1px solid #fff;
  margin-right: 10px;
}
#footer div.wrapper div.left span.copyWright {
  color: var(--text-color);
  font-weight: 300;
  font-size: 13px;
  margin-top: 6px;
  display: block;
}
html[lang=ar] #footer div.wrapper div.left span.copyWright {
  font-size: calc(13px + 1px);
}
#footer div.wrapper div.right {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
@media all and (max-width: 992px) {
  #footer div.wrapper div.right {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  #footer div.wrapper div.right {
    justify-content: flex-start;
  }
}
@media all and (max-width: 576px) {
  #footer div.wrapper div.right {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 768px) {
  #footer div.wrapper div.right div.linkItem {
    width: -moz-fit-content;
    width: fit-content;
  }
}
#footer div.wrapper div.right div.linkItem ul.subLinks {
  display: none;
}
#footer div.wrapper div.right div.linkItem ul li.link {
  margin-bottom: 10px;
}
#footer div.wrapper div.right div.linkItem ul li.link a {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 300;
}
html[lang=ar] #footer div.wrapper div.right div.linkItem ul li.link a {
  font-size: calc(13px + 1px);
}

#faq {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#faq .container {
  width: 100%;
}
#faq .container .sectionTitle {
  font-size: 1.563rem;
  margin-bottom: 3.75rem;
  line-height: 2rem;
}
html[lang=ar] #faq .container .sectionTitle {
  font-size: calc(1.563rem + 1px);
}
#faq .container .parent {
  margin: 0 auto;
  width: 50%;
}
#faq .container .parent .item {
  background-color: var(--primary-bg-color);
  margin: 0.813rem 0;
  padding: 1.125rem;
  border: 0rem solid transparent;
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  transition: border 0.3s ease-out;
}
#faq .container .parent .item.active {
  border: 1px solid transparent;
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
}
#faq .container .parent .item .question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#faq .container .parent .item .question .title {
  cursor: pointer;
  margin: 0;
  font-feature-settings: "clig" off, "liga" off;
  font-family: var(--audrey-font-family);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
html[lang=ar] #faq .container .parent .item .question .title {
  font-size: calc(1rem + 1px);
}
#faq .container .parent .item .question img {
  cursor: pointer;
  width: 4.375rem;
  height: 4.375rem;
}
#faq .container .parent .item .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  color: #FFF;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  width: 90%;
}
html[lang=ar] #faq .container .parent .item .answer {
  font-size: calc(0.875rem + 1px);
}
#faq .container .parent .item .answer.active {
  max-height: 12.5rem;
}

@media all and (max-width: 61.25rem) {
  #faq .container .sectionTitle {
    font-size: 1.313rem;
    margin: 0 auto;
    width: 85%;
    margin-bottom: 2rem;
  }
  html[lang=ar] #faq .container .sectionTitle {
    font-size: calc(1.313rem + 1px);
  }
  #faq .container .parent {
    width: 85%;
  }
  #faq .container .parent .item .question {
    padding-bottom: 0.625rem;
  }
  #faq .container .parent .item .question img {
    width: 3.125rem;
    height: 3.125rem;
  }
  #faq .container .parent .item .answer {
    padding: unset;
    line-height: 1.25rem;
    width: 100%;
  }
}
div#ourTeam {
  width: 100%;
  padding-top: 220px;
  background-color: var(--primary-bg-color);
  overflow-x: hidden;
}
div#ourTeam div.featuredMembers {
  width: 100%;
  position: relative;
  z-index: 8;
}
div#ourTeam div.featuredMembers div.featuredListContainer {
  position: relative;
}
div#ourTeam div.featuredMembers div.featuredListContainer div.item div.imageContainer {
  position: relative;
  height: 700px;
  box-shadow: inset 24px 84px 221px rgba(0, 0, 0, 0.6);
}
@media all and (min-width: 1768px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.item div.imageContainer {
    height: 800px;
  }
}
@media all and (max-width: 640px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.item div.imageContainer {
    height: 500px;
  }
}
div#ourTeam div.featuredMembers div.featuredListContainer div.item div.imageContainer img {
  object-fit: cover;
}
div#ourTeam div.featuredMembers div.featuredListContainer div.overlayImage {
  width: 30%;
  height: 701px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
}
@media all and (min-width: 1768px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.overlayImage {
    height: 921px;
    width: 30%;
  }
}
@media all and (min-width: 1980px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.overlayImage {
    width: 20%;
  }
}
@media all and (max-width: 1979px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.overlayImage {
    width: 30%;
  }
}
@media all and (max-width: 980px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.overlayImage {
    display: none;
  }
}
div#ourTeam div.featuredMembers div.featuredListContainer div.overlayImage img {
  object-fit: cover;
}
div#ourTeam div.featuredMembers div.featuredListContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, var(--primary-bg-color) 15%, rgba(18, 24, 35, 0) 100%);
  height: 700px;
  z-index: 1;
}
@media all and (min-width: 1980px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.gradient {
    height: 900px;
  }
}
@media all and (max-width: 980px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.gradient {
    bottom: 100px;
    height: 300px;
  }
}
@media all and (max-width: 640px) {
  div#ourTeam div.featuredMembers div.featuredListContainer div.gradient {
    bottom: 37px;
    height: 500px;
  }
}
div#ourTeam div.featuredMembers div.content {
  background-color: var(--primary-bg-color);
  position: relative;
  margin-top: -100px;
  z-index: 2;
  padding-top: 40px;
}
div#ourTeam div.featuredMembers div.content div.logo {
  position: relative;
  width: 80px;
  height: 40px;
  margin: 0 auto 10px;
}
div#ourTeam div.featuredMembers div.content div.logo img {
  object-fit: contain;
}
div#ourTeam div.featuredMembers div.content h1.mainHeading {
  color: var(--text-color);
  font-weight: 300;
  margin-bottom: 10px;
}
div#ourTeam div.featuredMembers div.content span.dropDownArrow {
  text-align: center;
  display: none;
  margin-bottom: 15px;
}
div#ourTeam div.featuredMembers div.content span.dropDownArrow svg {
  width: 12px !important;
  height: 12px !important;
}
div#ourTeam div.featuredMembers div.content p.description {
  text-align: center;
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  max-width: 810px;
  margin: 0 auto 50px;
  line-height: 2em;
}
html[lang=ar] div#ourTeam div.featuredMembers div.content p.description {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 640px) {
  div#ourTeam div.featuredMembers div.content p.description {
    font-size: 12px;
    max-width: 90%;
  }
  html[lang=ar] div#ourTeam div.featuredMembers div.content p.description {
    font-size: calc(12px + 1px);
  }
}
div#ourTeam div.featuredMembers div.content div.experienceSection ul {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin: 0 auto 30px;
  text-align: center;
}
@media all and (max-width: 1400px) {
  div#ourTeam div.featuredMembers div.content div.experienceSection ul {
    width: 85%;
  }
}
@media all and (max-width: 980px) {
  div#ourTeam div.featuredMembers div.content div.experienceSection ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}
div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts {
  width: 20%;
}
@media all and (max-width: 980px) {
  div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts {
    width: 30%;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 640px) {
  div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts {
    width: 100%;
  }
}
div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts .title {
  color: rgb(188, 157, 141);
  font-weight: 300;
  font-size: 28px;
  text-transform: uppercase;
}
html[lang=ar] div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts .title {
  font-size: calc(28px + 1px);
}
@media all and (max-width: 640px) {
  div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts .title {
    font-size: 20px;
  }
  html[lang=ar] div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts .title {
    font-size: calc(20px + 1px);
  }
}
div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts span {
  color: rgb(172, 131, 109);
  font-weight: 500;
  font-size: 13px;
  display: block;
  text-align: center;
  text-transform: uppercase;
}
html[lang=ar] div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts span {
  font-size: calc(13px + 1px);
}
@media all and (max-width: 640px) {
  div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts span {
    font-size: 11px;
  }
  html[lang=ar] div#ourTeam div.featuredMembers div.content div.experienceSection ul li.texts span {
    font-size: calc(11px + 1px);
  }
}
div#ourTeam div.featuredMembers div.content div.experienceSection div.gradientLine {
  width: 60%;
  margin: 0 auto;
  height: 1px;
  position: relative;
}
@media all and (max-width: 1400px) {
  div#ourTeam div.featuredMembers div.content div.experienceSection div.gradientLine {
    width: 85%;
  }
}
div#ourTeam div.formContainer div.imageContainer {
  height: 550px;
  width: 100%;
  position: relative;
}
@media all and (max-width: 980px) {
  div#ourTeam div.formContainer div.imageContainer {
    height: 800px;
  }
}
div#ourTeam div.formContainer div.imageContainer img {
  object-fit: cover;
}
div#ourTeam div.formContainer div.imageContainer div.gradient {
  position: absolute;
  background: linear-gradient(180deg, rgb(13, 18, 28) 15%, rgba(20, 27, 39, 0.8758096989) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
div#ourTeam div.formContainer div.contentContainer {
  position: absolute;
  width: 85%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
@media all and (max-width: 980px) {
  div#ourTeam div.formContainer div.contentContainer {
    width: 85%;
  }
}
div#ourTeam div.formContainer div.contentContainer h1.heading {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color);
  margin-bottom: 50px;
}
html[lang=ar] div#ourTeam div.formContainer div.contentContainer h1.heading {
  font-size: calc(14px + 1px);
}
div#ourTeam div.formContainer div.contentContainer div.contactForm {
  width: 90%;
  padding: 90px 40px 80px;
  margin: 0 auto;
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow {
  display: flex;
  margin-bottom: 30px;
}
@media all and (max-width: 1050px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 980px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow {
    margin-bottom: 0;
  }
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow:last-child {
  margin-bottom: 0;
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer {
  margin-right: 30px;
  width: 23%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer {
    width: 46%;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 640px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer {
    width: 40%;
  }
}
@media all and (max-width: 480px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer {
    width: 100%;
  }
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer:last-child {
  margin-right: 0;
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer span.specialization {
  color: var(--placeholder-color);
  font-size: 14px;
  text-transform: uppercase;
}
html[lang=ar] div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer span.specialization {
  font-size: calc(14px + 1px);
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer span.icon {
  display: inline-block;
  margin-left: 10px;
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer span.icon svg {
  width: 10px !important;
  height: 10px !important;
}
div#ourTeam div.formContainer div.contentContainer div.contactForm div.oneRow div.inputContainer span.icon svg path {
  stroke: var(--placeholder-color);
}
div#ourTeam div.formContainer div.contentContainer div.contactForm input {
  width: 23%;
  display: block;
  background-color: transparent;
  border-bottom: 1px solid var(--link-color);
  padding-bottom: 8px;
  caret-color: #fff;
  color: var(--text-color);
  font-size: 16px;
  margin-right: 30px;
}
html[lang=ar] div#ourTeam div.formContainer div.contentContainer div.contactForm input {
  font-size: calc(16px + 1px);
}
@media all and (max-width: 1050px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm input {
    width: 30%;
  }
  div#ourTeam div.formContainer div.contentContainer div.contactForm input:nth-child(3) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm input {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
}
div#ourTeam div.formContainer div.contentContainer div.contactForm input::placeholder {
  letter-spacing: 1.6px;
  font-size: 14px;
}
html[lang=ar] div#ourTeam div.formContainer div.contentContainer div.contactForm input::placeholder {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 1050px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm button {
    margin-top: 30px;
  }
}
@media all and (max-width: 640px) {
  div#ourTeam div.formContainer div.contentContainer div.contactForm button {
    width: 100%;
  }
}

div.spTeamContainer {
  margin-top: 60px;
  position: relative;
}
div.spTeamContainer h2.heading {
  text-transform: uppercase;
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
  font-weight: 300;
}
html[lang=ar] div.spTeamContainer h2.heading {
  font-size: calc(25px + 1px);
}
@media all and (max-width: 640px) {
  div.spTeamContainer h2.heading {
    font-size: 22px;
  }
  html[lang=ar] div.spTeamContainer h2.heading {
    font-size: calc(22px + 1px);
  }
}
div.spTeamContainer div.teamListing {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
}
@media all and (max-width: 980px) {
  div.spTeamContainer div.teamListing {
    display: none;
  }
}
div.spTeamContainer div.teamListing div.item {
  width: 31%;
  margin-right: 40px;
  margin-bottom: 40px;
}
div.spTeamContainer div.teamListing div.item.ar {
  margin-right: unset;
  margin-left: 40px;
}
div.spTeamContainer div.teamListing div.item:nth-child(3n) {
  margin-right: 0;
}
div.spTeamContainer div.teamListing div.item:nth-child(3n).ar {
  margin-left: unset;
}
@media all and (max-width: 1400px) {
  div.spTeamContainer div.teamListing div.item {
    width: 32%;
    margin-right: 20px;
  }
  div.spTeamContainer div.teamListing div.item.ar {
    margin-right: unset;
    margin-left: 20px;
  }
}
@media all and (max-width: 1200px) {
  div.spTeamContainer div.teamListing div.item {
    width: 48%;
  }
  div.spTeamContainer div.teamListing div.item.ar {
    margin-left: 10px;
  }
  div.spTeamContainer div.teamListing div.item:nth-child(3n) {
    margin-right: 20px;
  }
  div.spTeamContainer div.teamListing div.item:nth-child(3n).ar {
    margin-right: unset;
    margin-left: 10px;
  }
  div.spTeamContainer div.teamListing div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.spTeamContainer div.teamListing div.item {
    width: 100%;
    margin-right: 0;
  }
  div.spTeamContainer div.teamListing div.item:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.spTeamContainer div.teamListing div.item {
    margin-right: 30px;
    width: 20rem;
  }
  div.spTeamContainer div.teamListing div.item:nth-child(3n) {
    margin-right: 30px;
  }
  div.spTeamContainer div.teamListing div.item:nth-child(2n) {
    margin-right: 30px;
  }
}
div.spTeamContainer div.carouselListing {
  display: none;
}
@media all and (max-width: 980px) {
  div.spTeamContainer div.carouselListing {
    display: block;
  }
  div.spTeamContainer div.carouselListing div.slick-slide {
    padding-right: 15px;
  }
  div.spTeamContainer div.carouselListing div.slick-list {
    overflow: visible !important;
  }
}
@media all and (max-width: 980px) and (max-width: 640px) {
  div.spTeamContainer div.carouselListing div.slick-list {
    padding: 0 0 !important;
  }
}
div.spTeamContainer div.gradientSection {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, rgb(13, 18, 28) 66%, rgba(18, 24, 35, 0) 100%);
  height: 211px;
  z-index: 1;
}
@media all and (max-width: 980px) {
  div.spTeamContainer div.gradientSection {
    display: none;
  }
}
div.spTeamContainer div.gradientSection button.viewMore {
  position: absolute;
  color: var(--text-color);
  z-index: 2;
  bottom: 50px;
  right: 50px;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.spTeamContainer div.gradientSection button.viewMore {
    display: none;
  }
}
div.spTeamContainer div.gradientSection button.viewMore span.text {
  letter-spacing: 1.6px;
  display: block;
  margin-right: 10px;
  line-height: 0;
}
div.spTeamContainer div.gradientSection button.viewMore span.icon {
  line-height: 0;
}
div.spTeamContainer div.gradientSection button.viewMore span.icon svg {
  width: 18px !important;
  height: 18px !important;
}
div.spTeamContainer div.gradientLine {
  width: 60%;
  margin: 0 auto;
  height: 1px;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 980px) {
  div.spTeamContainer div.gradientLine {
    display: none;
  }
}
div.landscapeTeamCard {
  display: flex;
  min-height: 200px;
}
@media all and (min-width: 1700px) {
  div.landscapeTeamCard {
    min-height: 250px;
  }
}
@media all and (max-width: 980px) {
  div.landscapeTeamCard {
    min-height: 273px;
  }
}
@media all and (max-width: 768px) {
  div.landscapeTeamCard {
    min-height: 240px;
  }
}
@media all and (max-width: 640px) {
  div.landscapeTeamCard {
    flex-direction: column;
    min-height: 584px;
  }
}
@media all and (max-width: 480px) {
  div.landscapeTeamCard {
    min-height: 450px;
  }
}
div.landscapeTeamCard div.left {
  width: 40%;
}
@media all and (max-width: 640px) {
  div.landscapeTeamCard div.left {
    width: 100%;
  }
}
div.landscapeTeamCard div.left div.imageContainer {
  width: 100%;
  position: relative;
  height: 100%;
}
@media all and (max-width: 1200px) {
  div.landscapeTeamCard div.left div.imageContainer {
    height: 247px;
  }
}
@media all and (max-width: 980px) {
  div.landscapeTeamCard div.left div.imageContainer {
    height: 348px;
  }
}
@media all and (max-width: 768px) {
  div.landscapeTeamCard div.left div.imageContainer {
    height: 250px;
  }
}
@media all and (max-width: 640px) {
  div.landscapeTeamCard div.left div.imageContainer {
    height: 434px;
  }
}
@media all and (max-width: 480px) {
  div.landscapeTeamCard div.left div.imageContainer {
    height: 298px;
  }
}
@media all and (max-width: 416px) {
  div.landscapeTeamCard div.left div.imageContainer {
    height: 270px;
  }
}
div.landscapeTeamCard div.left div.imageContainer img {
  object-fit: cover;
  height: 100%;
}
div.landscapeTeamCard div.right {
  width: 60%;
  padding: 15px 0 15px 15px;
  color: var(--text-color);
}
div.landscapeTeamCard div.right.ar {
  margin-right: 1rem;
}
@media all and (max-width: 640px) {
  div.landscapeTeamCard div.right {
    width: 100%;
    height: 180px;
  }
}
div.landscapeTeamCard div.right h3.name {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 18px;
}
html[lang=ar] div.landscapeTeamCard div.right h3.name {
  font-size: calc(18px + 1px);
}
@media all and (max-width: 1400px) {
  div.landscapeTeamCard div.right h3.name {
    font-size: 14px;
  }
  html[lang=ar] div.landscapeTeamCard div.right h3.name {
    font-size: calc(14px + 1px);
  }
}
div.landscapeTeamCard div.right span {
  text-transform: capitalize;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 6px;
  display: block;
}
html[lang=ar] div.landscapeTeamCard div.right span {
  font-size: calc(14px + 1px);
}
div.landscapeTeamCard div.right span.language {
  margin-bottom: 20px;
  text-transform: capitalize;
}
@media all and (max-width: 1400px) {
  div.landscapeTeamCard div.right span {
    font-size: 12px;
  }
  html[lang=ar] div.landscapeTeamCard div.right span {
    font-size: calc(12px + 1px);
  }
}
@media all and (max-width: 640px) {
  div.landscapeTeamCard div.right span {
    font-size: 10px;
  }
  html[lang=ar] div.landscapeTeamCard div.right span {
    font-size: calc(10px + 1px);
  }
}
div.landscapeTeamCard div.right .langHead {
  text-transform: capitalize;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 6px;
  display: block;
  margin-top: 10px;
}
html[lang=ar] div.landscapeTeamCard div.right .langHead {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 1400px) {
  div.landscapeTeamCard div.right .langHead {
    font-size: 12px;
  }
  html[lang=ar] div.landscapeTeamCard div.right .langHead {
    font-size: calc(12px + 1px);
  }
}
@media all and (max-width: 640px) {
  div.landscapeTeamCard div.right .langHead {
    font-size: 10px;
  }
  html[lang=ar] div.landscapeTeamCard div.right .langHead {
    font-size: calc(10px + 1px);
  }
}
div.landscapeTeamCard div.right p.description {
  text-transform: uppercase;
  color: var(--light-text-color);
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 10px;
}
html[lang=ar] div.landscapeTeamCard div.right p.description {
  font-size: calc(12px + 1px);
}
div.landscapeTeamCard div.right a.icon {
  position: relative;
  z-index: 9;
}
div.landscapeTeamCard div.right a.icon svg {
  width: 25px !important;
  height: 25px !important;
}

#searchForm {
  padding-bottom: 60px;
  border-bottom: 1px solid var(--border-color);
}
@media all and (max-width: 980px) {
  #searchForm {
    border: 1px solid transparent; /* Set the border width and make it transparent */
    border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
    border-image-slice: 1;
    padding: 20px;
  }
}
#searchForm div.mainContainer {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1400px) {
  #searchForm div.mainContainer {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
@media all and (max-width: 1200px) {
  #searchForm div.mainContainer {
    flex-direction: column;
    align-items: center;
  }
}
#searchForm div.mainContainer div.formContainer {
  width: 80%;
  display: flex;
}
#searchForm div.mainContainer div.formContainer.ar {
  justify-content: start;
}
@media all and (max-width: 1400px) {
  #searchForm div.mainContainer div.formContainer {
    width: 100%;
    justify-content: space-between;
  }
}
@media all and (max-width: 1200px) {
  #searchForm div.mainContainer div.formContainer {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer {
    flex-direction: column;
    width: 100%;
  }
}
#searchForm div.mainContainer div.formContainer div.inputContainer {
  width: 36%;
  margin-right: 10px;
  border: 1px solid var(--border-color);
  padding: 10px;
  cursor: pointer;
  position: relative;
}
#searchForm div.mainContainer div.formContainer div.inputContainer.ar {
  margin-right: unset;
}
@media all and (max-width: 1400px) {
  #searchForm div.mainContainer div.formContainer div.inputContainer {
    width: 39%;
    margin-right: 0;
  }
}
@media all and (max-width: 1200px) {
  #searchForm div.mainContainer div.formContainer div.inputContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}
#searchForm div.mainContainer div.formContainer div.inputContainer input {
  width: 100%;
  background-color: transparent;
  font-size: 12px;
  caret-color: #fff;
  color: #fff;
  font-size: 14px;
}
html[lang=ar] #searchForm div.mainContainer div.formContainer div.inputContainer input {
  font-size: calc(12px + 1px);
}
html[lang=ar] #searchForm div.mainContainer div.formContainer div.inputContainer input {
  font-size: calc(14px + 1px);
}
#searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
  letter-spacing: 1.6px;
  font-size: 13px;
  font-family: var(--lato-font-family);
}
html[lang=ar] #searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
  font-size: calc(13px + 1px);
}
@media all and (max-width: 576px) {
  #searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
    font-size: 12px;
  }
  html[lang=ar] #searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
    font-size: calc(12px + 1px);
  }
}
@media all and (max-width: 425px) {
  #searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
    font-size: 11px;
  }
  html[lang=ar] #searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
    font-size: calc(11px + 1px);
  }
}
@media all and (max-width: 375px) {
  #searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
    font-size: 10px;
  }
  html[lang=ar] #searchForm div.mainContainer div.formContainer div.inputContainer input::placeholder {
    font-size: calc(10px + 1px);
  }
}
#searchForm div.mainContainer div.formContainer div.inputContainer input.ar::placeholder {
  letter-spacing: normal;
}
#searchForm div.mainContainer div.formContainer div.inputContainer div.dropDown {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  max-height: 15rem;
  overflow-y: scroll;
  z-index: 1;
  left: 0;
  right: 0;
}
#searchForm div.mainContainer div.formContainer div.inputContainer div.dropDown ul.lists li {
  padding: 10px;
  transition: 0.2s ease-in-out;
  text-align: center;
}
#searchForm div.mainContainer div.formContainer div.inputContainer div.dropDown ul.lists li:hover {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}
#searchForm div.mainContainer div.formContainer div.inputContainer div.dropDown ul.lists li span {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#searchForm div.mainContainer div.formContainer div.selectContainer {
  display: flex;
  width: 60%;
}
@media all and (max-width: 1200px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer {
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer {
    width: 100%;
    flex-direction: column;
  }
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field {
  padding: 10px 12px;
  border: 1px solid var(--border-color);
  margin-right: 10px;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field.ar {
    margin-right: unset;
  }
}
@media all and (max-width: 1400px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field {
    width: 43%;
  }
}
@media all and (max-width: 1200px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field {
    width: 49%;
  }
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field {
    width: 100%;
    margin-bottom: 20px;
  }
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3) div.dropDown, #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) div.dropDown {
  width: 15rem;
  padding: 10px;
}
@media all and (max-width: 1400px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3) div.dropDown, #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) div.dropDown {
    width: 10rem;
  }
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3) div.dropDown, #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) div.dropDown {
    width: 100%;
  }
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3) div.dropDown div.dropDownSelectContainer, #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) div.dropDown div.dropDownSelectContainer {
  width: 100%;
  margin-bottom: 20px;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3) div.dropDown div.dropDownSelectContainer .css-13cymwt-control, #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) div.dropDown div.dropDownSelectContainer .css-13cymwt-control {
  border: none;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3) div.dropDown div.dropDownSelectContainer:last-child, #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) div.dropDown div.dropDownSelectContainer:last-child {
  margin-bottom: 0;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field div.dropDown {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow-y: scroll;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field div.dropDown ul.lists li {
  padding: 10px;
  transition: 0.2s ease-in-out;
  text-align: center;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field div.dropDown ul.lists li:hover {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:last-child.ar {
  margin-right: 10px;
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field:last-child.ar {
    margin-right: unset;
  }
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(2n) {
  width: 25%;
}
@media all and (max-width: 1200px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(2n) {
    width: 23%;
  }
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(2n) {
    width: 100%;
  }
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3), #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) {
  width: 30%;
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(3), #searchForm div.mainContainer div.formContainer div.selectContainer div.field:nth-child(4) {
    width: 100%;
  }
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field span.text {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 11px;
}
html[lang=ar] #searchForm div.mainContainer div.formContainer div.selectContainer div.field span.text {
  font-size: calc(11px + 1px);
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field span.icon {
  display: block;
  margin-left: 5px;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field span.icon svg {
  width: 10px !important;
  height: 10px !important;
}
#searchForm div.mainContainer div.formContainer div.selectContainer div.field span.icon svg path {
  stroke: var(--placeholder-color);
}
#searchForm div.mainContainer div.buttonContainer {
  width: 16%;
  display: flex;
  justify-content: flex-end;
}
#searchForm div.mainContainer div.buttonContainer.resetButton {
  margin-right: 1rem;
}
#searchForm div.mainContainer div.buttonContainer.resetButton.ar {
  margin-left: 0.5rem;
}
@media all and (max-width: 1400px) {
  #searchForm div.mainContainer div.buttonContainer {
    margin-top: 30px;
  }
}
@media all and (max-width: 1200px) {
  #searchForm div.mainContainer div.buttonContainer {
    width: 21%;
  }
  #searchForm div.mainContainer div.buttonContainer.resetButton {
    margin-right: unset;
  }
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.buttonContainer {
    width: 100%;
  }
}
#searchForm div.mainContainer div.buttonContainer button {
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  padding: 12px 10px;
}
@media all and (max-width: 980px) {
  #searchForm div.mainContainer div.buttonContainer button {
    padding: 12px 10px;
    background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  }
  #searchForm div.mainContainer div.buttonContainer button span.text {
    color: #000;
  }
  #searchForm div.mainContainer div.buttonContainer button span.icon svg {
    stroke: #000;
  }
}
#searchForm div.mainContainer div.buttonContainer button:hover {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
}
#searchForm div.mainContainer div.buttonContainer button:hover span.text {
  color: #000;
}
#searchForm div.mainContainer div.buttonContainer button:hover span.icon svg {
  stroke: #000;
}
#searchForm div.mainContainer div.buttonContainer button span.text {
  text-transform: uppercase;
  color: var(--text-color);
  letter-spacing: 1.6px;
  font-size: 11px;
  font-family: var(--lato-font-family);
}
html[lang=ar] #searchForm div.mainContainer div.buttonContainer button span.text {
  font-size: calc(11px + 1px);
}
#searchForm div.mainContainer div.buttonContainer button span.icon {
  display: block;
  margin-left: 10px;
}
#searchForm div.mainContainer div.buttonContainer button span.icon svg {
  width: 12px !important;
  height: 12px !important;
  stroke: var(--text-color);
}
div.agentContactCardV2 {
  padding: 20px 10px 20px 10px;
  border: 1px solid var(--border-color);
  width: 100%;
  background-color: var(--primary-bg-color);
}
div.agentContactCardV2 div.top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
div.agentContactCardV2 div.top div.left {
  width: 30%;
  margin-right: 15px;
}
div.agentContactCardV2 div.top div.left div.imageContainer {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid var(--border-color);
}
div.agentContactCardV2 div.top div.left div.imageContainer.placeHolder {
  padding: 6px;
  width: 100px;
  height: 100px;
}
div.agentContactCardV2 div.top div.left div.imageContainer img {
  object-fit: cover;
  position: static !important;
  display: block;
  width: 100%;
  border-radius: 50%;
}
div.agentContactCardV2 div.top div.right {
  width: 59%;
}
div.agentContactCardV2 div.top div.right h1.name, div.agentContactCardV2 div.top div.right h2.name {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  text-transform: uppercase;
}
html[lang=ar] div.agentContactCardV2 div.top div.right h1.name, html[lang=ar] div.agentContactCardV2 div.top div.right h2.name {
  font-size: calc(14px + 1px);
}
div.agentContactCardV2 div.top div.right h6.title {
  color: var(--light-text-color);
  font-size: 12px;
  font-weight: 300;
}
html[lang=ar] div.agentContactCardV2 div.top div.right h6.title {
  font-size: calc(12px + 1px);
}
div.agentContactCardV2 div.bottom h1, div.agentContactCardV2 div.bottom h2 {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 15px;
}
html[lang=ar] div.agentContactCardV2 div.bottom h1, html[lang=ar] div.agentContactCardV2 div.bottom h2 {
  font-size: calc(14px + 1px);
}
div.agentContactCardV2 div.bottom div.buttons {
  width: 100%;
}
div.agentContactCardV2 div.bottom div.buttonContainer {
  width: 100%;
  margin-right: 8px;
  margin-bottom: 10px;
}
div.agentContactCardV2 div.bottom div.buttonContainer.ar {
  margin-right: unset;
}
div.agentContactCardV2 div.bottom div.buttonContainer:last-child {
  margin-bottom: 0;
}
div.agentContactCardV2 div.bottom div.buttonContainer button {
  padding: 13px 15px;
  border: 1px solid var(--border-color);
  background-color: transparent;
  color: var(--text-color);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  font-family: var(--lato-font-family);
}
div.agentContactCardV2 div.bottom div.buttonContainer button:hover {
  background: var(--tertiary-color);
  color: #000;
  transition: 0.2s ease-in-out;
}
@media all and (max-width: 1400px) {
  div.agentContactCardV2 div.bottom div.buttonContainer button {
    padding: 13px 8px;
  }
}
div.agentContactCardV2 div.bottom div.buttonContainer button span.icon {
  display: block;
  margin-right: 5px;
  line-height: 0;
}
div.agentContactCardV2 div.bottom div.buttonContainer button span.icon svg {
  width: 12px !important;
  height: 12px !important;
  stroke: var(--text-color);
}
div.agentContactCardV2 div.bottom div.buttonContainer button span.text {
  font-size: 10px;
  font-weight: 300;
  line-height: 0;
  letter-spacing: 1.6px;
}
html[lang=ar] div.agentContactCardV2 div.bottom div.buttonContainer button span.text {
  font-size: calc(10px + 1px);
}
div.agentContactCardV2 div.bottom div.inputContainer {
  margin-bottom: 15px;
  position: relative;
  border: 1px solid var(--border-color);
}
div.agentContactCardV2 div.bottom div.inputContainer.error {
  border-color: var(--error-color);
}
div.agentContactCardV2 div.bottom div.inputContainer input {
  background-color: var(--secondary-bg-color);
  width: 100%;
  padding: 10px;
  color: var(--text-color);
  font-family: var(--lato-font-family);
}
div.agentContactCardV2 div.bottom div.inputContainer input:focus {
  border: 1px solid var(--tertiary-color);
  box-shadow: 0 0 10px rgba(206, 160, 113, 0.368627451);
}
div.agentContactCardV2 div.bottom div.inputContainer span.errorIcon {
  position: absolute;
  right: 10px;
  transform: translateX(0);
  animation: zoomIn 0.6s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
}
div.agentContactCardV2 div.bottom div.inputContainer span.errorIcon svg {
  width: 20px !important;
  height: 20px !important;
}
div.agentContactCardV2 div.bottom div.inputContainer textarea {
  background-color: var(--secondary-bg-color);
  width: 100%;
  padding: 10px;
  color: var(--text-color);
  resize: none;
  outline: none;
  border: none;
  font-family: var(--lato-font-family);
}
div.agentContactCardV2 div.bottom div.inputContainer textarea:focus {
  border: 1px solid var(--tertiary-color);
  outline: none !important;
  box-shadow: 0 0 10px rgba(206, 160, 113, 0.368627451);
}
div.agentContactCardV2 .PhoneInputInput {
  padding-right: 0.5rem;
  background-color: var(--secondary-bg-color) !important;
  width: 100% !important;
  padding: 10px !important;
  color: var(--text-color) !important;
  outline: none !important;
  border: 1px solid var(--border-color) !important;
}
div.agentContactCardV2 .PhoneInputInput:focus {
  border: 1px solid var(--tertiary-color) !important;
  outline: none !important;
  box-shadow: 0 0 10px rgba(206, 160, 113, 0.368627451) !important;
}
div.agentContactCardV2 .errorIcon {
  position: absolute;
  right: 20px;
  top: 52.3%;
  transform: translateY(-50%);
  animation: zoomIn 0.6s ease-in-out;
}
div.agentContactCardV2 .errorIcon svg {
  width: 20px !important;
  height: 20px !important;
}
div.agentContactCardV2 .PhoneInputInput {
  border-radius: 0px;
  padding-right: 0.5rem;
}
div.agentContactCardV2 .error .PhoneInputInput {
  border: 1px solid var(--error-color) !important;
}

div.agentStickyV2 {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  padding-bottom: 80px;
  z-index: 99;
  overflow-y: scroll;
  height: 100vh;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
div.agentStickyV2::-webkit-scrollbar {
  display: none;
}
@media all and (max-width: 1200px) {
  div.agentStickyV2 {
    position: static;
    height: auto;
    overflow: auto;
    padding-bottom: 20px;
  }
}

div.contactBtns {
  border: 1px solid var(--border-color);
  padding: 6px;
  margin-bottom: 15px;
  background-color: var(--primary-bg-color);
  z-index: 9;
}
div.contactBtns div.top h1.price, div.contactBtns div.top h2.price {
  margin-bottom: 10px;
  text-align: center;
  font-family: unset !important;
}
div.contactBtns div.bottom div.buttonContainer {
  display: flex;
}
@media all and (max-width: 1200px) {
  div.contactBtns div.bottom div.buttonContainer {
    justify-content: space-between;
  }
}
@media all and (max-width: 320px) {
  div.contactBtns div.bottom div.buttonContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}
div.contactBtns div.bottom div.buttonContainer a.gradientBorder {
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  width: 32%;
}
@media all and (max-width: 320px) {
  div.contactBtns div.bottom div.buttonContainer a.gradientBorder {
    width: 47%;
    margin-bottom: 10px;
  }
}
div.contactBtns div.bottom div.buttonContainer a.gradientBorder span.icon {
  line-height: 0;
}
div.contactBtns div.bottom div.buttonContainer a.gradientBorder span.icon.ar {
  margin-left: 0.5rem;
}
div.contactBtns div.bottom div.buttonContainer a.gradientBorder span.icon svg {
  width: 15px !important;
  height: 15px !important;
}
div.contactBtns div.bottom div.buttonContainer a.gradientBorder span.text {
  color: var(--light-text-color);
  display: block;
  margin-left: 6px;
  font-size: 12px;
  font-family: var(--lato-font-family);
}
html[lang=ar] div.contactBtns div.bottom div.buttonContainer a.gradientBorder span.text {
  font-size: calc(12px + 1px);
}
div.contactBtns div.bottom div.buttonContainer button {
  background-color: transparent;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 32%;
  justify-content: center;
  cursor: pointer;
}
@media all and (max-width: 320px) {
  div.contactBtns div.bottom div.buttonContainer button {
    width: 47%;
    margin-bottom: 10px;
  }
}
div.contactBtns div.bottom div.buttonContainer button.whatsapp {
  margin-right: 0;
  display: block;
}
div.contactBtns div.bottom div.buttonContainer button:last-child {
  margin-right: 0;
}
div.contactBtns div.bottom div.buttonContainer button span.icon {
  line-height: 0;
}
div.contactBtns div.bottom div.buttonContainer button span.icon.ar {
  margin-left: 0.5rem;
}
div.contactBtns div.bottom div.buttonContainer button span.text {
  color: var(--light-text-color);
  display: block;
  margin-left: 6px;
  font-family: var(--lato-font-family);
}

#searchPage {
  padding: 130px 0;
  background-color: var(--primary-bg-color);
}

div.searchResults {
  padding-top: 3rem;
}
div.searchResults div.mainHeadingDiv {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; /* Ensure space between heading and sort dropdown */
  margin-bottom: 3rem;
}
@media all and (max-width: 992px) {
  div.searchResults div.mainHeadingDiv {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}
div.searchResults div.mainHeadingDiv h2.mainHeading {
  text-align: left;
  color: var(--text-color);
}
@media all and (max-width: 992px) {
  div.searchResults div.mainHeadingDiv h2.mainHeading {
    margin-bottom: 1rem;
  }
}
div.searchResults div.mainHeadingDiv h2.mainHeading.ar {
  text-align: right;
}
div.searchResults div.mainContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}
div.searchResults div.mainContainer div.leftContainer {
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.searchResults div.mainContainer div.leftContainer {
    width: 100%;
  }
}
div.searchResults div.mainContainer div.leftContainer div.listings {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Consistent spacing between items */
}
div.searchResults div.mainContainer div.leftContainer div.listings.center {
  justify-content: center;
}
div.searchResults div.mainContainer div.leftContainer div.listings div.item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.333% - 10px); /* 3 items per row with gap */
  /* Optional: Adjust card height or other styles */
}
@media all and (max-width: 1200px) {
  div.searchResults div.mainContainer div.leftContainer div.listings div.item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px); /* 2 items per row on medium screens */
  }
}
@media all and (max-width: 768px) {
  div.searchResults div.mainContainer div.leftContainer div.listings div.item {
    flex: 0 0 100%; /* 1 item per row on small screens */
  }
}
div.searchResults div.mainContainer div.leftContainer div.listings div.item.ar {
  /* No need for margin adjustments with gap */
}
div.searchResults div.mainContainer div.rightContainer {
  width: 26%;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.searchResults div.mainContainer div.rightContainer {
    display: none;
  }
}
div.searchResults div.mainContainer div.rightContainer div.stickyContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  padding-top: 60px;
}
div.searchResults div.mainContainer div.rightContainer div.stickyContainer h1.heading {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 12px;
}
html[lang=ar] div.searchResults div.mainContainer div.rightContainer div.stickyContainer h1.heading {
  font-size: calc(14px + 1px);
}

div.agentContactCard {
  padding: 20px 10px 20px 10px;
  border: 1px solid var(--border-color);
  width: 100%;
  background-color: var(--primary-bg-color);
}
div.agentContactCard div.top {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
div.agentContactCard div.top div.left {
  width: 70%;
  margin: 0 auto;
}
div.agentContactCard div.top div.left div.imageContainer {
  position: relative;
  width: 100%;
}
div.agentContactCard div.top div.left div.imageContainer img {
  object-fit: cover;
  position: static !important;
  display: block;
  width: 100%;
}
div.agentContactCard div.bottom .contactTitle {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 15px;
  text-transform: uppercase;
}
html[lang=ar] div.agentContactCard div.bottom .contactTitle {
  font-size: calc(14px + 1px);
}
div.agentContactCard div.bottom div.buttons {
  width: 100%;
}
div.agentContactCard div.bottom div.buttonContainer {
  width: 100%;
  margin-right: 8px;
  margin-bottom: 10px;
}
div.agentContactCard div.bottom div.buttonContainer:last-child {
  margin-bottom: 0;
}
div.agentContactCard div.bottom div.buttonContainer button {
  padding: 13px 15px;
  border: 1px solid var(--border-color);
  background-color: transparent;
  color: var(--text-color);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 1400px) {
  div.agentContactCard div.bottom div.buttonContainer button {
    padding: 13px 8px;
  }
}
div.agentContactCard div.bottom div.buttonContainer button span.icon {
  display: block;
  margin-right: 5px;
  line-height: 0;
}
div.agentContactCard div.bottom div.buttonContainer button span.icon svg {
  width: 12px !important;
  height: 12px !important;
  stroke: var(--text-color);
}
div.agentContactCard div.bottom div.buttonContainer button span.text {
  font-size: 10px;
  font-weight: 300;
  line-height: 0;
  letter-spacing: 1.6px;
}
html[lang=ar] div.agentContactCard div.bottom div.buttonContainer button span.text {
  font-size: calc(10px + 1px);
}

#categoryCarousel {
  padding-bottom: 60px;
}
#categoryCarousel div.gallery {
  margin: 30px auto 0;
  width: 100%;
}
#categoryCarousel div.gallery div.propertyItem {
  width: 30%;
  filter: blur(3px);
}
#categoryCarousel div.gallery div.propertyItem.active {
  filter: none;
}
#categoryCarousel div.slick-slider div.slick-slide {
  padding-right: 30px;
  transform: scale(0.7);
}
@media all and (max-width: 1400px) {
  #categoryCarousel div.slick-slider div.slick-slide {
    padding-right: 0;
  }
}
@media all and (max-width: 1050px) {
  #categoryCarousel div.slick-slider div.slick-slide {
    transform: scale(1);
    filter: none;
    padding-right: 30px;
  }
}
@media all and (max-width: 360px) {
  #categoryCarousel div.slick-slider div.slick-slide {
    padding-right: 10px;
  }
}
@media all and (max-width: 980px) {
  #categoryCarousel div.slick-slider div.slick-list {
    overflow: visible !important;
  }
}
#categoryCarousel div.slick-slider div.arrow-left {
  position: absolute;
  bottom: -20px;
  left: 250px !important;
  font-size: 35px;
  cursor: pointer;
  z-index: 9;
  top: unset !important;
}
html[lang=ar] #categoryCarousel div.slick-slider div.arrow-left {
  font-size: calc(35px + 1px);
}
@media all and (max-width: 1050px) {
  #categoryCarousel div.slick-slider div.arrow-left {
    bottom: -50px;
  }
}
@media all and (max-width: 980px) {
  #categoryCarousel div.slick-slider div.arrow-left {
    display: none;
  }
}
#categoryCarousel div.slick-slider div.arrow-right {
  position: absolute;
  bottom: -20px !important;
  right: 250px !important;
  font-size: 35px;
  cursor: pointer;
  top: unset !important;
}
html[lang=ar] #categoryCarousel div.slick-slider div.arrow-right {
  font-size: calc(35px + 1px);
}
@media all and (max-width: 1050px) {
  #categoryCarousel div.slick-slider div.arrow-right {
    bottom: -50px !important;
  }
}
@media all and (max-width: 980px) {
  #categoryCarousel div.slick-slider div.arrow-right {
    display: none;
  }
}
@media all and (max-width: 1200px) {
  #categoryCarousel div.slick-slider div.propertyItem {
    width: 19rem !important;
  }
}
@media all and (max-width: 1050px) {
  #categoryCarousel div.slick-slider div.propertyItem {
    width: 100% !important;
  }
}
#categoryCarousel div.slick-slider div.slick-center.slick-slide {
  filter: none !important;
  transform: scale(1) !important;
  transition: 0.4s;
}
#categoryCarousel div.slick-slider div.slick-center div.propertyItem {
  z-index: 999;
}

#offplanInfoPage div.spotlight {
  width: 100%;
}
#offplanInfoPage div.spotlight div.imageContainer {
  position: relative;
  width: 100%;
  height: 900px;
  z-index: 2;
}
@media all and (max-width: 1400px) {
  #offplanInfoPage div.spotlight div.imageContainer {
    height: 733px;
  }
}
@media all and (max-width: 1300px) {
  #offplanInfoPage div.spotlight div.imageContainer {
    height: 608px;
  }
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.spotlight div.imageContainer {
    height: 600px;
  }
}
#offplanInfoPage div.spotlight div.imageContainer img {
  object-fit: cover;
}
#offplanInfoPage div.spotlight div.imageContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, var(--secondary-bg-color) 30%, rgba(18, 24, 35, 0) 100%);
  height: 400px;
  z-index: 1;
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.spotlight div.imageContainer div.gradient {
    height: 600px;
  }
}
@media all and (max-width: 640px) {
  #offplanInfoPage div.spotlight div.imageContainer div.gradient {
    bottom: 200px;
  }
}
#offplanInfoPage div.spotlight div.imageContainer div.info {
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.spotlight div.imageContainer div.info {
    bottom: -176px;
  }
}
@media all and (max-width: 640px) {
  #offplanInfoPage div.spotlight div.imageContainer div.info {
    bottom: -376px;
  }
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper {
  z-index: 9;
  position: relative;
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper h1.mainHeading {
  color: var(--text-color);
  margin-bottom: 30px;
  line-height: 2rem;
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper div.gradientLine {
  height: 1px;
  position: relative;
  width: 60%;
  margin: 0 auto 30px;
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 1050px) {
  #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list {
    justify-content: space-between;
  }
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item {
  width: 25%;
  display: flex;
  justify-content: center;
}
@media all and (max-width: 1050px) {
  #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item {
    width: 49%;
    margin-bottom: 30px;
    justify-content: left;
  }
  #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item.ar {
    justify-content: right;
  }
  #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item:nth-child(2), #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item:nth-child(4) {
    justify-content: flex-start;
  }
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    width: 46%;
  }
}
@media all and (max-width: 640px) {
  #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item {
    width: 100%;
  }
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.left {
  margin-right: 15px;
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.left.ar {
  margin-right: unset;
  margin-left: 15px;
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.left span.icon {
  display: block;
  margin-top: 5px;
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.left span.icon svg {
  width: 20px !important;
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.right span.value {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 500;
  display: block;
}
html[lang=ar] #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.right span.value {
  font-size: calc(18px + 1px);
}
#offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.right span.label {
  font-size: 14px;
  color: var(--light-text-color);
}
html[lang=ar] #offplanInfoPage div.spotlight div.imageContainer div.info div.wrapper ul.list li.item div.right span.label {
  font-size: calc(14px + 1px);
}

div.popupFormContainer div.formButton {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}
@media all and (max-width: 640px) {
  div.popupFormContainer div.formButton {
    right: 15px;
    bottom: 15px;
  }
}
div.popupFormContainer div.formButton button {
  background-color: var(--dark-blue);
  border: 1px solid #ac836d;
  border-radius: 5px;
  cursor: pointer;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.popupFormContainer div.formButton button svg {
  width: 23px !important;
  height: 23px !important;
}

#offplanInfoPage div.bodyContainer {
  padding-top: 100px;
  position: relative;
  background-color: var(--secondary-bg-color);
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.bodyContainer {
    padding-top: 200px;
  }
}
@media all and (max-width: 640px) {
  #offplanInfoPage div.bodyContainer {
    padding-top: 400px;
  }
}
#offplanInfoPage div.bodyContainer div.wrapper {
  width: 80%;
  margin: 0 auto;
}
@media all and (min-width: 1200px) {
  #offplanInfoPage div.bodyContainer div.wrapper {
    margin: 0 auto;
    width: 85%;
  }
}
@media all and (min-width: 1980px) {
  #offplanInfoPage div.bodyContainer div.wrapper {
    margin: 0 auto;
  }
}
#offplanInfoPage div.bodyContainer div.offplanContainerLeft {
  width: 100%;
}
#offplanInfoPage div.bodyContainer div.offplanContainerLeft div.bgContainer {
  position: relative;
}
#offplanInfoPage div.bodyContainer div.offplanContainerLeft div.bgContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, rgb(18, 24, 35) 30%, rgba(18, 24, 35, 0) 100%);
  height: 400px;
  z-index: 1;
  width: 100%;
}
#offplanInfoPage .faqSectionContainer {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

._contactAgentForm {
  margin-bottom: 80px;
  margin-top: 80px;
}

#offplanInfoPage div.descriptionContainer {
  padding-bottom: 70px;
}
#offplanInfoPage div.descriptionContainer div.wrapper {
  text-align: center;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.descriptionContainer div.wrapper {
    text-align: left;
  }
  #offplanInfoPage div.descriptionContainer div.wrapper.ar {
    text-align: right;
  }
}
#offplanInfoPage div.descriptionContainer div.wrapper .brochureImage {
  width: 60%;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.descriptionContainer div.wrapper .brochureImage {
    display: flex;
    margin: 0 auto;
    width: 60%;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.descriptionContainer div.wrapper .brochureImage {
    display: unset;
    margin: unset;
    width: 100%;
  }
}
#offplanInfoPage div.descriptionContainer div.wrapper h2.mainHeading {
  color: var(--text-color);
  line-height: 2rem;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
html[lang=ar] #offplanInfoPage div.descriptionContainer div.wrapper h2.mainHeading {
  font-size: calc(1.25rem + 1px);
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.descriptionContainer div.wrapper h2.mainHeading {
    text-align: left;
  }
  #offplanInfoPage div.descriptionContainer div.wrapper h2.mainHeading.ar {
    text-align: right;
  }
}
#offplanInfoPage div.descriptionContainer div.wrapper p {
  text-transform: none;
  margin: revert;
  font-size: revert;
  font-size: 15px;
  line-height: 1.6rem;
}
html[lang=ar] #offplanInfoPage div.descriptionContainer div.wrapper p {
  font-size: calc(15px + 1px);
}
#offplanInfoPage div.descriptionContainer div.wrapper p strong span {
  font-weight: 900;
}
#offplanInfoPage div.descriptionContainer div.wrapper ul {
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.descriptionContainer div.wrapper ul {
    align-items: start;
    margin-left: 1rem;
  }
}
#offplanInfoPage div.descriptionContainer div.wrapper li {
  line-height: 2em;
  font-weight: 300;
  list-style-type: disc;
  color: var(--light-text-color);
  font-size: 15px;
}
html[lang=ar] #offplanInfoPage div.descriptionContainer div.wrapper li {
  font-size: calc(15px + 1px);
}
#offplanInfoPage div.descriptionContainer div.wrapper span {
  font-size: 1em;
  color: var(--light-text-color);
  font-weight: 300;
}
html[lang=ar] #offplanInfoPage div.descriptionContainer div.wrapper span {
  font-size: calc(1em + 1px);
}
#offplanInfoPage div.descriptionContainer div.wrapper div.buttonContainer {
  margin-top: 20px;
}
#offplanInfoPage div.descriptionContainer div.wrapper div.buttonContainer button {
  font-size: 14px;
  text-transform: capitalize;
  width: 12rem;
  padding: 17px 15px;
}
html[lang=ar] #offplanInfoPage div.descriptionContainer div.wrapper div.buttonContainer button {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.descriptionContainer .fullWidth {
  width: -moz-fit-content;
  width: fit-content;
}
@media all and (max-width: 576px) {
  #offplanInfoPage div.descriptionContainer .fullWidth {
    width: 100%;
  }
}

#offplanInfoPage div.gallerySection.zIndex {
  position: relative;
  z-index: 9999;
}
#offplanInfoPage div.gallerySection div.galleryCarousel {
  position: relative;
}
#offplanInfoPage div.gallerySection div.galleryCarousel div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
}
#offplanInfoPage div.gallerySection div.galleryCarousel div.closeIcon {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
  animation: fadeIn 1s ease-in-out;
}
#offplanInfoPage div.gallerySection div.galleryCarousel div.closeIcon svg {
  width: 20px !important;
  height: 20px !important;
}
#offplanInfoPage div.gallerySection div.wrapper {
  display: flex;
  justify-content: center;
}
#offplanInfoPage div.gallerySection div.wrapper ul.images {
  display: flex;
  flex-wrap: wrap;
  width: 77%;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images {
    width: 100%;
  }
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item {
  margin-right: 18px;
  width: 32%;
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item:first-child {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item:first-child div.imageContainer img {
  object-fit: cover;
  max-height: none;
  min-height: none;
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
  max-height: 250px;
  min-height: 250px;
}
@media all and (min-width: 1980px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    max-height: 290px;
    min-height: 290px;
  }
}
@media all and (max-width: 1400px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: 200px;
    max-height: 200px;
  }
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: 150px;
    max-height: 150px;
  }
}
@media all and (max-width: 768px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: 100px;
    max-height: 100px;
  }
}
@media all and (max-width: 480px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: unset;
    max-height: unset;
  }
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) {
  margin-right: 0;
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
  position: absolute;
  content: "View more images";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1.6px;
  cursor: pointer;
}
html[lang=ar] #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
  font-size: calc(18px + 1px);
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
    font-size: 13px;
  }
  html[lang=ar] #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
    font-size: calc(13px + 1px);
  }
}
@media all and (max-width: 1400px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item {
    width: 31%;
  }
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item {
    width: 31.5%;
  }
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item {
    width: 30.5%;
  }
}
@media all and (max-width: 640px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item {
    width: 47%;
  }
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(4) {
    display: none;
  }
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) {
    margin-right: 0;
  }
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    position: absolute;
    content: "View more images";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1.6px;
    cursor: pointer;
  }
  html[lang=ar] #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    font-size: calc(18px + 1px);
  }
}
@media all and (max-width: 640px) and (max-width: 980px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    font-size: 13px;
  }
  html[lang=ar] #offplanInfoPage div.gallerySection div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    font-size: calc(13px + 1px);
  }
}
@media all and (max-width: 480px) {
  #offplanInfoPage div.gallerySection div.wrapper ul.images li.item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item div.imageContainer {
  width: 100%;
  position: relative;
}
#offplanInfoPage div.gallerySection div.wrapper ul.images li.item div.imageContainer img {
  position: relative !important;
  width: 100%;
  display: block;
  object-fit: cover;
}
#offplanInfoPage div.gallerySection div.wrapper div.offplanContainerRight {
  width: 21%;
  padding-top: 50px;
}
#offplanInfoPage div.gallerySection div.wrapper div.offplanContainerRight div.stickyContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.gallerySection div.wrapper div.offplanContainerRight {
    display: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.__imageContainer {
  position: relative;
  width: 100%;
  height: 80vh; /* Adjust height as per your design needs */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow for images larger than the container */
}

.__imageContainer img, .__imageContainer span {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important; /* Make sure image fits inside container */
}

div.arrow-left {
  position: absolute;
  left: -80px;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
}
html[lang=ar] div.arrow-left {
  font-size: calc(30px + 1px);
}
@media all and (max-width: 980px) {
  div.arrow-left {
    bottom: -40px;
    left: 40%;
    top: auto;
    z-index: 1;
  }
}

div.arrow-right {
  position: absolute;
  right: -80px;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
}
html[lang=ar] div.arrow-right {
  font-size: calc(30px + 1px);
}
@media all and (max-width: 980px) {
  div.arrow-right {
    bottom: -40px;
    right: 40%;
    top: auto;
  }
}

#offplanInfoPage div.propertyInfo {
  border-width: 5px;
  border-left: none;
  border-right: none;
  position: relative;
  z-index: 99;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer {
  width: 100%;
  height: 850px;
  position: relative;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer.noHeight {
  height: 350px;
}
@media all and (max-width: 1200px) and (orientation: portrait) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer {
    height: 819px;
  }
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer {
    height: 1300px;
  }
  #offplanInfoPage div.propertyInfo div.bgImageContainer.noHeight {
    height: 480px;
  }
}
@media all and (max-width: 640px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer {
    height: 1150px;
  }
  #offplanInfoPage div.propertyInfo div.bgImageContainer.noHeight {
    height: 480px;
  }
}
@media all and (max-width: 480px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer {
    height: 1250px;
  }
  #offplanInfoPage div.propertyInfo div.bgImageContainer.noHeight {
    height: 700px;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer img {
  object-fit: cover;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.gradient {
  position: absolute;
  background: linear-gradient(360deg, rgb(20, 27, 39) 15%, rgba(20, 27, 39, 0.8758096989) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer {
  position: absolute;
  width: 100%;
  z-index: 9;
  padding-top: 80px;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper h2.mainHeading {
  text-align: left;
  font-size: 1.25rem;
  color: var(--text-color);
  margin-bottom: 20px;
}
html[lang=ar] #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper h2.mainHeading {
  font-size: calc(1.25rem + 1px);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper h2.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper h2.mainHeading {
    line-height: 2rem;
    letter-spacing: 1.6px;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo {
    margin-bottom: 0px;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  width: 45%;
  margin-right: 40px;
  padding: 15px 0;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list.ar {
  margin-right: unset;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list:nth-child(2n) {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list {
    width: 100%;
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.left {
  width: 30%;
}
@media all and (max-width: 1400px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.left {
    width: 39%;
  }
}
@media all and (max-width: 480px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.left {
    width: 100%;
    margin-bottom: 10px;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.left span.label {
  color: var(--light-text-color);
  font-size: 14px;
}
html[lang=ar] #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.left span.label {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.right {
  width: 50%;
}
@media all and (max-width: 480px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.right {
    width: 100%;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.right span.value {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
}
html[lang=ar] #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper ul.pInfo li.list div.right span.value {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.gradientLine {
  position: relative;
  height: 1px;
  width: 50%;
  margin: 0 auto;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection {
    flex-direction: column;
    margin-top: 50px;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer {
  width: 48%;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer {
    width: 54%;
  }
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer {
    width: 100%;
    margin-bottom: 60px;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer h1.mainHeading {
  margin-bottom: 30px;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.tabs {
  display: flex;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.tabs li.tab {
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.tabs li.tab.active {
  border-bottom: 1px solid var(--text-color);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.tabs li.tab span {
  color: var(--text-color);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo {
    margin-top: 0;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  padding: 15px 0;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list:first-child {
  border-top: none;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list div.left {
  width: 30%;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list div.left span.label {
  color: var(--light-text-color);
  font-size: 14px;
}
html[lang=ar] #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list div.left span.label {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list div.right {
  width: 50%;
  background-color: transparent;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list div.right span.value {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
}
html[lang=ar] #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer ul.foorInfo li.list div.right span.value {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.leftContainer div.buttonContainer button.globalBtn {
  text-transform: capitalize;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.rightContainer {
  width: 38%;
  padding: 15px;
  background-color: var(--primary-bg-color);
}
@media all and (max-width: 980px) {
  #offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.rightContainer {
    width: 100%;
  }
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.rightContainer div.imageContainer {
  position: relative;
  width: 100%;
}
#offplanInfoPage div.propertyInfo div.bgImageContainer div.contentContainer div.wrapper div.floorPlanSection div.rightContainer div.imageContainer img {
  position: unset !important;
  object-fit: contain;
  display: block;
  width: 100%;
  height: unset !important;
}
#offplanInfoPage .textUnderline {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}

#offplanInfoPage div.currencyAndPlan div.wrapper h2.mainHeading {
  text-align: left;
  color: var(--text-color);
  font-size: 1.375rem;
  margin-bottom: 50px;
}
html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper h2.mainHeading {
  font-size: calc(1.375rem + 1px);
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper h2.mainHeading {
    font-size: 1.125rem;
  }
  html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper h2.mainHeading {
    font-size: calc(1.125rem + 1px);
  }
}
#offplanInfoPage div.currencyAndPlan div.wrapper ul.currencies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}
#offplanInfoPage div.currencyAndPlan div.wrapper ul.currencies li.item h1 {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 5px;
}
html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper ul.currencies li.item h1 {
  font-size: calc(18px + 1px);
}
#offplanInfoPage div.currencyAndPlan div.wrapper ul.currencies li.item span.label {
  color: var(--light-text-color);
  font-size: 14px;
  font-weight: 300;
}
html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper ul.currencies li.item span.label {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.crypto {
  display: flex;
  align-items: center;
  padding: 25px 20px;
  background-color: var(--dark-blue);
  margin-bottom: 60px;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.crypto div.icons {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.crypto div.icons span.icon {
  display: block;
  margin-right: 15px;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.crypto div.icons span.icon:last-child {
  margin-right: 0;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.crypto div.icons span.icon svg {
  width: 40px;
  height: 40px;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.crypto span.text {
  color: var(--light-text-color);
  font-weight: 300;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan h2.mainHeading {
  margin-bottom: 15px;
  font-size: 1.25rem;
}
html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan h2.mainHeading {
  font-size: calc(1.25rem + 1px);
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan h2.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan h2.mainHeading {
    margin-bottom: 2rem;
  }
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment {
  margin-bottom: 40px;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans {
  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 30px;
  margin-bottom: 20px;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans.isDesktop {
    display: none;
  }
}
@media all and (min-width: 993px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans.isMobile {
    display: none;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans {
    padding: unset;
    flex-direction: column;
  }
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans:last-child {
  margin-bottom: 0;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item {
  margin-right: 10px;
  margin-bottom: 25px;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item.ar {
  text-align: right;
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item {
    text-align: left;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item {
    width: 100%;
    margin-bottom: unset;
    border-bottom: 1px solid var(--border-color);
    padding: 1.5rem 0rem;
    margin-right: unset;
  }
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item:first-child {
    padding: 0rem;
    padding-bottom: 1.5rem;
  }
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item span.value {
  display: block;
  font-weight: 300;
  letter-spacing: 4px;
  font-size: 3.125rem;
  color: var(--text-color);
}
html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item span.value {
  font-size: calc(3.125rem + 1px);
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item span.value {
    font-size: 2.5rem;
  }
  html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item span.value {
    font-size: calc(2.5rem + 1px);
  }
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item span.label {
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: var(--light-text-color);
  font-weight: 300;
  color: #FFF;
  letter-spacing: 1.92px;
}
html[lang=ar] #offplanInfoPage div.currencyAndPlan div.wrapper div.paymentPlan div.payment ul.listPlans li.item span.label {
  font-size: calc(1.2rem + 1px);
}
#offplanInfoPage div.currencyAndPlan div.wrapper div.gradientLine {
  width: 90%;
  height: 1px;
  margin: 3rem 0 3rem 0;
  position: relative;
}

#offplanInfoPage div.locationInfo {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-width: 5px;
  padding-top: 80px;
  margin-bottom: 80px;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo {
    margin-bottom: unset;
  }
}
#offplanInfoPage div.locationInfo div.wrapper h2.mainHeading {
  text-align: left;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  font-weight: 700;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
#offplanInfoPage div.locationInfo div.wrapper h2.mainHeading.ar {
  text-align: right;
}
html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper h2.mainHeading {
  font-size: calc(1.25rem + 1px);
}
#offplanInfoPage div.locationInfo div.wrapper div.mapContainer {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
#offplanInfoPage div.locationInfo div.wrapper div.mapContainer img {
  object-fit: cover;
}
#offplanInfoPage div.locationInfo div.wrapper div.content {
  font-size: 14px;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 60px;
}
html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper div.content {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.locationInfo div.wrapper .location_description {
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 31.768px;
  letter-spacing: 0.4px;
  margin-bottom: 5rem;
  text-transform: unset;
}
html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper .location_description {
  font-size: calc(1.25rem + 1px);
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.locationInfo div.wrapper .location_description {
    margin-bottom: 3rem;
  }
}
#offplanInfoPage div.locationInfo div.wrapper .facilities_near_location {
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: unset;
  margin-bottom: 1rem;
}
html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper .facilities_near_location {
  font-size: calc(1.25rem + 1px);
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container {
    flex-direction: column;
  }
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 15rem;
  max-height: 20rem;
  padding-left: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  column-gap: 5rem;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container ul {
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container li {
  list-style-image: url("/pin.svg");
  color: white;
  padding: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
}
html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper .destinations_container li {
  font-size: calc(1rem + 1px);
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  width: 35%;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations {
    width: 50%;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations {
    width: 100%;
    order: 0;
  }
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent {
  display: flex;
  align-items: baseline;
  width: 100%;
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item {
  text-align: center;
  width: 50%;
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item img {
  margin-bottom: 1rem;
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .value {
  margin-bottom: 0.5rem;
  font-weight: 700;
  letter-spacing: 0.4px;
  font-size: 1.25rem;
}
html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .value {
  font-size: calc(1.25rem + 1px);
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .value {
    font-size: 0.94rem;
  }
  html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .value {
    font-size: calc(0.94rem + 1px);
  }
}
#offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .label {
  font-weight: 300;
  letter-spacing: 0.4px;
  font-size: 1.25rem;
}
html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .label {
  font-size: calc(1.25rem + 1px);
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .label {
    font-size: 0.94rem;
  }
  html[lang=ar] #offplanInfoPage div.locationInfo div.wrapper .destinations_container .destinations .parent .destinations_item .label {
    font-size: calc(0.94rem + 1px);
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container_parent.removeMarginTop {
    margin-top: 1rem;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container_parent {
    margin-top: 3rem;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo div.wrapper .destinations_container_parent {
    order: 1;
  }
}
#offplanInfoPage div.locationInfo div.wrapper .globalBtnContainer {
  margin-top: 40px;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo div.wrapper .globalBtnContainer.marginTop {
    margin-top: unset;
  }
}
#offplanInfoPage div.locationInfo div.wrapper .globalBtnContainer .fullWidth {
  width: -moz-fit-content;
  width: fit-content;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.locationInfo div.wrapper .globalBtnContainer .fullWidth {
    margin-bottom: 5rem;
  }
}
@media all and (max-width: 576px) {
  #offplanInfoPage div.locationInfo div.wrapper .globalBtnContainer .fullWidth {
    width: 100%;
  }
}

#offplanInfoPage div.faqsSection {
  margin-top: 60px;
}
#offplanInfoPage div.faqsSection .ReactCollapse--collapse {
  transition: height 200ms;
}
#offplanInfoPage div.faqsSection div.wrapper h1.mainHeading {
  text-align: left;
  color: var(--text-color);
  font-size: 16px;
  margin-bottom: 40px;
}
html[lang=ar] #offplanInfoPage div.faqsSection div.wrapper h1.mainHeading {
  font-size: calc(16px + 1px);
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer {
  margin-bottom: 80px;
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border-color);
  padding: 20px 10px;
  justify-content: space-between;
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem:last-child {
  border-bottom: 1px solid var(--border-color);
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.leftContainer {
  width: 90%;
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.leftContainer h1.question {
  color: var(--text-color);
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}
html[lang=ar] #offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.leftContainer h1.question {
  font-size: calc(16px + 1px);
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.leftContainer p.answer {
  font-weight: 300;
  font-size: 14px;
  color: var(--light-text-color);
  text-transform: unset;
}
html[lang=ar] #offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.leftContainer p.answer {
  font-size: calc(14px + 1px);
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.rightContainer {
  width: 8%;
}
#offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.rightContainer h1 {
  font-size: 25px;
  color: var(--text-color);
  text-align: right;
  cursor: pointer;
}
html[lang=ar] #offplanInfoPage div.faqsSection div.wrapper div.accordionContainer div.accordionItem div.rightContainer h1 {
  font-size: calc(25px + 1px);
}
#offplanInfoPage div.faqsSection div.wrapper div#gradientLine {
  margin-bottom: 80px;
}

div.propertyContactForm {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-blue);
}
@media all and (max-width: 1200px) {
  div.propertyContactForm {
    flex-direction: column;
    padding: 30px;
  }
}
div.propertyContactForm div.leftContainer {
  width: 26%;
}
@media all and (max-width: 1200px) {
  div.propertyContactForm div.leftContainer {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 980px) {
  div.propertyContactForm div.leftContainer {
    display: none;
  }
}
div.propertyContactForm div.leftContainer div.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
div.propertyContactForm div.leftContainer div.imageContainer img {
  object-fit: cover;
  position: unset !important;
  display: block;
  width: 100%;
  height: unset !important;
}
div.propertyContactForm div.rightContainer {
  width: 100%;
  padding: 30px 60px 30px 20px;
}
@media all and (max-width: 1200px) {
  div.propertyContactForm div.rightContainer {
    width: 100%;
    padding: 0;
  }
}
div.propertyContactForm div.rightContainer h2.mainHeading {
  color: var(--text-color);
  font-size: 18px;
  text-align: center !important;
}
html[lang=ar] div.propertyContactForm div.rightContainer h2.mainHeading {
  font-size: calc(18px + 1px);
}
div.propertyContactForm div.rightContainer div.form form div.inputFields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer {
  width: 48%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  border-bottom: 1px solid var(--link-color);
  padding-bottom: 8px;
  position: relative;
  position: relative;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer.noPadding {
  padding-bottom: 0;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer select {
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  font-size: 16px;
}
html[lang=ar] div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer select {
  font-size: calc(16px + 1px);
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer.error {
  border-color: var(--error-color);
}
@media all and (max-width: 640px) {
  div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer {
    width: 100%;
  }
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer:nth-child(2n) {
  margin-right: 0;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer:last-child {
  margin-right: 0;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer span.label {
  color: var(--placeholder-color);
  font-size: 14px;
  text-transform: uppercase;
}
html[lang=ar] div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer span.label {
  font-size: calc(14px + 1px);
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer span.errorIcon {
  position: absolute;
  right: 0;
  transform: translateX(0);
  animation: zoomIn 0.6s ease-in-out;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer span.errorIcon svg {
  width: 20px !important;
  height: 20px !important;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer span.icon {
  display: inline-block;
  margin-left: 10px;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer span.icon svg {
  width: 10px !important;
  height: 10px !important;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer span.icon svg path {
  stroke: var(--placeholder-color);
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  top: 30px;
  border-radius: 6px;
  z-index: 9;
  max-height: 400px;
  overflow-y: scroll;
}
@media all and (max-width: 768px) {
  div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown {
    width: 100%;
    left: 0;
  }
}
@media all and (max-width: 640px) {
  div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown {
    left: revert;
    right: 0;
  }
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems {
  padding-top: 10px;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems li {
  text-align: center;
  padding: 10px 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems li:hover {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
div.propertyContactForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems li:last-child {
  margin-bottom: 0;
}
div.propertyContactForm div.rightContainer div.form form div.inputFields input {
  width: 100%;
  display: block;
  background-color: transparent;
  caret-color: #fff;
  color: var(--text-color);
  font-size: 16px;
}
html[lang=ar] div.propertyContactForm div.rightContainer div.form form div.inputFields input {
  font-size: calc(16px + 1px);
}
div.propertyContactForm div.rightContainer div.form form div.inputFields input::placeholder {
  letter-spacing: 1.6px;
  font-size: 14px;
  text-transform: uppercase;
}
html[lang=ar] div.propertyContactForm div.rightContainer div.form form div.inputFields input::placeholder {
  font-size: calc(14px + 1px);
}
div.propertyContactForm div.rightContainer div.form form div.buttonContainer {
  text-align: center;
}
div.propertyContactForm div.rightContainer div.form form div.buttonContainer button.globalBtn {
  width: 17rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.propertyContactForm div.rightContainer div.form form div.buttonContainer button.globalBtn {
    width: 100%;
  }
}

div#gradientLine {
  position: relative;
  margin: 0 auto;
  height: 1px;
}

div.similiarPropertyComponent h2.mainHeading {
  color: var(--text-color);
  font-size: 1.25rem;
  text-align: left;
  margin-bottom: 30px;
}
html[lang=ar] div.similiarPropertyComponent h2.mainHeading {
  font-size: calc(1.25rem + 1px);
}
div.similiarPropertyComponent div.carouselSection {
  padding-bottom: 80px;
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent.leftAlign div.slick-track {
  margin-left: 0;
  margin-right: 0;
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent.hideCarousel div.listItems {
  display: flex;
}
@media all and (max-width: 1400px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent.visibleCarousel div.listItems {
    display: none;
  }
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems {
  display: none;
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.slick-slide {
  padding: 0 5px;
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div#offplanPropertyCard {
  width: 100%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom h6.text {
    font-size: 9px;
  }
  html[lang=ar] div.similiarPropertyComponent div.carouselSection div#carouselComponent div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom h6.text {
    font-size: calc(9px + 1px);
  }
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo a:nth-child(2) {
    padding: 0 50px;
  }
}
@media all and (max-width: 980px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo a:nth-child(2) {
    padding: 0 30px;
  }
}
@media all and (max-width: 768px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo a:nth-child(2) {
    padding: 0 100px;
    margin: 0 0;
  }
}
@media all and (max-width: 640px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo a:nth-child(2) {
    padding: 0 60px;
    margin: 0 0;
  }
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.arrow-left {
  position: absolute;
  left: 50%;
  bottom: -60px;
  font-size: 30px;
  cursor: pointer;
  top: unset;
}
html[lang=ar] div.similiarPropertyComponent div.carouselSection div#carouselComponent div.arrow-left {
  font-size: calc(30px + 1px);
}
@media all and (max-width: 768px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.arrow-left {
    left: 40%;
  }
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.arrow-right {
  position: absolute;
  right: 40%;
  bottom: -60px;
  font-size: 30px;
  cursor: pointer;
  top: unset;
}
html[lang=ar] div.similiarPropertyComponent div.carouselSection div#carouselComponent div.arrow-right {
  font-size: calc(30px + 1px);
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems {
  display: flex;
}
@media all and (max-width: 1400px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems {
    flex-wrap: wrap;
  }
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item {
  width: 30%;
  margin-right: 30px;
}
@media all and (max-width: 1400px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item {
    width: 40%;
    margin-bottom: 30px;
  }
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 1200px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item {
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item {
    width: 70%;
  }
}
@media all and (max-width: 768px) {
  div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item {
    width: 100%;
  }
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item:last-child {
  margin-right: 0;
}
div.similiarPropertyComponent div.carouselSection div#carouselComponent div.listItems div.item div#offplanPropertyCard {
  width: 100%;
}

div#offplanPropertyCard {
  cursor: pointer;
  width: 100%;
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  background-color: var(--primary-bg-color);
}
div#offplanPropertyCard:hover div.imageContainer img {
  transform: scale(1.2);
}
div#offplanPropertyCard div.imageContainer {
  width: 100%;
  position: relative;
  height: 245px;
  overflow: hidden;
}
div#offplanPropertyCard div.imageContainer img {
  transition: 0.3s ease-in-out;
  object-fit: cover;
}
div#offplanPropertyCard div.imageContainer .developerImageContainer {
  position: absolute;
  bottom: 0;
  margin: 20px 20px 20px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
div#offplanPropertyCard div.imageContainer .developerImageContainer .developerImage {
  max-width: 80px;
}
div#offplanPropertyCard div.detailsContainer {
  padding: 20px 20px 20px;
  background-color: var(--dark-blue);
}
div#offplanPropertyCard div.detailsContainer.primaryBg {
  background-color: var(--primary-bg-color);
}
div#offplanPropertyCard div.detailsContainer h3.title {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color);
  font-size: 16px;
  min-height: 41px;
}
html[lang=ar] div#offplanPropertyCard div.detailsContainer h3.title {
  font-size: calc(16px + 1px);
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top {
  border-bottom: 1px solid var(--light-text-color);
  padding-bottom: 20px;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.location {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  min-height: 36px;
}
@media all and (max-width: 768px) {
  div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.location {
    min-height: 40px;
  }
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.location span.icon {
  line-height: 0;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.location span.icon.ar {
  margin-left: 0.3rem;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.location span.icon svg g {
  stroke: var(--light-text-color) !important;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.location span.text {
  color: var(--light-text-color);
  font-weight: 300;
  margin-left: 6px;
  font-size: 16px;
  text-transform: capitalize;
}
html[lang=ar] div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.location span.text {
  font-size: calc(16px + 1px);
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.bed {
  display: flex;
  align-items: center;
  min-height: 36px;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.bed span.icon {
  line-height: 0;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.bed span.icon.ar {
  margin-left: 0.3rem;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.bed span.icon svg g {
  stroke: var(--light-text-color) !important;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.bed span.text {
  color: var(--light-text-color);
  font-weight: 300;
  margin-left: 6px;
  font-size: 13px;
}
html[lang=ar] div#offplanPropertyCard div.detailsContainer div.extraInfo div.top span.bed span.text {
  font-size: calc(13px + 1px);
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom {
  padding: 12px 0;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.starting {
  font-weight: 300;
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 30px;
  display: block;
}
html[lang=ar] div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.starting {
  font-size: calc(14px + 1px);
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.oneLine {
  display: flex;
  justify-content: space-between;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text {
  display: block;
  color: var(--text-color);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
html[lang=ar] div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text {
  font-size: calc(13px + 1px);
}
@media all and (max-width: 1200px) {
  div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text {
    font-size: 10px;
  }
  html[lang=ar] div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text {
    font-size: calc(10px + 1px);
  }
}
@media all and (max-width: 1050px) {
  div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text {
    display: none;
  }
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text span.icon {
  display: block;
  margin-left: 10px;
  line-height: 0;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text span.icon.ar {
  transform: scaleX(-1);
  margin-right: 0.5rem;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom span.text span.icon svg {
  width: 20px;
  stroke: var(--text-color);
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo {
  display: flex;
  align-items: center;
}
@media all and (max-width: 1050px) {
  div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo {
    justify-content: space-between;
    width: 100%;
  }
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo a {
  cursor: pointer;
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo a:nth-child(2) {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  padding: 0 20px;
  margin: 0 20px;
}
@media all and (max-width: 1050px) {
  div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo a:nth-child(2) {
    padding: 0 60px;
    margin: 0 0;
  }
}
div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo span.icon svg {
  width: 13px !important;
  height: 13px !important;
}
@media all and (max-width: 1050px) {
  div#offplanPropertyCard div.detailsContainer div.extraInfo div.bottom div.contactInfo span.icon svg {
    width: 15px !important;
    height: 15px !important;
  }
}

#contactForm {
  z-index: 9999;
  position: absolute;
}
#contactForm div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
#contactForm div.closeIcon {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
  animation: fadeIn 0.3s ease-in-out;
}
#contactForm div.closeIcon svg {
  width: 20px !important;
  height: 20px !important;
}
#contactForm div.content {
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark-blue);
  width: 50%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  animation: fadeIn 0.3s ease-in-out;
}
@media all and (max-width: 1200px) {
  #contactForm div.content {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  #contactForm div.content {
    width: 95%;
    padding: 60px 30px 30px;
  }
}
#contactForm div.content h1.mainHeading {
  text-align: center !important;
  font-size: 20px !important;
}
html[lang=ar] #contactForm div.content h1.mainHeading {
  font-size: calc(20px + 1px) !important;
}
@media all and (max-width: 1200px) {
  #contactForm div.content h1.mainHeading {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  #contactForm div.content h1.mainHeading {
    margin-bottom: 30px;
  }
}
#contactForm div.content div.formContainer {
  width: 100%;
}
#contactForm div.content div.formContainer div.inputContainer {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
#contactForm div.content div.formContainer div.inputContainer:nth-child(5) {
  border: none;
}
#contactForm div.content div.formContainer div.inputContainer.error {
  border-color: var(--error-color);
}
#contactForm div.content div.formContainer div.inputContainer span.label {
  color: var(--placeholder-color);
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
html[lang=ar] #contactForm div.content div.formContainer div.inputContainer span.label {
  font-size: calc(14px + 1px);
}
#contactForm div.content div.formContainer div.inputContainer input {
  width: 100%;
  background-color: transparent;
  padding-bottom: 10px;
  color: var(--text-color);
  font-size: 14px;
}
html[lang=ar] #contactForm div.content div.formContainer div.inputContainer input {
  font-size: calc(14px + 1px);
}
#contactForm div.content div.formContainer div.inputContainer input::placeholder {
  letter-spacing: 1.6px;
}
#contactForm div.content div.formContainer div.inputContainer input.error {
  border-color: var(--error-color);
}
#contactForm div.content div.formContainer div.inputContainer span.errorIcon {
  position: absolute;
  right: 0;
  transform: translateX(0);
  animation: zoomIn 0.6s ease-in-out;
}
#contactForm div.content div.formContainer div.inputContainer span.errorIcon svg {
  width: 20px !important;
  height: 20px !important;
}
#contactForm div.content div.formContainer div.inputContainer div.textArea {
  width: 100%;
  margin-bottom: 10px;
}
#contactForm div.content div.formContainer div.inputContainer div.textArea label {
  display: block;
  text-align: left;
  font-size: 14px;
  color: var(--placeholder-color);
  margin-bottom: 10px;
  text-transform: uppercase;
}
html[lang=ar] #contactForm div.content div.formContainer div.inputContainer div.textArea label {
  font-size: calc(14px + 1px);
}
#contactForm div.content div.formContainer div.inputContainer div.textArea textarea {
  background-color: transparent;
  width: 100%;
  color: var(--text-color);
  resize: none;
  padding: 6px;
}
#contactForm div.content div.formContainer div.dropDown {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  top: 30px;
  border-radius: 6px;
  z-index: 9;
  max-height: 400px;
  overflow-y: scroll;
}
@media all and (max-width: 768px) {
  #contactForm div.content div.formContainer div.dropDown {
    width: 100%;
    left: 0;
  }
}
@media all and (max-width: 640px) {
  #contactForm div.content div.formContainer div.dropDown {
    left: revert;
    right: 0;
  }
}
#contactForm div.content div.formContainer div.dropDown ul.listDropdownitems {
  padding-top: 10px;
}
#contactForm div.content div.formContainer div.dropDown ul.listDropdownitems li {
  text-align: center;
  padding: 10px 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
#contactForm div.content div.formContainer div.dropDown ul.listDropdownitems li span.text {
  color: #000;
}
#contactForm div.content div.formContainer div.dropDown ul.listDropdownitems li:hover {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
#contactForm div.content div.formContainer div.dropDown ul.listDropdownitems li:hover span {
  color: var(--text-color);
}
#contactForm div.content div.formContainer div.dropDown ul.listDropdownitems li:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 640px) {
  #contactForm div.content div.formContainer div.buttonContainer button.globalBtn {
    width: 100%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#contactForm .PhoneInputCountryIcon {
  padding-bottom: 10px;
  height: unset;
  box-shadow: unset;
}

div.swal2-container {
  z-index: 99999 !important;
}

#blogsListing {
  background-color: var(--secondary-bg-color);
  padding-bottom: 100px;
  padding-top: 100px;
}
#blogsListing div.blogSpotlight {
  width: 100%;
}
#blogsListing div.blogSpotlight div.imageContainer {
  position: relative;
  width: 100%;
  height: 750px;
  z-index: 2;
}
@media all and (max-width: 1400px) {
  #blogsListing div.blogSpotlight div.imageContainer {
    height: 582px;
  }
}
#blogsListing div.blogSpotlight div.imageContainer img {
  object-fit: cover;
}
#blogsListing div.blogSpotlight div.imageContainer div.gradient {
  position: absolute;
  background: linear-gradient(360deg, rgb(20, 27, 39) 15%, rgba(20, 27, 39, 0.5758096989) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
#blogsListing div.blogSpotlight div.imageContainer div.info {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#blogsListing div.blogSpotlight div.imageContainer div.info div.wrapper {
  z-index: 9;
  position: relative;
}
#blogsListing div.blogSpotlight div.imageContainer div.info div.wrapper h1.mainHeading {
  color: var(--text-color);
  margin-bottom: 30px;
}
#blogsListing div.wrapper {
  margin-top: 60px;
}
@media all and (max-width: 1200px) {
  #blogsListing div.wrapper .mainHeading {
    text-align: left;
  }
  #blogsListing div.wrapper .mainHeading.ar {
    text-align: right;
  }
}
@media all and (max-width: 1200px) {
  #blogsListing div.wrapper #gradientLine {
    display: none !important;
  }
}
#blogsListing div.wrapper .description {
  text-align: center;
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  margin: 0 auto 50px;
  line-height: 2em;
  margin-top: 1rem;
  width: 95%;
}
html[lang=ar] #blogsListing div.wrapper .description {
  font-size: calc(14px + 1px);
}
#blogsListing div.wrapper .description.ar {
  font-size: 16px;
}
html[lang=ar] #blogsListing div.wrapper .description.ar {
  font-size: calc(16px + 1px);
}
@media all and (max-width: 1200px) {
  #blogsListing div.wrapper .description {
    text-align: left;
    width: 100%;
  }
  #blogsListing div.wrapper .description.ar {
    text-align: right;
  }
}
@media all and (max-width: 640px) {
  #blogsListing div.wrapper .description {
    font-size: 12px;
  }
  html[lang=ar] #blogsListing div.wrapper .description {
    font-size: calc(12px + 1px);
  }
  #blogsListing div.wrapper .description.ar {
    font-size: 14px;
  }
  html[lang=ar] #blogsListing div.wrapper .description.ar {
    font-size: calc(14px + 1px);
  }
}
#blogsListing div.wrapper div.itemListing {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
  justify-content: center;
}
#blogsListing div.wrapper div.itemListing div.item {
  width: 30%;
  margin-right: 35px;
  margin-bottom: 40px;
}
#blogsListing div.wrapper div.itemListing div.item:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  #blogsListing div.wrapper div.itemListing div.item {
    width: 48%;
    margin-right: 15px;
  }
  #blogsListing div.wrapper div.itemListing div.item:nth-child(2n) {
    margin-right: 0;
  }
  #blogsListing div.wrapper div.itemListing div.item:nth-child(3n) {
    margin-right: 15px;
  }
}
@media all and (max-width: 640px) {
  #blogsListing div.wrapper div.itemListing div.item {
    width: 100%;
    margin-right: 0;
  }
  #blogsListing div.wrapper div.itemListing div.item:nth-child(2n) {
    margin-right: 0;
  }
  #blogsListing div.wrapper div.itemListing div.item:nth-child(3n) {
    margin-right: 0;
  }
}
#blogsListing div.wrapper div#gradientLine {
  margin-bottom: 50px;
}

div#blogCard {
  width: 100%;
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%);
  border-image-slice: 1;
  background-color: var(--primary-bg-color);
}
div#blogCard div.imageContainer {
  width: 100%;
  position: relative;
}
div#blogCard div.imageContainer img {
  position: static !important;
  display: block;
  width: 100%;
}
div#blogCard div.detailsContainer {
  padding: 20px 20px 15px;
}
div#blogCard div.detailsContainer h1.title, div#blogCard div.detailsContainer h2.title {
  text-transform: uppercase;
  font-weight: 300;
  color: var(--text-color);
  font-size: 15px;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 38px;
}
html[lang=ar] div#blogCard div.detailsContainer h1.title, html[lang=ar] div#blogCard div.detailsContainer h2.title {
  font-size: calc(15px + 1px);
}
div#blogCard div.detailsContainer h1.title.ar, div#blogCard div.detailsContainer h2.title.ar {
  text-align: right;
}
div#blogCard div.detailsContainer div.extraInfo div.top {
  border-bottom: 1px solid var(--light-text-color);
  padding-bottom: 20px;
}
div#blogCard div.detailsContainer div.extraInfo div.top span.date {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
div#blogCard div.detailsContainer div.extraInfo div.top span.date span.icon {
  line-height: 0;
}
div#blogCard div.detailsContainer div.extraInfo div.top span.date span.icon svg g {
  stroke: var(--light-text-color) !important;
}
div#blogCard div.detailsContainer div.extraInfo div.top span.date span.text {
  color: var(--light-text-color);
  font-weight: 300;
  margin-left: 6px;
  font-size: 13px;
}
html[lang=ar] div#blogCard div.detailsContainer div.extraInfo div.top span.date span.text {
  font-size: calc(13px + 1px);
}
div#blogCard div.detailsContainer div.extraInfo div.bottom {
  padding: 22px 0 12px;
  display: flex;
  justify-content: flex-end;
}
div#blogCard div.detailsContainer div.extraInfo div.bottom div.oneLine h6.text {
  color: var(--text-color);
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
html[lang=ar] div#blogCard div.detailsContainer div.extraInfo div.bottom div.oneLine h6.text {
  font-size: calc(13px + 1px);
}
div#blogCard div.detailsContainer div.extraInfo div.bottom div.oneLine h6.text span.icon {
  display: block;
  margin-left: 10px;
  line-height: 0;
}
div#blogCard div.detailsContainer div.extraInfo div.bottom div.oneLine h6.text span.icon svg {
  width: 20px;
  stroke: var(--text-color);
}

div.subscribeContainer h3 {
  text-align: center;
  text-transform: uppercase;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 2em;
  max-width: 50%;
  margin: 0 auto 20px;
}
html[lang=ar] div.subscribeContainer h3 {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 980px) {
  div.subscribeContainer h3 {
    max-width: 100%;
  }
}
div.subscribeContainer div.subscribeForm {
  width: 100%;
  padding: 90px 40px 80px;
  align-items: flex-end;
  margin: 0 auto;
}
div.subscribeContainer div.subscribeForm div.oneRow {
  display: flex;
}
@media all and (max-width: 980px) {
  div.subscribeContainer div.subscribeForm {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 980px) {
  div.subscribeContainer div.subscribeForm {
    padding: 40px;
  }
}
@media all and (max-width: 480px) {
  div.subscribeContainer div.subscribeForm {
    padding: 20px;
  }
}
div.subscribeContainer div.subscribeForm div.oneRow {
  display: flex;
  margin-bottom: 30px;
}
@media all and (max-width: 1050px) {
  div.subscribeContainer div.subscribeForm div.oneRow {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 980px) {
  div.subscribeContainer div.subscribeForm div.oneRow {
    margin-bottom: 0;
  }
  div.subscribeContainer div.subscribeForm div.oneRow.bottomRow {
    flex-direction: column;
  }
}
div.subscribeContainer div.subscribeForm div.oneRow:last-child {
  margin-bottom: 0;
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer {
  margin-right: 30px;
  width: 23%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.ar {
  margin-right: unset;
  margin-left: 30px;
}
@media all and (max-width: 980px) {
  div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.top {
    width: 46%;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 640px) {
  div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.top {
    width: 45%;
  }
}
@media all and (max-width: 480px) {
  div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.top {
    width: 100%;
  }
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.top:last-child {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.bottom {
    margin-bottom: 30px;
    margin-right: 22px;
    width: 30%;
  }
  div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.bottom.ar {
    margin-right: unset;
  }
}
@media all and (max-width: 980px) {
  div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer.bottom {
    width: 100%;
  }
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer span.specialization {
  color: var(--placeholder-color);
  font-size: 14px;
  text-transform: uppercase;
}
html[lang=ar] div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer span.specialization {
  font-size: calc(14px + 1px);
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer span.errorIcon {
  position: absolute;
  right: 0;
  transform: translateX(0);
  animation: zoomIn 0.6s ease-in-out;
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer span.errorIcon svg {
  width: 20px !important;
  height: 20px !important;
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer span.icon {
  display: inline-block;
  margin-left: 10px;
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer span.icon svg {
  width: 10px !important;
  height: 10px !important;
}
div.subscribeContainer div.subscribeForm div.oneRow div.inputContainer span.icon svg path {
  stroke: var(--placeholder-color);
}
div.subscribeContainer div.subscribeForm input {
  width: 100%;
  display: block;
  background-color: transparent;
  border-bottom: 1px solid var(--link-color);
  padding-bottom: 8px;
  caret-color: #fff;
  color: var(--text-color);
  font-size: 16px;
}
html[lang=ar] div.subscribeContainer div.subscribeForm input {
  font-size: calc(16px + 1px);
}
div.subscribeContainer div.subscribeForm input.error {
  border-color: var(--error-color);
}
div.subscribeContainer div.subscribeForm input::placeholder {
  letter-spacing: 1.6px;
  font-size: 14px;
  font-family: var(--lato-font-family);
}
html[lang=ar] div.subscribeContainer div.subscribeForm input::placeholder {
  font-size: calc(14px + 1px);
}
div.subscribeContainer div.subscribeForm input.ar::placeholder {
  letter-spacing: normal;
}
@media all and (max-width: 768px) {
  div.subscribeContainer div.subscribeForm button {
    width: 100%;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(2);
  }
  50% {
    transform: scale(1);
  }
  60% {
    transform: ranslateX(20px);
  }
  80% {
    transform: translateX(-10px);
  }
}
div.subscribeContainer .PhoneInputCountry {
  margin-right: unset !important;
  border-bottom: 1px solid var(--link-color);
  padding-bottom: 10px;
}
div.subscribeContainer .PhoneInputInput {
  border-radius: 0px;
  padding-left: 0.5rem;
}
div.subscribeContainer .custom-phone-input.error .PhoneInputInput {
  border-bottom: 1px solid var(--error-color) !important;
}
div.subscribeContainer .custom-phone-input.error .PhoneInputCountry {
  border-bottom: 1px solid var(--error-color) !important;
}

#blogInfo {
  padding-bottom: 100px;
}
#blogInfo h1, #blogInfo h2, #blogInfo h3, #blogInfo h4, #blogInfo h5, #blogInfo h6 {
  color: var(--text-color) !important;
}
#blogInfo a {
  color: #a0d5fe !important;
}
#blogInfo img {
  display: block;
  width: 100%;
}
#blogInfo p {
  text-transform: unset;
  margin: revert;
  font-size: 17px;
  line-height: 2em;
}
html[lang=ar] #blogInfo p {
  font-size: calc(17px + 1px);
}
#blogInfo p strong {
  color: var(--text-color) !important;
  font-size: 18px !important;
}
html[lang=ar] #blogInfo p strong {
  font-size: calc(18px + 1px) !important;
}
#blogInfo li {
  line-height: 2em;
  color: var(--light-text-color) !important;
  font-size: 17px;
  font-weight: 300;
}
html[lang=ar] #blogInfo li {
  font-size: calc(17px + 1px);
}
#blogInfo li strong {
  color: var(--text-color) !important;
}
#blogInfo ol li {
  color: var(--light-text-color) !important;
  font-size: 17px;
  line-height: 2em;
}
html[lang=ar] #blogInfo ol li {
  font-size: calc(17px + 1px);
}
#blogInfo ol li strong {
  color: var(--text-color) !important;
  font-size: 17px !important;
}
html[lang=ar] #blogInfo ol li strong {
  font-size: calc(17px + 1px) !important;
}
#blogInfo div.nextSection {
  margin-top: 70px;
}
#blogInfo th, #blogInfo td {
  border: 1px solid white;
  padding: 1rem;
  color: white;
}

#chatbot {
  display: none;
}

#blogInfo div.blogInfoSpotlight {
  width: 100%;
  padding-top: 60px;
}
#blogInfo div.blogInfoSpotlight div.imageContainer {
  position: relative;
  width: 100%;
  height: 750px;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media all and (min-width: 1600px) {
  #blogInfo div.blogInfoSpotlight div.imageContainer {
    height: 900px;
  }
}
@media all and (min-width: 1980px) {
  #blogInfo div.blogInfoSpotlight div.imageContainer {
    height: 1000px;
  }
}
@media all and (min-width: 2600px) {
  #blogInfo div.blogInfoSpotlight div.imageContainer {
    height: 1200px;
  }
}
@media all and (max-width: 980px) {
  #blogInfo div.blogInfoSpotlight div.imageContainer {
    height: 600px;
  }
}
@media all and (max-width: 640px) {
  #blogInfo div.blogInfoSpotlight div.imageContainer {
    height: 300px;
  }
}
#blogInfo div.blogInfoSpotlight div.imageContainer img {
  object-fit: cover;
}
#blogInfo div.blogInfoSpotlight div.imageContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, rgb(18, 24, 35) 0, rgba(18, 24, 35, 0) 100%);
  height: 400px;
  z-index: 1;
}
#blogInfo div.blogInfoSpotlight div.imageContainer div.info {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#blogInfo div.blogInfoSpotlight div.imageContainer div.info div.wrapper {
  z-index: 9;
  position: relative;
}
#blogInfo div.blogInfoSpotlight div.imageContainer div.info div.wrapper h1.mainHeading {
  color: var(--text-color);
  margin-bottom: 30px;
  text-align: left;
}
#blogInfo div.blogInfoSpotlight div.imageContainer div.info div.wrapper h1.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 640px) {
  #blogInfo div.blogInfoSpotlight div.imageContainer div.info div.wrapper h1.mainHeading {
    font-size: 20px;
  }
  html[lang=ar] #blogInfo div.blogInfoSpotlight div.imageContainer div.info div.wrapper h1.mainHeading {
    font-size: calc(20px + 1px);
  }
}

div.offplanListingSpotlight {
  width: 100%;
}
div.offplanListingSpotlight div.imageContainer {
  position: relative;
  width: 100%;
  height: 750px;
  z-index: 2;
}
@media all and (max-width: 1400px) {
  div.offplanListingSpotlight div.imageContainer {
    height: 582px;
  }
}
div.offplanListingSpotlight div.imageContainer img {
  object-fit: cover;
}
div.offplanListingSpotlight div.imageContainer div.gradient {
  position: absolute;
  background: linear-gradient(360deg, var(--primary-bg-color) 15%, rgba(20, 27, 39, 0.3758096989) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
div.offplanListingSpotlight div.imageContainer h1.heroTitle {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -40%);
  font-size: 37px;
  z-index: 9;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 200;
  font-family: var(--audrey-font-family);
}
html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
  font-size: calc(37px + 1px);
}
@media all and (min-width: 1980px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 3em;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(3em + 1px);
  }
}
@media all and (max-width: 1979px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 2em;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(2em + 1px);
  }
}
@media all and (max-width: 1400px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 1.5em;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(1.5em + 1px);
  }
}
@media all and (max-width: 1400px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 1.5em;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(1.5em + 1px);
  }
}
@media all and (max-width: 1200px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 1.5em;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(1.5em + 1px);
  }
}
@media all and (max-width: 980px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 1.6em;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(1.6em + 1px);
  }
}
@media all and (max-width: 768px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 1.3em;
    max-width: 80%;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(1.3em + 1px);
  }
}
@media all and (max-width: 640px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 1.5em;
    max-width: 85%;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(1.5em + 1px);
  }
}
@media all and (max-width: 480px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    max-width: 95%;
  }
}
@media all and (max-width: 360px) {
  div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: 1em;
  }
  html[lang=ar] div.offplanListingSpotlight div.imageContainer h1.heroTitle {
    font-size: calc(1em + 1px);
  }
}
div.offplanListingSpotlight div.imageContainer div.info {
  position: absolute;
  bottom: 0;
  width: 100%;
}
div.offplanListingSpotlight div.imageContainer div.info div.wrapper {
  z-index: 9;
  position: relative;
}
div.offplanListingSpotlight div.imageContainer div.info div.wrapper h1.mainHeading {
  color: var(--text-color);
  margin-bottom: 30px;
}

#offplanListingPage div.offplanListingComponent {
  background-color: var(--primary-bg-color);
}
#offplanListingPage div.offplanListingComponent div.mainContainer div.listings {
  display: flex;
  flex-wrap: wrap;
}
#offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item {
  width: 30%;
  margin-right: 32px;
  margin-bottom: 35px;
}
#offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  #offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item {
    width: 32%;
    margin-right: 10px;
  }
}
@media all and (max-width: 980px) {
  #offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item {
    width: 49%;
  }
  #offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item:nth-child(3n) {
    margin-right: 10px;
  }
  #offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  #offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item {
    width: 100%;
    margin-right: 0;
  }
  #offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item:nth-child(2n) {
    margin-right: 0;
  }
  #offplanListingPage div.offplanListingComponent div.mainContainer div.listings div.item:nth-child(3n) {
    margin-right: 0;
  }
}

#contactUsPage {
  padding-bottom: 100px;
}
#contactUsPage div.contactSpotlight {
  width: 100%;
}
#contactUsPage div.contactSpotlight div.imageContainer {
  position: relative;
  width: 100%;
  height: 750px;
  z-index: 2;
}
#contactUsPage div.contactSpotlight div.imageContainer img {
  object-fit: cover;
}
#contactUsPage div.contactSpotlight div.imageContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, var(--secondary-bg-color) 30%, rgba(18, 24, 35, 0) 100%);
  height: 300px;
  z-index: 1;
}
#contactUsPage div.contactSpotlight div.imageContainer div.info {
  position: absolute;
  bottom: 90px;
  width: 100%;
}
#contactUsPage div.contactSpotlight div.imageContainer div.info div.wrapper {
  z-index: 9;
  position: relative;
}
#contactUsPage div.contactSpotlight div.imageContainer div.info div.wrapper h1.mainHeading {
  color: var(--text-color);
  margin-bottom: 10px;
}
#contactUsPage div.contactSpotlight div.imageContainer div.info div.wrapper p.subHeading {
  text-align: center;
}

#contactUsPage div.ourOffice div.wrapper div.gallery {
  display: flex;
  justify-content: space-between;
}
#contactUsPage div.ourOffice div.wrapper div.gallery img {
  object-fit: cover;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.item {
  position: relative;
  height: 250px;
  width: 100%;
  margin-bottom: 100px;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.leftContainer {
  width: 20%;
}
@media all and (max-width: 1200px) {
  #contactUsPage div.ourOffice div.wrapper div.gallery div.leftContainer {
    display: none;
  }
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.leftContainer div.item:nth-child(1) {
  animation: fadeIn 2.6s infinite alternate;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.leftContainer div.item:nth-child(2) {
  animation: fadeIn 2s infinite alternate;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.leftContainer div.item:nth-child(3) {
  animation: fadeIn 2.4s infinite alternate;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer {
  width: 40%;
}
@media all and (max-width: 1200px) {
  #contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer {
    margin: 0 auto;
    width: 62%;
  }
}
@media all and (max-width: 768px) {
  #contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  #contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer {
    width: 100%;
  }
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer span.pointerIcon {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer span.pointerIcon svg {
  width: 20px;
  height: 200px;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer span.pointerIcon svg g {
  stroke-width: 2px;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer div.centerImage {
  position: relative;
  height: 700px;
  width: 100%;
}
@media all and (max-width: 768px) {
  #contactUsPage div.ourOffice div.wrapper div.gallery div.centerContainer div.centerImage {
    height: 500px;
  }
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.rightContainer {
  width: 20%;
}
@media all and (max-width: 1200px) {
  #contactUsPage div.ourOffice div.wrapper div.gallery div.rightContainer {
    display: none;
  }
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.rightContainer div.item:nth-child(1) {
  animation: fadeIn 2s infinite alternate;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.rightContainer div.item:nth-child(2) {
  animation: fadeIn 2.5s infinite alternate;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.rightContainer div.item:nth-child(3) {
  animation: fadeIn 2.6s infinite alternate;
}
#contactUsPage div.ourOffice div.wrapper div.gallery div.rightContainer div.item:first-child {
  height: 180px;
}

#contactUsPage div.contactInfo {
  margin-top: 60px;
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media all and (max-width: 640px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer {
    justify-content: center;
  }
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item {
  width: 30%;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item {
    width: 48%;
  }
}
@media all and (max-width: 980px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item:last-child {
    margin-bottom: 0;
  }
}
@media all and (max-width: 640px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item {
    width: 100%;
    text-align: center;
  }
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.top {
  margin-bottom: 8px;
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.top.multiple {
  display: flex;
  justify-content: center;
}
@media all and (max-width: 980px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.top.multiple {
    justify-content: center;
  }
}
@media all and (max-width: 640px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.top.multiple {
    justify-content: center;
  }
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.top.multiple span.icon:first-child {
  display: block;
  padding-right: 15px;
  border-right: 1px solid var(--border-color);
  margin-right: 15px;
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.top span.icon svg {
  width: 22px;
  height: 22px;
}
@media all and (max-width: 980px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.bottom {
    text-align: center;
  }
}
@media all and (max-width: 640px) {
  #contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.bottom {
    text-align: center;
  }
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.bottom a {
  font-weight: 400;
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.bottom a strong {
  color: var(--light-text-color);
  font-weight: 700;
}
#contactUsPage div.contactInfo div.wrapper div.infoContainer div.item div.bottom p {
  color: var(--text-color);
  font-weight: 400;
}
#contactUsPage div.contactInfo div.wrapper div.social {
  display: flex;
  margin-bottom: 90px;
  justify-content: center;
}
@media all and (max-width: 980px) {
  #contactUsPage div.contactInfo div.wrapper div.social {
    justify-content: center;
  }
}
@media all and (max-width: 640px) {
  #contactUsPage div.contactInfo div.wrapper div.social {
    justify-content: center;
  }
}
#contactUsPage div.contactInfo div.wrapper div.social span.icon {
  display: block;
  margin-right: 15px;
}
#contactUsPage div.contactInfo div.wrapper div.social span.icon svg {
  width: 25px !important;
  height: 25px !important;
}

#privacyPolicy {
  padding: 130px 0;
}
#privacyPolicy p {
  text-transform: none;
}
#privacyPolicy h1.mainHeading {
  text-align: left;
}
#privacyPolicy p.lastUpdated {
  margin-bottom: 20px;
}

div.arrow-right.slick-next::before {
  content: "";
}
div.arrow-right.slick-disabled {
  opacity: 0.3;
}

div.arrow-left.slick-prev::before {
  content: "";
}
div.arrow-left.slick-disabled {
  opacity: 0.3;
}

#categoryWise {
  padding: 0 0 130px;
  background-color: var(--primary-bg-color);
}

div.mortgageCalculator h2.heading {
  text-align: center;
  text-transform: uppercase;
  color: var(--text-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 2em;
  max-width: 90%;
  margin: 0 auto 20px;
}
html[lang=ar] div.mortgageCalculator h2.heading {
  font-size: calc(18px + 1px);
}
@media all and (max-width: 980px) {
  div.mortgageCalculator h2.heading {
    max-width: 100%;
  }
}
div.mortgageCalculator div.calculatorContainer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background-color: var(--dark-blue);
}
@media all and (max-width: 980px) {
  div.mortgageCalculator div.calculatorContainer {
    flex-direction: column;
  }
}
@media all and (max-width: 640px) {
  div.mortgageCalculator div.calculatorContainer {
    padding: 20px;
  }
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer {
  width: 45%;
}
@media all and (max-width: 1200px) {
  div.mortgageCalculator div.calculatorContainer div.leftcontainer {
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.mortgageCalculator div.calculatorContainer div.leftcontainer {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer .rangeslider__fill {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%) !important;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer {
  margin-bottom: 20px;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.label {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  width: 40%;
}
@media all and (max-width: 768px) {
  div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.label {
    width: 100%;
  }
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.label div.react-tooltip {
  z-index: 9;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.label label {
  font-size: 13px;
  color: var(--light-text-color);
  font-weight: 300;
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.label label {
  font-size: calc(13px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.label span.icon {
  display: inline-block;
  margin-left: 6px;
  line-height: 0;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.label span.icon svg {
  width: 20px !important;
  height: 20px !important;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields {
  display: flex;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields.oneByTwo {
  display: flex;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields.oneByTwo:last-child {
  margin-right: 0;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields.oneByTwo:last-child div.inputField {
  width: 48%;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField {
  width: 100%;
  border: 1px solid var(--border-color);
  padding: 10px;
  display: flex;
  position: relative;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField.doubleField {
  width: 93%;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField.downPayment {
  width: 42% !important;
  margin-right: 15px;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField input {
  width: 100%;
  background-color: transparent;
  color: var(--text-color);
  font-size: 14px;
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField input {
  font-size: calc(14px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField input::-webkit-outer-spin-button,
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.inputField input[type=number] {
  -moz-appearance: textfield;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.aedSign,
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.sign {
  background-color: var(--border-color);
  padding: 10px;
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.aedSign span,
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.sign span {
  font-size: 14px;
  color: var(--text-color);
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.aedSign span,
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.sign span {
  font-size: calc(14px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.percentageSign {
  width: 10%;
  background-color: var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
@media all and (max-width: 1200px) {
  div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.percentageSign {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.percentageSign {
    width: 12%;
  }
}
@media all and (max-width: 640px) {
  div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.percentageSign {
    width: 23%;
  }
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.percentageSign span {
  font-size: 14px;
  color: var(--text-color);
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer div.fields div.percentageSign span {
  font-size: calc(14px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer span.error {
  font-size: 12px;
  color: var(--error-color);
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.leftcontainer div.inputContainer span.error {
  font-size: calc(12px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.leftcontainer div.rangeContainer div.head {
  display: flex;
  justify-content: space-between;
  color: var(--light-text-color);
  font-weight: 300;
  font-size: 13px;
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.leftcontainer div.rangeContainer div.head {
  font-size: calc(13px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.rightContainer {
  width: 45%;
}
@media all and (max-width: 980px) {
  div.mortgageCalculator div.calculatorContainer div.rightContainer {
    width: 100%;
  }
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer {
  background-color: var(--secondary-bg-color);
  padding: 30px;
  border-radius: 10px;
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection h3.result {
  color: var(--text-color);
  margin-right: 6px;
  margin-bottom: 10px;
  font-size: 2rem;
  display: inline-block;
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection h3.result {
  font-size: calc(2rem + 1px);
}
@media all and (max-width: 480px) {
  div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection h3.result {
    font-size: 25px;
  }
  html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection h3.result {
    font-size: calc(25px + 1px);
  }
}
@media all and (max-width: 480px) {
  div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection h3.result {
    font-size: 20px;
  }
  html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection h3.result {
    font-size: calc(20px + 1px);
  }
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection span.sub {
  font-size: 12px;
  color: var(--light-text-color);
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top div.resultSection span.sub {
  font-size: calc(12px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top p {
  font-size: 12px;
  text-transform: none;
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.top p {
  font-size: calc(12px + 1px);
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center {
  margin: 30px 0;
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.oneLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.oneLine {
    flex-wrap: wrap;
  }
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.oneLine span {
  color: var(--light-text-color);
  font-weight: 300;
  font-size: 14px;
  display: block;
}
html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.oneLine span {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 480px) {
  div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.oneLine span {
    font-size: 14px;
  }
  html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.oneLine span {
    font-size: calc(14px + 1px);
  }
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.oneLine span:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 480px) {
  div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.buttonContainer button.globalBtn {
    width: 100%;
    padding: 10px 15px;
    font-size: 13px;
  }
  html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.center div.buttonContainer button.globalBtn {
    font-size: calc(13px + 1px);
  }
}
div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.bottom p {
  text-transform: none;
}
@media all and (max-width: 480px) {
  div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.bottom p {
    font-size: 13px;
  }
  html[lang=ar] div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.bottom p {
    font-size: calc(13px + 1px);
  }
  div.mortgageCalculator div.calculatorContainer div.rightContainer div.resultContainer div.bottom p.ar {
    text-align: right;
  }
}
div.mortgageCalculator h1 {
  margin-bottom: 4rem;
  font-size: 40px;
}
html[lang=ar] div.mortgageCalculator h1 {
  font-size: calc(40px + 1px);
}
@media all and (max-width: 768px) {
  div.mortgageCalculator h1 {
    margin-bottom: 2rem;
    font-size: 30px;
  }
  html[lang=ar] div.mortgageCalculator h1 {
    font-size: calc(30px + 1px);
  }
}
@media all and (max-width: 768px) {
  div.mortgageCalculator h2 {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0.3rem;
  }
  div.mortgageCalculator h2.ar {
    text-align: right;
  }
}
div.mortgageCalculator h3 {
  font-size: 16.53px;
}
html[lang=ar] div.mortgageCalculator h3 {
  font-size: calc(16.53px + 1px);
}
@media all and (max-width: 768px) {
  div.mortgageCalculator h3 {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
  }
  div.mortgageCalculator h3.ar {
    text-align: right;
  }
}
div.mortgageCalculator .paragraphContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 768px) {
  div.mortgageCalculator .paragraphContainer {
    width: 100%;
  }
  div.mortgageCalculator .paragraphContainer p.ar {
    text-align: right;
  }
}
div.mortgageCalculator .paragraphContainer div {
  margin: 1rem 0rem 1.2rem 0rem;
  text-align: center;
  line-height: 1.3rem;
  width: 50%;
}
@media all and (max-width: 768px) {
  div.mortgageCalculator .paragraphContainer div {
    width: 100%;
    text-align: left;
    margin-right: 20px;
    margin-left: 20px;
  }
  div.mortgageCalculator .paragraphContainer div.ar {
    text-align: right;
  }
}

#loadingUI {
  position: relative;
  background-color: var(--primary-bg-color);
  z-index: 1;
  padding-top: 100px;
  width: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(18, 24, 35, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(18, 24, 35, 0.5) 60%, rgba(18, 24, 35, 0));
  animation: shimmer 2s infinite;
}
@media all and (max-width: 980px) {
  #loadingUI {
    margin-top: 160px;
  }
}
@media all and (max-width: 768px) {
  #loadingUI {
    margin-top: 380px;
  }
}
@media all and (max-width: 480px) {
  #loadingUI {
    margin-top: 500px;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
} 
div.paginationContainer {
  width: 50%;
  margin: 30px auto 0;
}
div.paginationContainer ul {
  display: flex;
  justify-content: center;
}
div.paginationContainer ul li {
  border: 1px solid var(--border-color);
}
div.paginationContainer ul li a {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
div.paginationContainer ul li a svg {
  stroke: var(--text-color);
}
div.paginationContainer ul li.disabled a {
  color: var(--border-color);
  cursor: not-allowed;
}
div.paginationContainer ul li.disabled a svg {
  opacity: 0.2;
}
div.paginationContainer ul li.selected {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
}
div.paginationContainer ul li.selected a {
  color: #000;
}
div.paginationContainer ul li span {
  font-weight: 500;
  color: var(--text-color);
}

div.secondarySearchFields {
  width: 100%;
}
div.secondarySearchFields .sortOrder {
  width: 15% !important;
  margin-left: auto !important;
  margin-right: 2.4% !important;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields .sortOrder {
    margin-right: 1.2% !important;
  }
}
@media all and (max-width: 768px) {
  div.secondarySearchFields .sortOrder {
    margin-right: unset;
  }
}
div.secondarySearchFields div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
}
div.secondarySearchFields div.closeIcon {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
  animation: fadeIn 1s ease-in-out;
}
div.secondarySearchFields div.closeIcon svg {
  width: 20px !important;
  height: 20px !important;
}
div.secondarySearchFields div.allFields {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
div.secondarySearchFields div.allFields.responsive {
  display: none;
}
div.secondarySearchFields div.allFields.responsive.showModal {
  display: flex;
  padding: 50px 20px;
  align-items: center;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields.responsive.showModal {
    background-color: var(--secondary-bg-color);
    position: fixed;
    z-index: 999999;
    top: 50%;
    bottom: 0;
    right: 0;
    left: 50%;
    width: 85%;
    margin: 0 auto;
    height: 100dvh;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}
div.secondarySearchFields div.allFields h1.mainHeading {
  text-align: center;
}
@media all and (max-width: 768px) {
  div.secondarySearchFields div.allFields h1.mainHeading {
    font-size: 20px;
  }
  html[lang=ar] div.secondarySearchFields div.allFields h1.mainHeading {
    font-size: calc(20px + 1px);
  }
}
@media all and (max-width: 640px) {
  div.secondarySearchFields div.allFields h1.mainHeading {
    font-size: 18px;
  }
  html[lang=ar] div.secondarySearchFields div.allFields h1.mainHeading {
    font-size: calc(18px + 1px);
  }
}
div.secondarySearchFields div.allFields div.inputField {
  display: flex;
  justify-content: space-between;
  border-radius: 0;
}
div.secondarySearchFields div.allFields div.inputField div.selectedValue {
  color: var(--text-color);
}
div.secondarySearchFields div.allFields div.inputField div.selectedValue.ar {
  margin-right: unset;
  margin-left: 2rem;
  min-width: 250px;
}
div.secondarySearchFields div.allFields div.inputField div.selectedValue span {
  font-size: 15px;
  color: var(--text-color);
  font-weight: 300;
}
html[lang=ar] div.secondarySearchFields div.allFields div.inputField div.selectedValue span {
  font-size: calc(15px + 1px);
}
div.secondarySearchFields div.allFields div.inputField span.icon svg {
  width: 13px !important;
  height: 13px !important;
}
div.secondarySearchFields div.allFields div.inputField ul.dropDown {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  background-color: var(--primary-bg-color);
  z-index: 999;
  max-height: 200px;
  overflow-y: scroll;
}
div.secondarySearchFields div.allFields div.inputField ul.dropDown li {
  text-align: left;
  padding: 15px 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid var(--border-color);
  font-size: 16px;
  text-transform: capitalize;
}
html[lang=ar] div.secondarySearchFields div.allFields div.inputField ul.dropDown li {
  font-size: calc(16px + 1px);
}
div.secondarySearchFields div.allFields div.inputField ul.dropDown li.ar {
  text-align: start;
}
div.secondarySearchFields div.allFields div.inputField ul.dropDown li span {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
}
html[lang=ar] div.secondarySearchFields div.allFields div.inputField ul.dropDown li span {
  font-size: calc(14px + 1px);
}
div.secondarySearchFields div.allFields div.inputField ul.dropDown li:hover {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
div.secondarySearchFields div.allFields div.inputField ul.dropDown li:last-child {
  margin-bottom: 0;
}
div.secondarySearchFields div.allFields div.fieldsContainer {
  display: flex;
  width: calc(99.5% + 30px);
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 1rem;
}
div.secondarySearchFields div.allFields div.commonContainer {
  width: 15%;
  margin-right: 0.45%;
  padding: 15px 8px;
  border: 1px solid var(--border-color);
  position: relative;
  cursor: pointer;
}
div.secondarySearchFields div.allFields div.commonContainer.ar {
  margin-right: unset;
  margin-left: 0.45%;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.commonContainer {
    width: 48% !important;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 768px) {
  div.secondarySearchFields div.allFields div.commonContainer {
    width: 100% !important;
    margin-bottom: 15px;
    margin-right: 0;
  }
  div.secondarySearchFields div.allFields div.commonContainer.ar {
    margin-left: unset;
  }
}
div.secondarySearchFields div.allFields div.offeringType {
  width: 6%;
}
div.secondarySearchFields div.allFields div.propertyStatus.buyResidential {
  width: 13.4%;
}
div.secondarySearchFields div.allFields div.propertyType {
  width: 12%;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.propertyType {
    width: 33%;
  }
}
div.secondarySearchFields div.allFields div.inputSection {
  width: 25%;
  padding: 0;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.inputSection {
    width: 33%;
  }
}
div.secondarySearchFields div.allFields div.inputSection div.inputField {
  padding: 0;
}
div.secondarySearchFields div.allFields div.inputSection div.inputField input {
  width: 100%;
  background-color: transparent;
  padding: 15px 8px;
  height: 100%;
  color: var(--text-color);
  font-size: 15px;
}
html[lang=ar] div.secondarySearchFields div.allFields div.inputSection div.inputField input {
  font-size: calc(15px + 1px);
}
div.secondarySearchFields div.allFields div.inputSection div.inputField input::placeholder {
  font-size: 15px;
  letter-spacing: 1.6px;
  font-weight: 300;
  font-family: var(--lato-font-family);
}
html[lang=ar] div.secondarySearchFields div.allFields div.inputSection div.inputField input::placeholder {
  font-size: calc(15px + 1px);
}
div.secondarySearchFields div.allFields div.inputSection div.inputField input.ar::placeholder {
  letter-spacing: normal;
}
div.secondarySearchFields div.allFields div.inputSection.sale {
  width: 24.45%;
}
div.secondarySearchFields div.allFields div.inputSection.buyResidential {
  width: 28.45%;
}
div.secondarySearchFields div.allFields div.inputSection.rentResidential {
  width: 42.35%;
}
div.secondarySearchFields div.allFields div.bedrooms {
  width: 10%;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.bedrooms {
    width: 33%;
  }
}
div.secondarySearchFields div.allFields div.minPrice {
  width: 14%;
  cursor: pointer;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.minPrice {
    width: 33%;
  }
}
div.secondarySearchFields div.allFields div.maxPrice {
  width: 14%;
}
div.secondarySearchFields div.allFields div.maxPrice.ar {
  margin-left: unset;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.maxPrice {
    margin-right: 10px;
    width: 33%;
  }
}
@media all and (max-width: 768px) {
  div.secondarySearchFields div.allFields div.maxPrice {
    margin-right: unset;
  }
}
div.secondarySearchFields div.allFields div.maxPrice.rentResidential {
  margin-right: unset;
  width: 15%;
}
div.secondarySearchFields div.allFields div.maxPrice.buyResidential {
  width: 15%;
  margin-right: unset;
}
div.secondarySearchFields div.allFields div.minArea {
  width: 11%;
  cursor: pointer;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.minArea {
    width: 33%;
  }
}
div.secondarySearchFields div.allFields div.maxArea {
  width: 11%;
  margin-right: 0;
}
div.secondarySearchFields div.allFields div.maxArea.ar {
  margin-left: unset;
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div.maxArea {
    margin-right: 10px;
    width: 33%;
  }
}
@media all and (max-width: 768px) {
  div.secondarySearchFields div.allFields div.maxArea {
    margin-right: unset;
  }
}
div.secondarySearchFields div.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
div.secondarySearchFields div.filterBtn {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
div.secondarySearchFields div.filterBtn button {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
}
div.secondarySearchFields div.filterBtn button span.text {
  color: #000;
  display: block;
  margin-right: 10px;
}

div.secondaryMainPage {
  padding: 130px 0;
}
div.secondaryMainPage h1.mainHeading {
  margin-bottom: 20px;
}

div.secondarySearchResults {
  margin-top: 1rem;
}
div.secondarySearchResults div.properties {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
div.secondarySearchResults div.properties div.item {
  width: 31.5%;
  margin-right: 15px;
  margin-bottom: 35px;
}
div.secondarySearchResults div.properties div.item:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.secondarySearchResults div.properties div.item {
    width: 48%;
  }
  div.secondarySearchResults div.properties div.item:nth-child(3n) {
    margin-right: 15px;
  }
  div.secondarySearchResults div.properties div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.secondarySearchResults div.properties div.item {
    margin-right: unset;
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.secondarySearchResults div.properties div.item {
    width: 100%;
    margin-right: 0;
  }
  div.secondarySearchResults div.properties div.item:nth-child(3n) {
    margin-right: 0;
  }
  div.secondarySearchResults div.properties div.item:nth-child(2n) {
    margin-right: 0;
  }
}

#secondaryDetailsPage div.gallerySectionSecondary {
  display: flex;
  margin-bottom: 30px;
}
@media all and (max-width: 748px) {
  #secondaryDetailsPage div.gallerySectionSecondary {
    flex-direction: column;
    margin-bottom: unset;
  }
}
#secondaryDetailsPage div.gallerySectionSecondary div.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
#secondaryDetailsPage div.gallerySectionSecondary div.imageContainer img {
  object-fit: cover;
  position: static !important;
}
#secondaryDetailsPage div.gallerySectionSecondary div.left {
  width: 50%;
  margin-right: 6px;
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.left {
    width: 67%;
  }
}
@media all and (max-width: 748px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.left {
    width: 100%;
    margin-right: 0;
  }
}
#secondaryDetailsPage div.gallerySectionSecondary div.left div.imageContainer {
  min-height: 450px;
  max-height: 450px;
}
@media all and (max-width: 1200px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.left div.imageContainer {
    min-height: 380px;
    max-height: 380px;
  }
}
@media all and (max-width: 748px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.left div.imageContainer {
    height: 400px;
    min-height: auto;
    max-height: 400px;
    margin-bottom: 6px;
  }
}
@media all and (max-width: 748px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.left div.imageContainer {
    max-height: 197px;
    min-height: 197px;
  }
}
#secondaryDetailsPage div.gallerySectionSecondary div.loadingImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--secondary-bg-color);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
#secondaryDetailsPage div.gallerySectionSecondary div.loadingImage div.image {
  width: 130px;
  height: 50px;
  position: relative;
}
#secondaryDetailsPage div.gallerySectionSecondary div.right {
  width: 48%;
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right {
    width: 35%;
  }
}
@media all and (max-width: 768px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right {
    width: 100%;
    flex-direction: row;
  }
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right {
    width: 100%;
  }
}
#secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer {
  margin-bottom: 6px;
  min-height: 223px;
  max-height: 223px;
  width: 48%;
  margin-right: 6px;
  position: relative;
}
@media all and (max-width: 1200px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer {
    min-height: 187px;
    max-height: 187px;
  }
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer {
    width: 100%;
    margin-right: 0;
  }
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer :nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer {
    height: auto;
    width: 49%;
  }
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:first-child {
    margin-right: 6px;
  }
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(2) div.buttonContainer {
    display: block;
  }
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer {
    width: 100%;
    max-height: 197px;
    min-height: 197px;
    height: auto;
    margin-right: 0;
  }
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:first-child {
    margin-right: 0;
  }
}
#secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(2n) {
  margin-right: 0;
}
#secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(3) {
    display: none;
  }
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(4) {
    display: none;
  }
}
#secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(4)::before {
  position: absolute;
  content: attr(data-view-more-images);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1.6px;
  cursor: pointer;
}
html[lang=ar] #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(4)::before {
  font-size: calc(18px + 1px);
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(4)::before {
    font-size: 13px;
  }
  html[lang=ar] #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer:nth-child(4)::before {
    font-size: calc(13px + 1px);
  }
}
#secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer div.buttonContainer {
  display: none;
  position: absolute;
  bottom: 5px;
  width: 100%;
}
#secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer div.buttonContainer button.showMore {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 6px 10px;
  width: 95%;
  margin: 0 auto;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
}
html[lang=ar] #secondaryDetailsPage div.gallerySectionSecondary div.right div.imageContainer div.buttonContainer button.showMore {
  font-size: calc(14px + 1px);
}

#secondaryDetailsPage {
  padding: 100px 0;
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage {
    padding: 70px 0;
  }
}
#secondaryDetailsPage div.wrapper {
  width: 96%;
  display: flex;
  justify-content: space-between;
}
#secondaryDetailsPage div.wrapper:first-child {
  display: block;
}
@media all and (max-width: 1200px) {
  #secondaryDetailsPage div.wrapper {
    flex-direction: column;
  }
}
#secondaryDetailsPage div.wrapper div.leftContainer {
  width: 73%;
}
@media all and (max-width: 1400px) {
  #secondaryDetailsPage div.wrapper div.leftContainer {
    width: 70%;
  }
}
@media all and (max-width: 1200px) {
  #secondaryDetailsPage div.wrapper div.leftContainer {
    width: 100%;
  }
}
#secondaryDetailsPage div.wrapper div.rightContainer {
  width: 25%;
}
@media all and (max-width: 1400px) {
  #secondaryDetailsPage div.wrapper div.rightContainer {
    width: 28%;
  }
}
@media all and (max-width: 1200px) {
  #secondaryDetailsPage div.wrapper div.rightContainer {
    width: 100%;
  }
}
#secondaryDetailsPage div._wrapper {
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 992px) {
  #secondaryDetailsPage div._wrapper {
    width: 96%;
  }
}
#secondaryDetailsPage div.wrapper2 {
  width: 90%;
  margin: 0 auto;
  max-width: 1600px;
}
#secondaryDetailsPage div.wrapper2 div.propertyContactForm div.rightContainer h1.mainHeading {
  color: var(--text-color);
  font-size: 16px;
  margin-bottom: 50px;
}
html[lang=ar] #secondaryDetailsPage div.wrapper2 div.propertyContactForm div.rightContainer h1.mainHeading {
  font-size: calc(16px + 1px);
}
#secondaryDetailsPage div.locationInfo {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-width: 5px;
  padding-top: 60px;
  width: 100%;
  position: relative;
}
#secondaryDetailsPage div.locationInfo div.wrapper {
  display: block;
}
#secondaryDetailsPage div.locationInfo h1.mainHeading {
  text-align: left;
  font-size: 16px;
  margin-bottom: 15px;
  color: var(--text-color);
}
html[lang=ar] #secondaryDetailsPage div.locationInfo h1.mainHeading {
  font-size: calc(16px + 1px);
}
#secondaryDetailsPage div.locationInfo div.mapContainer {
  width: 100%;
  height: 600px;
  position: relative;
  margin-bottom: 20px;
}
#secondaryDetailsPage div.locationInfo div.mapContainer img {
  object-fit: cover;
}
#secondaryDetailsPage div.locationInfo div.content {
  font-size: 14px;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 60px;
}
html[lang=ar] #secondaryDetailsPage div.locationInfo div.content {
  font-size: calc(14px + 1px);
}

#secondaryDetailsPage {
  color: var(--text-color);
}
#secondaryDetailsPage h1.heading, #secondaryDetailsPage h2.heading {
  color: var(--text-color);
  font-size: 20px;
  font-family: var(--audrey-font-family);
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}
html[lang=ar] #secondaryDetailsPage h1.heading, html[lang=ar] #secondaryDetailsPage h2.heading {
  font-size: calc(20px + 1px);
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage h1.heading, #secondaryDetailsPage h2.heading {
    font-size: 18px;
  }
  html[lang=ar] #secondaryDetailsPage h1.heading, html[lang=ar] #secondaryDetailsPage h2.heading {
    font-size: calc(18px + 1px);
  }
}
#secondaryDetailsPage h1.title {
  font-family: var(--audrey-font-family);
  text-transform: uppercase;
  color: var(--text-color);
  font-size: 23px;
  margin-bottom: 15px;
}
html[lang=ar] #secondaryDetailsPage h1.title {
  font-size: calc(23px + 1px);
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage h1.title {
    font-size: 18px;
    line-height: 1.5em;
  }
  html[lang=ar] #secondaryDetailsPage h1.title {
    font-size: calc(18px + 1px);
  }
}
#secondaryDetailsPage h2.price {
  font-size: 30px;
  font-weight: 600;
  font-family: var(--audrey-font-family);
  margin-bottom: 15px;
}
html[lang=ar] #secondaryDetailsPage h2.price {
  font-size: calc(30px + 1px);
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage h2.price {
    font-size: 20px;
  }
  html[lang=ar] #secondaryDetailsPage h2.price {
    font-size: calc(20px + 1px);
  }
}
#secondaryDetailsPage div.metaInfo {
  padding-bottom: 30px;
  border-top: none;
  border-right: none;
  border-left: none;
}
#secondaryDetailsPage div.metaInfo ul.items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#secondaryDetailsPage div.metaInfo ul.items li.item {
  padding: 6px 12px;
  border-right: 1px solid var(--border-color);
  display: flex;
  align-items: center;
}
#secondaryDetailsPage div.metaInfo ul.items li.item.location {
  width: auto;
  padding: 6px 0;
}
@media all and (max-width: 640px) {
  #secondaryDetailsPage div.metaInfo ul.items li.item {
    width: 33%;
    margin-bottom: 10px;
    justify-content: center;
  }
  #secondaryDetailsPage div.metaInfo ul.items li.item:nth-child(3) {
    border-right: none;
  }
  #secondaryDetailsPage div.metaInfo ul.items li.item:nth-child(4) {
    justify-content: flex-start;
  }
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.metaInfo ul.items li.item {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    justify-content: flex-start;
    padding: 15px 10px;
  }
  #secondaryDetailsPage div.metaInfo ul.items li.item:last-child {
    border-bottom: none;
  }
  #secondaryDetailsPage div.metaInfo ul.items li.item:first-child {
    padding: 15px 10px !important;
  }
}
#secondaryDetailsPage div.metaInfo ul.items li.item:first-child {
  padding-left: 0;
}
#secondaryDetailsPage div.metaInfo ul.items li.item:first-child.ar {
  padding-left: 10px;
}
#secondaryDetailsPage div.metaInfo ul.items li.item:last-child {
  border-right: none;
}
#secondaryDetailsPage div.metaInfo ul.items li.item:last-child.ar {
  padding-right: 10px;
}
@media all and (min-width: 480x) {
  #secondaryDetailsPage div.metaInfo ul.items li.item:last-child.ar {
    border-right: 1px solid var(--border-color);
  }
}
#secondaryDetailsPage div.metaInfo ul.items li.item span {
  color: var(--light-text-color);
  display: block;
  margin-left: 6px;
  line-height: 0;
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.metaInfo ul.items li.item span {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.metaInfo ul.items li.item span.icon {
    margin-right: 10px;
  }
  #secondaryDetailsPage div.metaInfo ul.items li.item span.icon.ar {
    margin-right: unset;
    margin-left: 10px;
  }
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.metaInfo ul.items li.item span.text {
    color: var(--text-color);
    font-size: 16px;
  }
  html[lang=ar] #secondaryDetailsPage div.metaInfo ul.items li.item span.text {
    font-size: calc(16px + 1px);
  }
}
#secondaryDetailsPage div.description {
  padding: 35px 0;
}
#secondaryDetailsPage div.description div.content {
  line-height: 2em;
  font-size: 16px !important;
  color: var(--light-text-color) !important;
  font-weight: 300 !important;
  position: relative;
}
html[lang=ar] #secondaryDetailsPage div.description div.content {
  font-size: calc(16px + 1px) !important;
}
#secondaryDetailsPage div.description div.content.contract {
  max-height: 500px;
  overflow: hidden;
}
#secondaryDetailsPage div.description div.content strong {
  color: var(--text-color);
}
#secondaryDetailsPage div.description div.content p {
  text-transform: none;
  font-size: 16px !important;
}
html[lang=ar] #secondaryDetailsPage div.description div.content p {
  font-size: calc(16px + 1px) !important;
}
#secondaryDetailsPage div.description div.content div.moreSection {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 100px 0 0 0;
  background: linear-gradient(360deg, rgb(20, 27, 39) 10%, rgba(20, 27, 39, 0.3758096989) 100%);
}
#secondaryDetailsPage div.description div.content div.moreSection.disableGradient {
  background: none;
  padding: 0 0 0 0;
  position: static;
}
#secondaryDetailsPage div.description div.content div.moreSection button {
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid var(--text-color);
  border-top: none;
  border-left: none;
  border-right: none;
}
#secondaryDetailsPage div.description div.content div.moreSection button span {
  background: linear-gradient(to right, #F0CAB2 44%, #AC836D 53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  text-transform: uppercase;
}
html[lang=ar] #secondaryDetailsPage div.description div.content div.moreSection button span {
  font-size: calc(14px + 1px);
}
#secondaryDetailsPage div.listingDetails {
  border-left: none;
  border-right: none;
  padding: 35px 0;
}
#secondaryDetailsPage div.listingDetails ul.pInfo {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo {
    margin-bottom: 0px;
  }
}
#secondaryDetailsPage div.listingDetails ul.pInfo li.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  width: 45%;
  margin-right: 40px;
  padding: 15px 0;
}
#secondaryDetailsPage div.listingDetails ul.pInfo li.list:nth-child(2n) {
  margin-right: 0;
}
#secondaryDetailsPage div.listingDetails ul.pInfo li.list:first-child, #secondaryDetailsPage div.listingDetails ul.pInfo li.list:nth-child(2) {
  border-top: none;
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list:first-child, #secondaryDetailsPage div.listingDetails ul.pInfo li.list:nth-child(2) {
    border-top: 1px solid var(--border-color);
  }
}
@media all and (max-width: 980px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list {
    width: 100%;
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
#secondaryDetailsPage div.listingDetails ul.pInfo li.list div.left {
  width: 30%;
}
@media all and (max-width: 1400px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.left {
    width: 39%;
  }
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.left {
    width: 100%;
    margin-bottom: 10px;
  }
}
#secondaryDetailsPage div.listingDetails ul.pInfo li.list div.left h6.label {
  color: var(--light-text-color);
  font-size: 14px;
  font-family: var(--lato-font-family);
}
html[lang=ar] #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.left h6.label {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.left h6.label {
    font-size: 15px;
  }
  html[lang=ar] #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.left h6.label {
    font-size: calc(15px + 1px);
  }
}
#secondaryDetailsPage div.listingDetails ul.pInfo li.list div.right {
  width: 50%;
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.right {
    width: 100%;
  }
}
#secondaryDetailsPage div.listingDetails ul.pInfo li.list div.right h4.value {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
}
html[lang=ar] #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.right h4.value {
  font-size: calc(14px + 1px);
}
@media all and (max-width: 480px) {
  #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.right h4.value {
    font-size: 16px;
  }
  html[lang=ar] #secondaryDetailsPage div.listingDetails ul.pInfo li.list div.right h4.value {
    font-size: calc(16px + 1px);
  }
}
#secondaryDetailsPage div.featuresAndAmenities {
  padding: 35px 0;
}
#secondaryDetailsPage div.featuresAndAmenities h1.heading, #secondaryDetailsPage div.featuresAndAmenities h2.heading {
  margin-bottom: 25px;
}
#secondaryDetailsPage div.featuresAndAmenities ul.listItems {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 640px) {
  #secondaryDetailsPage div.featuresAndAmenities ul.listItems {
    justify-content: space-between;
  }
}
#secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item {
  width: 31%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  margin-right: 22px;
}
@media all and (max-width: 640px) {
  #secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item {
    width: 47%;
    margin-right: 0;
  }
  #secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item:nth-child(2n) {
    margin-right: 0;
  }
}
#secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item:nth-child(3n) {
  margin-right: 0;
}
#secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item span.icon {
  display: block;
  transform: rotate(-90deg);
  margin-right: 10px;
}
#secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item span.icon.ar {
  transform: rotate(90deg);
}
#secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item span.text {
  font-weight: 300;
  font-size: 16px;
}
html[lang=ar] #secondaryDetailsPage div.featuresAndAmenities ul.listItems li.item span.text {
  font-size: calc(16px + 1px);
}
#secondaryDetailsPage .textUnderline {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}

div.galleryCarousel {
  padding-bottom: 60px;
  position: relative;
}
div.galleryCarousel.zIndex {
  position: relative;
  z-index: 9999;
}
div.galleryCarousel div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
div.galleryCarousel div.closeIcon {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 99999;
  cursor: pointer;
  animation: fadeIn 1s ease-in-out;
}
div.galleryCarousel div.closeIcon svg {
  width: 20px !important;
  height: 20px !important;
}
div.galleryCarousel div.content {
  height: 100%;
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 75%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  animation: fadeIn 1s ease-in-out;
}
@media all and (max-width: 980px) {
  div.galleryCarousel div.content {
    width: 100%;
    padding: 30px 30px 30px;
  }
}
@media all and (max-width: 480px) {
  div.galleryCarousel div.content {
    width: 100%;
    padding: 10px 10px 10px;
  }
}
div.galleryCarousel div.content div.carouselGallerySection div.item {
  width: 100%;
}
div.galleryCarousel div.content div.carouselGallerySection div.item div.imageContainer {
  position: relative;
  width: 100%;
}
div.galleryCarousel div.content div.carouselGallerySection div.item div.imageContainer img {
  position: relative !important;
  width: 100% !important;
  display: block;
}
div.galleryCarousel div.content div.carouselGallerySection div.arrow-left {
  position: absolute;
  left: -80px;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
}
html[lang=ar] div.galleryCarousel div.content div.carouselGallerySection div.arrow-left {
  font-size: calc(30px + 1px);
}
@media all and (max-width: 980px) {
  div.galleryCarousel div.content div.carouselGallerySection div.arrow-left {
    bottom: -40px;
    left: 40%;
    top: auto;
    z-index: 1;
  }
}
div.galleryCarousel div.content div.carouselGallerySection div.arrow-right {
  position: absolute;
  right: -80px;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
}
html[lang=ar] div.galleryCarousel div.content div.carouselGallerySection div.arrow-right {
  font-size: calc(30px + 1px);
}
@media all and (max-width: 980px) {
  div.galleryCarousel div.content div.carouselGallerySection div.arrow-right {
    bottom: -40px;
    right: 40%;
    top: auto;
  }
}
div.galleryCarousel div.wrapper {
  display: flex;
  justify-content: space-between;
}
div.galleryCarousel div.wrapper ul.images {
  display: flex;
  flex-wrap: wrap;
  width: 77%;
}
@media all and (max-width: 1200px) {
  div.galleryCarousel div.wrapper ul.images {
    width: 100%;
  }
}
div.galleryCarousel div.wrapper ul.images li.item {
  margin-right: 18px;
  width: 32%;
}
div.galleryCarousel div.wrapper ul.images li.item:first-child {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;
}
div.galleryCarousel div.wrapper ul.images li.item:first-child div.imageContainer img {
  object-fit: cover;
  max-height: none;
  min-height: none;
}
div.galleryCarousel div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
  max-height: 250px;
  min-height: 250px;
}
@media all and (min-width: 1980px) {
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    max-height: 290px;
    min-height: 290px;
  }
}
@media all and (max-width: 1400px) {
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: 200px;
    max-height: 200px;
  }
}
@media all and (max-width: 980px) {
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: 150px;
    max-height: 150px;
  }
}
@media all and (max-width: 768px) {
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: 100px;
    max-height: 100px;
  }
}
@media all and (max-width: 480px) {
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(2) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer img, div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer img {
    min-height: unset;
    max-height: unset;
  }
}
div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) {
  margin-right: 0;
}
div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
  position: absolute;
  content: "View more images";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1.6px;
  cursor: pointer;
}
html[lang=ar] div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
  font-size: calc(18px + 1px);
}
@media all and (max-width: 980px) {
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
    font-size: 13px;
  }
  html[lang=ar] div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) div.imageContainer::before {
    font-size: calc(13px + 1px);
  }
}
@media all and (max-width: 1400px) {
  div.galleryCarousel div.wrapper ul.images li.item {
    width: 31%;
  }
}
@media all and (max-width: 1200px) {
  div.galleryCarousel div.wrapper ul.images li.item {
    width: 31.5%;
  }
}
@media all and (max-width: 980px) {
  div.galleryCarousel div.wrapper ul.images li.item {
    width: 30.5%;
  }
}
@media all and (max-width: 640px) {
  div.galleryCarousel div.wrapper ul.images li.item {
    width: 47%;
  }
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(4) {
    display: none;
  }
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) {
    margin-right: 0;
  }
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    position: absolute;
    content: "View more images";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1.6px;
    cursor: pointer;
  }
  html[lang=ar] div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    font-size: calc(18px + 1px);
  }
}
@media all and (max-width: 640px) and (max-width: 980px) {
  div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    font-size: 13px;
  }
  html[lang=ar] div.galleryCarousel div.wrapper ul.images li.item:nth-child(3) div.imageContainer::before {
    font-size: calc(13px + 1px);
  }
}
@media all and (max-width: 480px) {
  div.galleryCarousel div.wrapper ul.images li.item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
div.galleryCarousel div.wrapper ul.images li.item div.imageContainer {
  width: 100%;
  position: relative;
}
div.galleryCarousel div.wrapper ul.images li.item div.imageContainer img {
  position: relative !important;
  width: 100%;
  display: block;
  object-fit: cover;
}
div.galleryCarousel div.wrapper div.offplanContainerRight {
  width: 21%;
  padding-top: 50px;
}
div.galleryCarousel div.wrapper div.offplanContainerRight div.stickyContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}
@media all and (max-width: 1200px) {
  div.galleryCarousel div.wrapper div.offplanContainerRight {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
div.gallerySliderResponsive div.item div.imageContainer {
  max-height: 230px;
}
div.gallerySliderResponsive .slick-next {
  right: 0;
}
div.gallerySliderResponsive .slick-prev {
  left: 0;
  z-index: 1;
}

#loadingImage div.loadingImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--secondary-bg-color);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loadingImage div.loadingImage div.image {
  width: 130px;
  height: 50px;
  position: relative;
}
#commonCarousel {
  padding-bottom: 60px;
}
#commonCarousel div.gallery {
  margin: 30px auto 0;
  width: 100%;
}
#commonCarousel div.gallery div.propertyItem {
  width: 30%;
  filter: blur(3px);
}
#commonCarousel div.gallery div.propertyItem.active {
  filter: none;
}
#commonCarousel div.slick-slider div.slick-slide {
  padding-right: 30px;
  transform: scale(0.7);
}
@media all and (max-width: 1400px) {
  #commonCarousel div.slick-slider div.slick-slide {
    padding-right: 0;
  }
}
@media all and (max-width: 1050px) {
  #commonCarousel div.slick-slider div.slick-slide {
    transform: scale(1);
    filter: none;
    padding-right: 30px;
  }
}
@media all and (max-width: 480px) {
  #commonCarousel div.slick-slider div.slick-slide {
    padding-right: 10px;
  }
}
@media all and (max-width: 980px) {
  #commonCarousel div.slick-slider div.slick-list {
    overflow: visible !important;
  }
}
#commonCarousel div.slick-slider div.arrow-left {
  position: absolute;
  bottom: -20px;
  left: 250px !important;
  font-size: 35px;
  cursor: pointer;
  z-index: 9;
  top: unset !important;
}
html[lang=ar] #commonCarousel div.slick-slider div.arrow-left {
  font-size: calc(35px + 1px);
}
@media all and (max-width: 1050px) {
  #commonCarousel div.slick-slider div.arrow-left {
    bottom: -50px;
  }
}
@media all and (max-width: 980px) {
  #commonCarousel div.slick-slider div.arrow-left {
    display: none;
  }
}
#commonCarousel div.slick-slider div.arrow-right {
  position: absolute;
  bottom: -20px !important;
  right: 250px !important;
  font-size: 35px;
  cursor: pointer;
  top: unset !important;
}
html[lang=ar] #commonCarousel div.slick-slider div.arrow-right {
  font-size: calc(35px + 1px);
}
@media all and (max-width: 1050px) {
  #commonCarousel div.slick-slider div.arrow-right {
    bottom: -50px !important;
  }
}
@media all and (max-width: 980px) {
  #commonCarousel div.slick-slider div.arrow-right {
    display: none;
  }
}
@media all and (max-width: 1200px) {
  #commonCarousel div.slick-slider div.propertyItem {
    width: 19rem !important;
  }
}
@media all and (max-width: 1050px) {
  #commonCarousel div.slick-slider div.propertyItem {
    width: 100% !important;
  }
}
#commonCarousel div.slick-slider div.slick-center.slick-slide {
  filter: none !important;
  transform: scale(1) !important;
  transition: 0.4s;
}
#commonCarousel div.slick-slider div.slick-center div.propertyItem {
  z-index: 999;
}

#mortage-calculator {
  margin-top: 150px;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 20rem;
}
#mortage-calculator .faqSection {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

@media all and (max-width: 820px) {
  #mortage-calculator {
    margin: unset;
    margin-top: 10rem;
  }
  #mortage-calculator .faqSection {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
#review-container {
  padding: 2rem;
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;
  justify-content: center;
}
#review-container .review-content {
  display: flex;
  flex-direction: row;
  padding: 5rem 6rem 5rem 6rem;
}
#review-container .review-box {
  display: flex;
  padding: 1.6rem 2.401rem;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  border-top: none;
  border-bottom: none;
  border-left: none;
}
#review-container .review-box.ar {
  border-right: unset;
  border-left: inherit;
}
#review-container .google-logo {
  width: 6.25rem;
}
#review-container .review-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
}
#review-container .google-rating {
  color: #FFF;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6rem;
}
html[lang=ar] #review-container .google-rating {
  font-size: calc(1.6rem + 1px);
}
#review-container .rating {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
#review-container .rating-value {
  text-align: center;
  font-size: 2.401rem;
  font-style: normal;
  font-weight: 800;
}
html[lang=ar] #review-container .rating-value {
  font-size: calc(2.401rem + 1px);
}
#review-container .reviews-link {
  color: #868686;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
html[lang=ar] #review-container .reviews-link {
  font-size: calc(1.2rem + 1px);
}
#review-container .reviews-link:hover {
  text-decoration: underline;
}
#review-container .agency-message {
  padding-left: 3rem;
  display: flex;
  align-items: center;
  max-width: 40rem;
}
#review-container .agency-message.ar {
  padding-left: unset;
  padding-right: 3rem;
}
#review-container .title {
  text-align: left;
  font-size: 2rem;
}
html[lang=ar] #review-container .title {
  font-size: calc(2rem + 1px);
}

@media all and (max-width: 1065px) {
  #review-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #review-container .review-content {
    flex-direction: column;
    border: none;
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #review-container .review-box {
    border-right: none;
    padding: unset;
    order: 2;
    margin-top: 1rem;
  }
  #review-container .google-logo {
    width: 67.75px;
    height: 67.75px;
  }
  #review-container .google-rating {
    font-size: 17.344px;
    line-height: 17.344px;
  }
  html[lang=ar] #review-container .google-rating {
    font-size: calc(17.344px + 1px);
  }
  #review-container .rating {
    display: flex;
    align-items: center;
    gap: 10.344px;
  }
  #review-container .rating-value {
    font-size: 26.016px;
    line-height: 26.016px;
  }
  html[lang=ar] #review-container .rating-value {
    font-size: calc(26.016px + 1px);
  }
  #review-container .review-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8.672px;
  }
  #review-container .reviews-link {
    font-size: 13.008px;
  }
  html[lang=ar] #review-container .reviews-link {
    font-size: calc(13.008px + 1px);
  }
  #review-container .title {
    text-align: center;
    font-family: var(--audrey-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px; /* 181.25% */
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }
  html[lang=ar] #review-container .title {
    font-size: calc(20px + 1px);
  }
  #review-container .agency-message {
    padding-left: unset;
    order: 1;
  }
  #review-container .agency-message.ar {
    padding-right: unset;
  }
}
.breadcrumb-container {
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  color: white;
  background-color: rgba(18, 24, 35, 0.7);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 998;
  padding: 10px 50px;
  white-space: nowrap;
}
@media all and (max-width: 980px) {
  .breadcrumb-container {
    padding: 10px 20px;
    overflow-y: scroll;
  }
}

@media all and (max-width: 980px) {
  ol {
    flex-wrap: unset !important;
    overflow-y: scroll !important;
    padding-bottom: 0.2rem !important;
    overflow-y: hidden !important;
  }
}

.slider {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
.slider.oneItem {
  width: 33.33%;
}
.slider.twoItems {
  width: 66.66%;
}
.slider .sliderItem {
  padding: 0 5px;
}

@media (max-width: 1200px) {
  .slider.oneItem {
    width: 50%;
    margin: unset;
  }
  .slider.twoItems {
    width: 100%;
  }
}
@media all and (max-width: 576px) {
  .slider.oneItem {
    width: 100%;
  }
}
.sliderArrows {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.sliderArrows .arrow {
  font-size: 30px;
  cursor: pointer;
}
html[lang=ar] .sliderArrows .arrow {
  font-size: calc(30px + 1px);
}
.sliderArrows .arrow.prev {
  margin-right: 50px;
}
.sliderArrows .arrow.next {
  margin-left: 50px;
}
.sliderArrows .arrow.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.noData .noDataImg {
  margin-bottom: 1rem;
  width: 220px;
  height: 293.962px;
}
.noData .we_could_not_find_match {
  color: #9B9B9B;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
html[lang=ar] .noData .we_could_not_find_match {
  font-size: calc(30px + 1px);
}
.noData .similar_interests {
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
html[lang=ar] .noData .similar_interests {
  font-size: calc(30px + 1px);
}
@media (max-width: 992px) {
  .noData .noData {
    margin-top: 1rem;
  }
  .noData .noDataImg {
    margin-bottom: 1rem;
    width: 198px;
    height: 264.5658px;
  }
  .noData .we_could_not_find_match {
    font-size: 20px;
    letter-spacing: 1.6px;
  }
  html[lang=ar] .noData .we_could_not_find_match {
    font-size: calc(20px + 1px);
  }
  .noData .similar_interests {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.6px;
  }
  html[lang=ar] .noData .similar_interests {
    font-size: calc(20px + 1px);
  }
}
@media (max-width: 768px) {
  .noData .noDataImg {
    margin-bottom: 1rem;
    width: 176px;
    height: 235.1696px;
  }
  .noData .we_could_not_find_match {
    font-size: 16px;
    letter-spacing: 1.28px;
  }
  html[lang=ar] .noData .we_could_not_find_match {
    font-size: calc(16px + 1px);
  }
  .noData .similar_interests {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.28px;
  }
  html[lang=ar] .noData .similar_interests {
    font-size: calc(16px + 1px);
  }
}

.developers {
  margin-bottom: 100px;
  margin-top: 150px;
}
.developers .isDesktop {
  display: block;
}
.developers .top {
  margin-top: 50px;
  color: white;
  margin-bottom: 3rem;
}
.developers .top .title {
  color: #FFF;
  text-align: center;
  font-family: var(--audrey-font-family);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.654px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 7rem;
}
html[lang=ar] .developers .top .title {
  font-size: calc(30px + 1px);
}
.developers .top .subtitle {
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-bottom: 3rem;
  width: 80%;
}
html[lang=ar] .developers .top .subtitle {
  font-size: calc(20px + 1px);
}
.developers .top .gradientLine {
  width: 60%;
  margin: 0 auto;
  height: 1px;
  position: relative;
}
@media all and (max-width: 1400px) {
  .developers .top .gradientLine {
    width: 85%;
  }
}
.developers .top ._container {
  display: flex;
  flex-flow: wrap;
  row-gap: 35px;
  column-gap: 15px;
  margin-top: 3.5rem;
}
.developers .top .contentContainer {
  margin-top: 7rem;
}
.developers .faqContainer {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

@media (max-width: 1350px) {
  .developers .top .title {
    font-size: 18px;
    letter-spacing: 1.28px;
  }
  html[lang=ar] .developers .top .title {
    font-size: calc(18px + 1px);
  }
  .developers .top .subtitle {
    line-height: 25px;
    letter-spacing: 1.28px;
    margin-bottom: 2rem;
    font-size: 16px;
    margin-bottom: 3.5rem;
    width: 100%;
  }
  html[lang=ar] .developers .top .subtitle {
    font-size: calc(16px + 1px);
  }
  .developers .top ._container {
    margin-top: 3rem;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .developers .top .title {
    font-size: 18px;
    letter-spacing: 1.28px;
    line-height: 20px;
    text-align: left;
  }
  html[lang=ar] .developers .top .title {
    font-size: calc(18px + 1px);
  }
  .developers .top .title.ar {
    text-align: right;
  }
  .developers .top .subtitle {
    line-height: 30px;
    font-size: 16px;
    letter-spacing: 1.28px;
    margin-bottom: 2rem;
    text-align: left;
  }
  html[lang=ar] .developers .top .subtitle {
    font-size: calc(16px + 1px);
  }
  .developers .top .subtitle.ar {
    text-align: right;
  }
  .developers .faqContainer {
    margin-top: 5rem;
    margin-bottom: unset;
  }
  .developers .faqContainer .container .sectionTitle {
    width: 100% !important;
  }
  .developers .faqContainer .container .parent {
    width: 100% !important;
  }
}
._developerCard {
  width: 31.5%;
  display: flex;
}
._developerCard ._left {
  background-color: white;
  width: 151.6px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
._developerCard ._left ._logo {
  width: 150.7558px;
  max-height: 150px;
  padding-left: 1rem;
  padding-right: 1rem;
}
._developerCard ._right {
  margin-left: 1.5rem;
  margin-right: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
._developerCard ._right ._content {
  margin-top: 3rem;
}
._developerCard ._right ._content ._title {
  color: #FFF;
  font-family: var(--lato-font-family);
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
html[lang=ar] ._developerCard ._right ._content ._title {
  font-size: calc(19px + 1px);
}
._developerCard ._right ._content ._projects {
  margin-bottom: 1.2rem;
  color: #FFF;
  font-family: var(--lato-font-family);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  display: block;
}
html[lang=ar] ._developerCard ._right ._content ._projects {
  font-size: calc(18px + 1px);
}
._developerCard ._right ._content ._projects ._image {
  width: 17.3115px;
  height: 14.1642px;
  margin-right: 0.5rem;
}
._developerCard ._right ._content ._description {
  color: #FFF;
  margin-top: 1rem;
  font-family: var(--lato-font-family);
  font-size: 14.4px;
  font-weight: 300;
  text-transform: uppercase;
}
html[lang=ar] ._developerCard ._right ._content ._description {
  font-size: calc(14.4px + 1px);
}
._developerCard ._right ._view_more {
  width: 100%;
  text-align: right;
  padding-right: 1.5rem;
  margin-bottom: 2rem;
}
._developerCard ._right ._view_more ._view_all_projects {
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 12.4px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  display: flex;
}
html[lang=ar] ._developerCard ._right ._view_more ._view_all_projects {
  font-size: calc(12.4px + 1px);
}
._developerCard ._right ._view_more ._view_all_projects_image {
  margin-left: 0.3rem;
}
._developerCard ._right ._view_more ._view_all_projects_image.ar {
  margin-left: unset;
  margin-right: 0.3rem;
  transform: rotate(180deg);
}

@media (max-width: 1200px) {
  ._developerCard {
    width: 360.809px;
    height: 172.173px;
    display: flex;
  }
  ._developerCard ._left {
    background-color: white;
    width: 120.653px;
    height: 171.487px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  ._developerCard ._left ._logo {
    width: 120.20464px;
    max-height: 150px;
  }
  ._developerCard ._right {
    margin-left: 1.2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  ._developerCard ._right ._content {
    margin-top: 1.5rem;
  }
  ._developerCard ._right ._content ._title {
    color: #FFF;
    font-family: var(--lato-font-family);
    font-size: 16.463px;
    font-weight: 400;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.3rem;
  }
  html[lang=ar] ._developerCard ._right ._content ._title {
    font-size: calc(16.463px + 1px);
  }
  ._developerCard ._right ._content ._projects {
    margin-bottom: 0.5rem;
    color: #FFF;
    font-family: var(--lato-font-family);
    font-size: 13.719px;
    font-weight: 300;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    display: block;
  }
  html[lang=ar] ._developerCard ._right ._content ._projects {
    font-size: calc(13.719px + 1px);
  }
  ._developerCard ._right ._content ._projects ._image {
    width: 13.8492px;
    height: 11.33136px;
    margin-right: 0.4rem;
  }
  ._developerCard ._right ._content ._description {
    color: #FFF;
    margin-top: 0.8rem;
    font-family: var(--lato-font-family);
    font-size: 10.975px;
    font-weight: 300;
    text-transform: uppercase;
  }
  html[lang=ar] ._developerCard ._right ._content ._description {
    font-size: calc(10.975px + 1px);
  }
  ._developerCard ._right ._view_more {
    width: 100%;
    text-align: right;
    padding-right: 1.2rem;
    margin-bottom: 0.8rem;
  }
  ._developerCard ._right ._view_more ._view_all_projects {
    color: #FFF;
    text-align: center;
    font-family: var(--lato-font-family);
    font-size: 11.52px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.16px;
    text-transform: uppercase;
  }
  html[lang=ar] ._developerCard ._right ._view_more ._view_all_projects {
    font-size: calc(11.52px + 1px);
  }
  ._developerCard ._right ._view_more ._view_all_projects_image {
    margin-left: 0.24rem;
  }
}
@media (max-width: 992px) and (orientation: portrait) {
  ._developerCard {
    width: 317.809px;
  }
}
@media (max-width: 756px) and (orientation: portrait) {
  ._developerCard {
    width: 100%;
  }
}
@media (max-width: 576px) and (orientation: portrait) {
  ._developerCard {
    width: 100%;
  }
}
._developerCardHeader {
  display: flex;
  width: calc(94.5% + 30px);
}
._developerCardHeader ._left {
  background-color: white;
  width: 201.6px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
._developerCardHeader ._left ._logo {
  width: 182.7558px;
  height: auto;
}
._developerCardHeader ._right {
  margin-left: 50px;
  margin-right: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
._developerCardHeader ._right ._content {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
._developerCardHeader ._right ._content ._title {
  display: block;
  color: #FFF;
  font-family: var(--audrey-font-family);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
html[lang=ar] ._developerCardHeader ._right ._content ._title {
  font-size: calc(28px + 1px);
}
._developerCardHeader ._right ._content ._description {
  color: #FFF;
  font-family: var(--lato-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30.154px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
html[lang=ar] ._developerCardHeader ._right ._content ._description {
  font-size: calc(16px + 1px);
}

@media (max-width: 1500px) {
  ._developerCardHeader {
    width: calc(91% + 15px);
  }
}
@media (max-width: 1200px) {
  ._developerCardHeader {
    width: 360.809px;
    height: 172.173px;
    display: flex;
  }
  ._developerCardHeader ._left {
    background-color: white;
    width: 120.653px;
    height: 171.487px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  ._developerCardHeader ._left ._logo {
    width: 120.20464px;
    height: auto;
  }
  ._developerCardHeader ._right {
    margin-left: 1.2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  ._developerCardHeader ._right ._content {
    margin-top: 1.5rem;
  }
  ._developerCardHeader ._right ._content ._title {
    color: #FFF;
    font-family: var(--lato-font-family);
    font-size: 16.463px;
    font-weight: 400;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.3rem;
  }
  html[lang=ar] ._developerCardHeader ._right ._content ._title {
    font-size: calc(16.463px + 1px);
  }
  ._developerCardHeader ._right ._content ._description {
    color: #FFF;
    margin-top: 0.8rem;
    font-family: var(--lato-font-family);
    font-size: 10.975px;
    font-weight: 300;
    text-transform: uppercase;
  }
  html[lang=ar] ._developerCardHeader ._right ._content ._description {
    font-size: calc(10.975px + 1px);
  }
}
@media (max-width: 980px) {
  ._developerCardHeader {
    width: 317.809px;
  }
}
@media (max-width: 980px) and (orientation: landscape) {
  ._developerCardHeader {
    width: 305.809px;
  }
}
@media (max-width: 668px) and (orientation: landscape) {
  ._developerCardHeader {
    width: 370.809px;
  }
}
#tags {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-wrap: wrap;
}
#tags .buttonContainer {
  display: flex;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}
@media all and (max-width: 576px) {
  #tags .buttonContainer {
    width: 100%;
  }
}
#tags .buttonContainer a {
  width: 100%;
  border-radius: 2rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px 10px;
  text-transform: uppercase;
  color: var(--text-color);
  letter-spacing: 1.6px;
  font-size: 11px;
}
html[lang=ar] #tags .buttonContainer a {
  font-size: calc(11px + 1px);
}
#tags .buttonContainer a:hover {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  color: #000;
}

.areasAndCommunities {
  margin-bottom: 100px;
  margin-top: 150px;
}
.areasAndCommunities .isDesktop {
  display: block;
}
.areasAndCommunities .top {
  margin-top: 150px;
  color: white;
  margin-bottom: 3rem;
}
.areasAndCommunities .top .title {
  color: #FFF;
  text-align: center;
  font-family: var(--audrey-font-family);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.654px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
html[lang=ar] .areasAndCommunities .top .title {
  font-size: calc(30px + 1px);
}
.areasAndCommunities .top .subtitle {
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-bottom: 3rem;
  width: 90%;
}
html[lang=ar] .areasAndCommunities .top .subtitle {
  font-size: calc(20px + 1px);
}
.areasAndCommunities .top .gradientLine {
  width: 60%;
  margin: 0 auto;
  height: 1px;
  position: relative;
}
@media all and (max-width: 1400px) {
  .areasAndCommunities .top .gradientLine {
    width: 85%;
  }
}
.areasAndCommunities .top ._container {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  row-gap: 35px;
  column-gap: 15px;
  margin-top: 3.5rem;
}
.areasAndCommunities .top .contentContainer {
  margin-top: 7rem;
}

@media (max-width: 1350px) {
  .areasAndCommunities .top .title {
    font-size: 18px;
    letter-spacing: 1.28px;
  }
  html[lang=ar] .areasAndCommunities .top .title {
    font-size: calc(18px + 1px);
  }
  .areasAndCommunities .top .subtitle {
    line-height: 25px;
    letter-spacing: 1.28px;
    margin-bottom: 2rem;
    font-size: 16px;
    margin-bottom: 3.5rem;
    width: 95%;
  }
  html[lang=ar] .areasAndCommunities .top .subtitle {
    font-size: calc(16px + 1px);
  }
  .areasAndCommunities .top ._container {
    margin-top: 3rem;
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .areasAndCommunities .top .title {
    font-size: 18px;
    letter-spacing: 1.28px;
    line-height: 20px;
    text-align: left;
  }
  html[lang=ar] .areasAndCommunities .top .title {
    font-size: calc(18px + 1px);
  }
  .areasAndCommunities .top .title.ar {
    text-align: right;
  }
  .areasAndCommunities .top .subtitle {
    line-height: 30px;
    font-size: 16px;
    letter-spacing: 1.28px;
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;
  }
  html[lang=ar] .areasAndCommunities .top .subtitle {
    font-size: calc(16px + 1px);
  }
  .areasAndCommunities .top .subtitle.ar {
    text-align: right;
  }
}
.community .title {
  color: white;
  font-size: 24px;
  line-height: 32px;
}
html[lang=ar] .community .title {
  font-size: calc(24px + 1px);
}

._imageContainer {
  position: relative;
  width: 100%;
  height: 750px;
  background-repeat: no-repeat;
  background-size: 100%;
}
._imageContainer .__title {
  color: #FFF;
  text-align: center;
  font-family: var(--audrey-font-family);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.654px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
}
html[lang=ar] ._imageContainer .__title {
  font-size: calc(30px + 1px);
}

._titleContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(1turn, #121823, rgba(18, 24, 35, 0));
  height: 400px;
  z-index: 1;
}

.__subtitle {
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-bottom: 5rem;
  width: 90%;
}
html[lang=ar] .__subtitle {
  font-size: calc(16px + 1px);
}

.__gradientLine {
  margin: 5rem 0rem 0rem 0rem;
}

.community .__top {
  color: white;
  margin-bottom: 3rem;
}

.similarPropertyComponentHeading {
  margin: 0 0 1rem 0 !important;
  text-align: center !important;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}
html[lang=ar] .similarPropertyComponentHeading {
  font-size: calc(1.5rem + 1px) !important;
}

.similarPropertyComponentSubheading {
  margin: 0 0 3rem 0 !important;
  text-align: center !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
html[lang=ar] .similarPropertyComponentSubheading {
  font-size: calc(1rem + 1px) !important;
}

@media (max-width: 1200px) {
  ._imageContainer {
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  ._imageContainer .__title {
    text-align: left;
    width: 85%;
    margin: 0 auto;
    max-width: 1600px;
  }
  ._imageContainer .__title.ar {
    text-align: right;
  }
  .__subtitle {
    width: 100%;
    line-height: 2.5rem;
    text-align: left;
  }
  .__subtitle.ar {
    text-align: right;
  }
}
@media (max-width: 576px) {
  .similarPropertyComponentHeading {
    text-align: left !important;
  }
  .similarPropertyComponentHeading.ar {
    text-align: right !important;
  }
  .similarPropertyComponentSubheading {
    text-align: left !important;
  }
  .similarPropertyComponentSubheading.ar {
    text-align: right !important;
  }
  .__subtitle {
    margin-bottom: 2rem;
  }
  .__subtitle {
    width: 100%;
    font-size: 14px;
    line-height: 2rem;
    text-align: left;
  }
  html[lang=ar] .__subtitle {
    font-size: calc(14px + 1px);
  }
}
.communitiesAndAreasCard {
  width: 49%;
}
.communitiesAndAreasCard ._top {
  background-color: white;
  background-image: url("/stage-default.png");
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
}
.communitiesAndAreasCard ._top ._logo {
  width: 100%;
  height: 355px;
  object-fit: cover; /* Ensures the image covers the container */
  object-position: center;
}
.communitiesAndAreasCard ._bottom {
  margin-left: 1.5rem;
  margin-right: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.communitiesAndAreasCard ._bottom ._content {
  margin-top: 3rem;
}
.communitiesAndAreasCard ._bottom ._content ._title {
  color: #FFF;
  font-family: var(--lato-font-family);
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
html[lang=ar] .communitiesAndAreasCard ._bottom ._content ._title {
  font-size: calc(19px + 1px);
}
.communitiesAndAreasCard ._bottom ._content ._description {
  margin-bottom: 1rem;
  line-height: 1.2rem;
}
.communitiesAndAreasCard ._bottom ._content ._projects {
  margin-bottom: 1.2rem;
  color: #FFF;
  font-family: var(--lato-font-family);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  display: block;
}
html[lang=ar] .communitiesAndAreasCard ._bottom ._content ._projects {
  font-size: calc(18px + 1px);
}
.communitiesAndAreasCard ._bottom ._content ._projects ._image {
  width: 17.3115px;
  height: 14.1642px;
  margin-right: 0.5rem;
}
.communitiesAndAreasCard ._bottom ._content ._projects ._image.ar {
  margin-right: unset;
  margin-left: 0.5rem;
}
.communitiesAndAreasCard ._bottom ._content ._description {
  color: #FFF;
  margin-top: 1rem;
  font-family: var(--lato-font-family);
  font-size: 14.4px;
  font-weight: 300;
  text-transform: uppercase;
}
html[lang=ar] .communitiesAndAreasCard ._bottom ._content ._description {
  font-size: calc(14.4px + 1px);
}
.communitiesAndAreasCard ._bottom ._view_more {
  width: 100%;
  text-align: right;
  padding-right: 1.5rem;
  margin-bottom: 2rem;
}
.communitiesAndAreasCard ._bottom ._view_more ._view_all_projects {
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 12.4px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  display: flex;
}
html[lang=ar] .communitiesAndAreasCard ._bottom ._view_more ._view_all_projects {
  font-size: calc(12.4px + 1px);
}
.communitiesAndAreasCard ._bottom ._view_more ._view_all_projects_image {
  margin-left: 0.3rem;
}
.communitiesAndAreasCard ._bottom ._view_more ._view_all_projects_image.ar {
  margin-left: unset;
  margin-right: 0.3rem;
  transform: rotate(180deg);
}

@media (max-width: 1200px) {
  .communitiesAndAreasCard ._top ._logo {
    height: 165.036px;
  }
  .communitiesAndAreasCard ._bottom {
    margin-left: 1.2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .communitiesAndAreasCard ._bottom ._content {
    margin-top: 1.5rem;
  }
  .communitiesAndAreasCard ._bottom ._content ._title {
    color: #FFF;
    font-family: var(--lato-font-family);
    font-size: 16.463px;
    font-weight: 400;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.3rem;
  }
  html[lang=ar] .communitiesAndAreasCard ._bottom ._content ._title {
    font-size: calc(16.463px + 1px);
  }
  .communitiesAndAreasCard ._bottom ._content ._projects {
    margin-bottom: 0.5rem;
    color: #FFF;
    font-family: var(--lato-font-family);
    font-size: 13.719px;
    font-weight: 300;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    display: block;
  }
  html[lang=ar] .communitiesAndAreasCard ._bottom ._content ._projects {
    font-size: calc(13.719px + 1px);
  }
  .communitiesAndAreasCard ._bottom ._content ._projects ._image {
    width: 13.8492px;
    height: 11.33136px;
    margin-right: 0.4rem;
  }
  .communitiesAndAreasCard ._bottom ._content ._description {
    color: #FFF;
    margin-top: 0.8rem;
    font-family: var(--lato-font-family);
    font-size: 10.975px;
    font-weight: 300;
    text-transform: uppercase;
  }
  html[lang=ar] .communitiesAndAreasCard ._bottom ._content ._description {
    font-size: calc(10.975px + 1px);
  }
  .communitiesAndAreasCard ._bottom ._view_more {
    width: 100%;
    text-align: right;
    padding-right: 1.2rem;
    margin-bottom: 1rem;
  }
  .communitiesAndAreasCard ._bottom ._view_more ._view_all_projects {
    color: #FFF;
    text-align: center;
    font-family: var(--lato-font-family);
    font-size: 11.52px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.16px;
    text-transform: uppercase;
  }
  html[lang=ar] .communitiesAndAreasCard ._bottom ._view_more ._view_all_projects {
    font-size: calc(11.52px + 1px);
  }
  .communitiesAndAreasCard ._bottom ._view_more ._view_all_projects_image {
    margin-left: 0.24rem;
  }
}
@media (max-width: 992px) {
  .communitiesAndAreasCard {
    width: 48.5%;
  }
}
@media (max-width: 576px) and (orientation: portrait) {
  .communitiesAndAreasCard {
    width: 100%;
  }
}
#cta {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
  padding: 2rem 1rem;
  font-family: var(--audrey-font-family);
}
#cta .heading {
  font-size: 2rem;
  text-transform: uppercase;
}
html[lang=ar] #cta .heading {
  font-size: calc(2rem + 1px);
}
#cta .subheading {
  font-size: 1.1rem;
  font-family: var(--lato-font-family);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 300;
}
html[lang=ar] #cta .subheading {
  font-size: calc(1.1rem + 1px);
}

@media all and (max-width: 1400px) {
  #cta {
    width: 85%;
  }
  #cta .heading {
    font-size: 20px;
    text-align: center !important;
  }
  html[lang=ar] #cta .heading {
    font-size: calc(20px + 1px);
  }
  #cta .subheading {
    font-size: 16px;
    line-height: 1.5rem;
    text-align: center !important;
  }
  html[lang=ar] #cta .subheading {
    font-size: calc(16px + 1px);
  }
}
.chatbotContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  /* Fullscreen on mobile */
}
.chatbotContainer .chatWindow {
  width: 448px;
  height: 80dvh;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
@media (max-width: 768px) {
  .chatbotContainer .chatWindow {
    width: 100%;
    height: 100vh;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
  .chatbotContainer .chatHeader, .chatbotContainer .inputContainer {
    padding: 10px;
  }
  .chatbotContainer .chatHeader {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.chatbotContainer .chatHeader {
  padding: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0.44%, rgba(0, 0, 0, 0) 49.5%), #ffffff;
  color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 1.25rem;
  border-bottom: 1px solid rgb(229, 231, 235);
}
.chatbotContainer .chatHeader div {
  display: flex;
  align-items: center;
  width: 50%;
}
.chatbotContainer .chatHeader div .agentName {
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: rgb(63, 63, 70);
  font-size: 0.875rem;
  font-weight: 700;
  margin-left: 0.5rem;
  letter-spacing: 1px;
  text-transform: unset;
}
html[lang=ar] .chatbotContainer .chatHeader div .agentName {
  font-size: calc(0.875rem + 1px);
}
.chatbotContainer .chatBody {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.chatbotContainer .messagesContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.chatbotContainer .scrollButtonContainer {
  position: absolute;
  bottom: 100px;
  right: 2rem;
}
.chatbotContainer .scrollButtonContainer .scrollButton {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid rgb(212, 212, 216);
  background-color: rgb(250, 250, 250);
  width: 2.5rem;
  height: 2.5rem;
}
.chatbotContainer .scrollButtonContainer .scrollButton img {
  margin-top: 4px;
  opacity: 0.7;
}
.chatbotContainer .messageContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.chatbotContainer .userMessageContainer {
  justify-content: flex-end;
}
.chatbotContainer .botMessageContainer {
  justify-content: flex-start;
}
.chatbotContainer .botAvatar {
  margin-right: 0.5rem;
  align-self: flex-end;
  padding-bottom: 0.5rem;
}
.chatbotContainer .message {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  padding: 8px;
  border-radius: 8px;
  max-width: 80%;
  word-wrap: break-word;
}
.chatbotContainer .inputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
  border-top: 1px solid rgb(228, 228, 231);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.chatbotContainer .inputContainer .input {
  margin-right: 1.25rem;
  border: none;
  width: 100%;
  height: 4rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
html[lang=ar] .chatbotContainer .inputContainer .input {
  font-size: calc(0.875rem + 1px);
}
.chatbotContainer .inputContainer .sendButton {
  background-color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbotContainer .inputContainer .sendButton .sendImage {
  opacity: 0.8;
}
.chatbotContainer .inputContainer .sendButton:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.chatbotContainer .openButton {
  border: 0px;
  bottom: 1rem;
  right: unset;
  left: unset;
  width: 55px;
  height: 55px;
  border-radius: 27.5px;
  background-color: rgb(32, 117, 188);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  cursor: pointer;
  z-index: 2147483645;
  transition: 0.2s ease-in-out;
  left: 1rem;
  transform: scale(1);
}
.chatbotContainer .openButton:hover {
  transform: scale(1.1);
}
.chatbotContainer .openButton div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.chatbotContainer .closeButton {
  background-color: transparent;
  border: none;
  color: gray;
  font-size: 16px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease;
}
html[lang=ar] .chatbotContainer .closeButton {
  font-size: calc(16px + 1px);
}
.chatbotContainer .closeButton:hover {
  transform: scale(1.1);
}
.chatbotContainer .userMessage {
  align-self: flex-end;
  background-color: rgb(32, 117, 188);
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #fff;
  font-size: 0.875rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1.25rem;
  border-radius: 20px;
  border-bottom-right-radius: 0.25rem;
  letter-spacing: 1px;
  font-weight: 400;
}
html[lang=ar] .chatbotContainer .userMessage {
  font-size: calc(0.875rem + 1px);
}
.chatbotContainer .botMessage {
  align-self: flex-start;
  background-color: rgba(228, 228, 231, 0.5);
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: rgb(63, 63, 70);
  font-size: 0.875rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  line-height: 1.25rem;
  border-radius: 20px;
  border-bottom-left-radius: 0.25rem;
  letter-spacing: 1px;
  font-weight: 400;
  position: relative;
}
html[lang=ar] .chatbotContainer .botMessage {
  font-size: calc(0.875rem + 1px);
}

.copyButton {
  background-color: transparent;
  border: none;
  color: #007bff; /* Button text color */
  cursor: pointer;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  bottom: 0.75rem;
  right: 1rem;
}
html[lang=ar] .copyButton {
  font-size: calc(12px + 1px);
}

.messageContainer:hover .copyButton {
  opacity: 1; /* Button becomes visible when hovering over the message */
}

.copyButton:hover {
  color: rgb(32, 117, 188); /* Darken color when hovering over the button */
}

@media all and (max-width: 61.25rem) {
  .BlogContent .wrapper.ar {
    text-align: right;
  }
  .BlogContent #faq .container .sectionTitle {
    width: 100%;
  }
  .BlogContent #faq .container .parent {
    width: 100%;
  }
}
.subheading {
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: white;
  font-size: 18px;
  font-family: var(--lato-font-family);
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}
html[lang=ar] .subheading {
  font-size: calc(18px + 1px);
}

@media all and (max-width: 576px) {
  .subheading {
    font-size: 18px;
    text-align: left;
    margin-bottom: 2rem;
  }
  html[lang=ar] .subheading {
    font-size: calc(18px + 1px);
  }
  .subheading.ar {
    text-align: right;
  }
}
#floorplans {
  padding-top: 80px;
  padding-bottom: 80px;
  border-top: unset;
  border-right: unset;
  border-left: unset;
  border-width: 5px;
}
#floorplans .wrapper {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1200px) {
  #floorplans .wrapper {
    flex-direction: column;
  }
}
#floorplans .wrapper .mainHeading {
  text-align: left;
  font-size: 1.25rem;
  color: var(--text-color);
  margin-bottom: 20px;
  letter-spacing: 1.6px;
}
html[lang=ar] #floorplans .wrapper .mainHeading {
  font-size: calc(1.25rem + 1px);
}
#floorplans .wrapper .mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 992px) {
  #floorplans .wrapper .mainHeading {
    line-height: 2rem;
  }
}
#floorplans .wrapper .bedrooms {
  display: flex;
  margin-top: 2rem;
}
#floorplans .wrapper .bedrooms .item {
  margin-right: 50px;
  color: white;
  cursor: pointer;
}
#floorplans .wrapper .bedrooms .item.ar {
  margin-right: unset;
  margin-left: 50px;
}
#floorplans .wrapper .bedrooms .item.selected {
  text-decoration: underline;
  text-underline-offset: 10px;
}
#floorplans .wrapper ul.pInfo {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
@media all and (max-width: 980px) {
  #floorplans .wrapper ul.pInfo {
    margin-bottom: 0px;
    margin-top: 30px;
  }
}
#floorplans .wrapper ul.pInfo li.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  width: 80%;
  margin-right: 40px;
  padding: 15px 0;
}
#floorplans .wrapper ul.pInfo li.list.ar {
  margin-right: unset;
}
@media all and (max-width: 1200px) {
  #floorplans .wrapper ul.pInfo li.list {
    width: 100%;
    margin-right: unset;
  }
}
@media all and (max-width: 480px) {
  #floorplans .wrapper ul.pInfo li.list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
#floorplans .wrapper ul.pInfo li.list div.left {
  width: 30%;
}
@media all and (max-width: 1400px) {
  #floorplans .wrapper ul.pInfo li.list div.left {
    width: 39%;
  }
}
@media all and (max-width: 480px) {
  #floorplans .wrapper ul.pInfo li.list div.left {
    width: 100%;
    margin-bottom: 10px;
  }
}
#floorplans .wrapper ul.pInfo li.list div.left span.label {
  color: var(--light-text-color);
  font-size: 14px;
}
html[lang=ar] #floorplans .wrapper ul.pInfo li.list div.left span.label {
  font-size: calc(14px + 1px);
}
#floorplans .wrapper ul.pInfo li.list div.right {
  width: 50%;
}
@media all and (max-width: 480px) {
  #floorplans .wrapper ul.pInfo li.list div.right {
    width: 100%;
  }
}
#floorplans .wrapper ul.pInfo li.list div.right span.value {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
}
html[lang=ar] #floorplans .wrapper ul.pInfo li.list div.right span.value {
  font-size: calc(14px + 1px);
}
#floorplans .wrapper ._left {
  width: 100%;
}
@media all and (max-width: 1200px) {
  #floorplans .wrapper ._left {
    order: 1;
  }
}
#floorplans .wrapper ._left .fullWidth {
  width: -moz-fit-content;
  width: fit-content;
}
@media all and (max-width: 992px) {
  #floorplans .wrapper ._left .fullWidth {
    width: 100%;
  }
}
#floorplans .wrapper ._right {
  width: 100%;
}
#floorplans .wrapper ._right img {
  height: 426.1px;
  width: 643.527px;
  border: 1px solid #FFECE0;
  padding: 2rem;
  box-sizing: border-box;
}
@media all and (max-width: 1200px) {
  #floorplans .wrapper ._right img {
    margin-bottom: unset;
    margin-top: 3rem;
    width: 100%;
    height: auto;
    padding: 1rem;
  }
}
#floorplans .isMobile {
  display: none;
}
@media all and (max-width: 1200px) {
  #floorplans .isMobile {
    display: flex;
  }
}
#floorplans .isDesktop {
  display: none;
}
@media all and (min-width: 1200px) {
  #floorplans .isDesktop {
    display: flex;
  }
}

#propertyPrice {
  padding-top: 80px;
  padding-bottom: 80px;
  border-top: unset;
  border-right: unset;
  border-left: unset;
  border-width: 5px;
}
#propertyPrice .wrapper .mainHeading {
  text-align: left;
  font-size: 1.25rem;
  color: var(--text-color);
  margin-bottom: 20px;
  letter-spacing: 1.6px;
  line-height: 2rem;
}
html[lang=ar] #propertyPrice .wrapper .mainHeading {
  font-size: calc(1.25rem + 1px);
}
#propertyPrice .wrapper .mainHeading.ar {
  text-align: right;
}
#propertyPrice .wrapper .priceContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 3rem;
  margin-top: 3rem;
}
@media all and (max-width: 992px) {
  #propertyPrice .wrapper .priceContainer {
    flex-direction: column;
    row-gap: unset;
  }
}
#propertyPrice .wrapper .priceContainer .item {
  text-align: center;
  color: white;
}
@media all and (max-width: 1200px) {
  #propertyPrice .wrapper .priceContainer .item {
    width: 50%;
    text-align: left;
  }
  #propertyPrice .wrapper .priceContainer .item.ar {
    text-align: right;
  }
}
@media all and (max-width: 992px) {
  #propertyPrice .wrapper .priceContainer .item {
    width: 100%;
    padding: 1.5rem 0rem;
    border-bottom: 1px solid var(--border-color);
  }
  #propertyPrice .wrapper .priceContainer .item:first-child {
    padding: 0rem;
    padding-bottom: 1.5rem;
  }
}
#propertyPrice .wrapper .priceContainer .item .price {
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: 2.4px;
}
html[lang=ar] #propertyPrice .wrapper .priceContainer .item .price {
  font-size: calc(1.875rem + 1px);
}
@media all and (max-width: 1200px) {
  #propertyPrice .wrapper .priceContainer .item .price {
    font-size: 1.5rem;
  }
  html[lang=ar] #propertyPrice .wrapper .priceContainer .item .price {
    font-size: calc(1.5rem + 1px);
  }
}
@media all and (max-width: 992px) {
  #propertyPrice .wrapper .priceContainer .item .price {
    font-size: 1.25rem;
  }
  html[lang=ar] #propertyPrice .wrapper .priceContainer .item .price {
    font-size: calc(1.25rem + 1px);
  }
}
#propertyPrice .wrapper .priceContainer .item .text {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 1.92px;
}
html[lang=ar] #propertyPrice .wrapper .priceContainer .item .text {
  font-size: calc(1.2rem + 1px);
}
#propertyPrice .wrapper .cryptoContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
}

div.contactAgentForm {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-blue);
}
@media all and (max-width: 1200px) {
  div.contactAgentForm {
    flex-direction: column;
    padding: 30px;
  }
}
div.contactAgentForm div.leftContainer {
  width: 26%;
}
@media all and (max-width: 1200px) {
  div.contactAgentForm div.leftContainer {
    width: 100%;
    margin-bottom: 30px;
    display: none;
  }
}
div.contactAgentForm div.leftContainer div.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
div.contactAgentForm div.leftContainer div.imageContainer img {
  object-fit: cover;
  position: unset !important;
  display: block;
  width: 100%;
  height: unset !important;
}
div.contactAgentForm div.rightContainer {
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.contactAgentForm div.rightContainer {
    width: 100%;
    padding: 0;
  }
}
div.contactAgentForm div.rightContainer h2.mainHeading {
  color: var(--text-color);
  font-size: 1.375rem;
  text-align: center !important;
  margin-bottom: 50px;
  line-height: 2rem;
}
html[lang=ar] div.contactAgentForm div.rightContainer h2.mainHeading {
  font-size: calc(1.375rem + 1px);
}
div.contactAgentForm div.rightContainer div.form form {
  padding-left: 5rem;
  padding-right: 5rem;
}
@media all and (max-width: 992px) {
  div.contactAgentForm div.rightContainer div.form form {
    padding: unset;
  }
}
div.contactAgentForm div.rightContainer div.form form div.inputFields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
}
@media all and (max-width: 992px) {
  div.contactAgentForm div.rightContainer div.form form div.inputFields {
    flex-direction: column;
    padding-bottom: unset;
  }
}
div.contactAgentForm div.rightContainer div.form form div.inputFields .languageDropdown {
  justify-content: space-between;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer {
  width: 48%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  border-bottom: 1px solid var(--link-color);
  padding-bottom: 8px;
  position: relative;
}
@media all and (max-width: 992px) {
  div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer {
    width: 100%;
  }
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer.noPadding {
  padding-bottom: 0;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer select {
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  font-size: 16px;
}
html[lang=ar] div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer select {
  font-size: calc(16px + 1px);
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer.error {
  border-color: var(--error-color);
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer:nth-child(2n) {
  margin-right: 0;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer:last-child {
  margin-right: 0;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer span.label {
  color: var(--placeholder-color);
  font-size: 14px;
  text-transform: uppercase;
}
html[lang=ar] div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer span.label {
  font-size: calc(14px + 1px);
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer span.errorIcon {
  position: absolute;
  right: 0;
  transform: translateX(0);
  animation: zoomIn 0.6s ease-in-out;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer span.errorIcon svg {
  width: 20px !important;
  height: 20px !important;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer span.icon {
  display: inline-block;
  margin-left: 10px;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer span.icon svg {
  width: 10px !important;
  height: 10px !important;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer span.icon svg path {
  stroke: var(--placeholder-color);
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  top: 30px;
  border-radius: 6px;
  z-index: 9;
  max-height: 400px;
  overflow-y: scroll;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems {
  padding-top: 10px;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems li {
  text-align: center;
  padding: 10px 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems li:hover {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
div.contactAgentForm div.rightContainer div.form form div.inputFields div.inputContainer div.dropDown ul.listDropdownitems li:last-child {
  margin-bottom: 0;
}
div.contactAgentForm div.rightContainer div.form form div.inputFields input {
  width: 100%;
  display: block;
  background-color: transparent;
  caret-color: #fff;
  color: var(--text-color);
  font-size: 16px;
}
html[lang=ar] div.contactAgentForm div.rightContainer div.form form div.inputFields input {
  font-size: calc(16px + 1px);
}
div.contactAgentForm div.rightContainer div.form form div.inputFields input::placeholder {
  letter-spacing: 1.6px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: var(--lato-font-family);
}
html[lang=ar] div.contactAgentForm div.rightContainer div.form form div.inputFields input::placeholder {
  font-size: calc(14px + 1px);
}
div.contactAgentForm div.rightContainer div.form form div.inputFields input.ar::placeholder {
  letter-spacing: normal;
}
div.contactAgentForm div.rightContainer div.form form div.buttonContainer {
  text-align: center;
}
div.contactAgentForm div.rightContainer div.form form div.buttonContainer button.globalBtn {
  width: 100%;
  margin: 0 auto;
}

#offplanInfoPage div.aboutDeveloper {
  border-left: none;
  border-right: none;
  border-width: 5px;
  padding-top: 80px;
  padding-bottom: 80px;
}
#offplanInfoPage div.aboutDeveloper div.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}
#offplanInfoPage div.aboutDeveloper div.wrapper .parent {
  width: 50%;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper .parent {
    width: 100%;
  }
}
#offplanInfoPage div.aboutDeveloper div.wrapper .parent h2.mainHeading {
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  font-weight: 700;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
html[lang=ar] #offplanInfoPage div.aboutDeveloper div.wrapper .parent h2.mainHeading {
  font-size: calc(1.5rem + 1px);
}
#offplanInfoPage div.aboutDeveloper div.wrapper .parent h2.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper .parent h2.mainHeading {
    text-align: center;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper .parent h2.mainHeading {
    font-size: 1.25rem;
    letter-spacing: 1.6px;
    line-height: 2rem;
  }
  html[lang=ar] #offplanInfoPage div.aboutDeveloper div.wrapper .parent h2.mainHeading {
    font-size: calc(1.25rem + 1px);
  }
}
#offplanInfoPage div.aboutDeveloper div.wrapper .parent .developer_description {
  width: 500px;
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 31.768px; /* 158.839% */
  letter-spacing: 0.4px;
  text-transform: unset;
  width: 100%;
}
html[lang=ar] #offplanInfoPage div.aboutDeveloper div.wrapper .parent .developer_description {
  font-size: calc(1.25rem + 1px);
}
#offplanInfoPage div.aboutDeveloper div.wrapper .parent .developer_image {
  width: 500px;
  background-color: white;
  padding: 1rem;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper .parent .developer_image {
    width: 50%;
  }
}
#offplanInfoPage div.aboutDeveloper div.wrapper .parent.imageParent {
  display: flex;
  justify-content: center;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper .parent.imageParent {
    justify-content: center;
    margin-bottom: 3rem;
  }
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper .parent.imageParent {
    margin-bottom: 2rem;
  }
}
#offplanInfoPage div.aboutDeveloper div.wrapper .parent .generalButton {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  text-align: center;
  margin-top: 2rem;
  letter-spacing: 1.6px;
}
@media all and (max-width: 576px) {
  #offplanInfoPage div.aboutDeveloper div.wrapper .parent .generalButton {
    letter-spacing: 0;
    width: 100%;
  }
}

#offplanInfoPage div.investSection {
  border-left: none;
  border-right: none;
  border-width: 5px;
  padding-top: 80px;
  padding-bottom: 80px;
}
#offplanInfoPage div.investSection div.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5rem;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.investSection div.wrapper {
    flex-direction: column;
  }
}
#offplanInfoPage div.investSection div.wrapper .parent {
  width: 50%;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.investSection div.wrapper .parent {
    width: 100%;
  }
}
#offplanInfoPage div.investSection div.wrapper .parent h2.mainHeading {
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  font-weight: 700;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
html[lang=ar] #offplanInfoPage div.investSection div.wrapper .parent h2.mainHeading {
  font-size: calc(1.5rem + 1px);
}
#offplanInfoPage div.investSection div.wrapper .parent h2.mainHeading.ar {
  text-align: right;
}
@media all and (max-width: 992px) {
  #offplanInfoPage div.investSection div.wrapper .parent h2.mainHeading {
    font-size: 1.25rem;
  }
  html[lang=ar] #offplanInfoPage div.investSection div.wrapper .parent h2.mainHeading {
    font-size: calc(1.25rem + 1px);
  }
}
#offplanInfoPage div.investSection div.wrapper .parent .image-container {
  width: 100%; /* Set the width of the container to 100% */
  position: relative; /* Create a positioning context */
  padding-bottom: 100%; /* Create a square by making height equal to width using padding-bottom */
  overflow: hidden; /* Hide overflow */
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.investSection div.wrapper .parent .image-container {
    margin-bottom: 2rem;
  }
}
#offplanInfoPage div.investSection div.wrapper .parent .investSection_image {
  margin-right: 10rem;
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.investSection div.wrapper .parent .investSection_image {
    margin-right: unset;
    width: 100%; /* Make the image take the full width of the container */
    height: 100%; /* Set height to 100% of the container */
    object-fit: cover; /* Ensures the image covers the container, maintaining aspect ratio */
    position: absolute; /* Absolutely position the image within the container */
    top: 0;
    left: 0;
  }
}
#offplanInfoPage div.investSection div.wrapper .parent .investSection_title {
  color: #FFF;
  font-family: var(--audrey-font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
html[lang=ar] #offplanInfoPage div.investSection div.wrapper .parent .investSection_title {
  font-size: calc(24px + 1px);
}
#offplanInfoPage div.investSection div.wrapper .parent .investSection_description {
  color: #FFF;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 31.768px; /* 158.839% */
  letter-spacing: 0.4px;
  margin-bottom: 1.2rem;
  text-transform: unset;
}
html[lang=ar] #offplanInfoPage div.investSection div.wrapper .parent .investSection_description {
  font-size: calc(1.125rem + 1px);
}
#offplanInfoPage div.investSection div.wrapper .parent ul {
  padding-left: 40px;
}
#offplanInfoPage div.investSection div.wrapper .parent ul li {
  list-style-type: disc;
  color: #FFF;
  font-family: var(--lato-font-family);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 31.768px;
  letter-spacing: 0.4px;
}
html[lang=ar] #offplanInfoPage div.investSection div.wrapper .parent ul li {
  font-size: calc(1.125rem + 1px);
}
@media all and (max-width: 1200px) {
  #offplanInfoPage div.investSection .isDesktop {
    display: none;
  }
}
@media all and (min-width: 1201px) {
  #offplanInfoPage div.investSection .isMobile {
    display: none;
  }
}
#offplanInfoPage div.investSection .generalButton {
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 1.6px;
}
@media all and (max-width: 576px) {
  #offplanInfoPage div.investSection .generalButton {
    letter-spacing: 0;
    width: 100%;
  }
}

.notFound {
  /* Responsive adjustments for tablets */
  /* Responsive adjustments for mobile */
}
.notFound .container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  z-index: 100;
  /* Image styling */
  /* Gradient overlay */
  /* Button styles */
}
.notFound .container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.notFound .container .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* Gradient overlay coverage */
  background: linear-gradient(180deg, rgba(19, 24, 35, 0) 0%, rgba(19, 24, 35, 0.88) 60%, #121823 100%);
  z-index: 1; /* Ensure it's below the button but above the image */
}
.notFound .container a.backHomeBtn {
  position: absolute;
  bottom: 10%; /* Position at bottom */
  left: 50%;
  z-index: 2; /* Ensure button is above the overlay */
  transform: translateX(-50%);
  padding: 10px 20px;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-size: 3rem;
  transition: background-color 0.3s ease;
  width: -moz-fit-content;
  width: fit-content; /* Fit the width of the text */
  max-width: 100%; /* Ensure it doesn't overflow on smaller screens */
}
html[lang=ar] .notFound .container a.backHomeBtn {
  font-size: calc(3rem + 1px);
}
.notFound .container a.backHomeBtn:hover {
  background-color: #333;
}
@media all and (max-width: 1200px) {
  .notFound .container {
    height: 80vh;
    margin-top: 5rem;
  }
  .notFound .container a.backHomeBtn {
    font-size: 1.9rem;
    width: -moz-fit-content;
    width: fit-content; /* Ensure the button fits the text */
    max-width: 100%; /* Ensure no overflow */
  }
  html[lang=ar] .notFound .container a.backHomeBtn {
    font-size: calc(1.9rem + 1px);
  }
}
@media all and (max-width: 1200px) and (orientation: landscape) {
  .notFound .container a.backHomeBtn {
    font-size: 1rem;
    width: -moz-fit-content;
    width: fit-content; /* Responsive fit for text */
  }
  html[lang=ar] .notFound .container a.backHomeBtn {
    font-size: calc(1rem + 1px);
  }
}
@media all and (max-width: 576px) {
  .notFound .container {
    height: 60vh;
  }
  .notFound .container a.backHomeBtn {
    font-size: 0.7rem;
    width: -moz-fit-content;
    width: fit-content; /* Button width based on text size */
    max-width: 100%; /* Prevent overflow */
    padding: 8px 12px; /* Adjust padding for smaller screens */
  }
  html[lang=ar] .notFound .container a.backHomeBtn {
    font-size: calc(0.7rem + 1px);
  }
}

.iframe {
  width: 70%;
}
@media all and (max-width: 1024px) {
  .iframe {
    width: 90%;
  }
}

.landing {
  background-color: white;
  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
  /* Wrapper */
  /* Sections */
  /* Developer and Project Logos */
  /* Handover Details Styles */
  /* Location Section Styles */
  /* Content Container */
  /* Form Section Styles */
  /* Sticky Footer Styles */
  /* Register Button Styles */
  /* Responsive Styles */
  /* Tablets and smaller devices */
  /* Mobile devices */
  /* Small mobile devices */
  /* If the sticky footer overlaps content, add padding to the body or main container */
  /* Tablet devices */
  /* Mobile devices */
}
.landing .wrapper {
  margin: 5rem auto;
  max-width: 60rem;
  width: 90%;
}
@media all and (max-width: 768px) {
  .landing .wrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
.landing .gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, var(--secondary-bg-color) 30%, rgba(18, 24, 35, 0) 100%);
  height: 400px;
  z-index: 1;
}
.landing .section_1,
.landing .section_2,
.landing .section_3,
.landing .section_4,
.landing .section_5 {
  margin-top: 4rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
}
@media all and (max-width: 1020px) {
  .landing .section_1,
  .landing .section_2,
  .landing .section_3,
  .landing .section_4,
  .landing .section_5 {
    height: 500px;
  }
}
.landing .developer_logo {
  width: 150px;
  height: auto;
}
.landing .project_logo {
  width: 250px;
  height: auto;
}
.landing .handover-details {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 5rem auto 0;
  justify-content: space-between;
  text-align: center;
}
.landing .handover-item {
  flex: 1 1;
  padding: 1rem;
  box-sizing: border-box;
}
.landing .border-right {
  border-right: 1px solid black;
}
.landing .handover-title,
.landing .handover-year,
.landing .handover-subtitle {
  font-family: Montserrat, sans-serif;
}
.landing .handover-title,
.landing .handover-subtitle {
  font-weight: 400;
  line-height: 1.2;
  font-size: 20px;
  margin: 0.5rem 0;
}
html[lang=ar] .landing .handover-title,
html[lang=ar] .landing .handover-subtitle {
  font-size: calc(20px + 1px);
}
.landing .handover-year {
  font-weight: 400;
  line-height: 1.2;
  font-size: 30px;
}
html[lang=ar] .landing .handover-year {
  font-size: calc(30px + 1px);
}
.landing .location-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  justify-content: center;
  margin-top: 5rem;
}
@media all and (max-width: 1024px) {
  .landing .location-container {
    row-gap: unset;
  }
}
.landing .location-item {
  width: 30%;
  text-align: center;
  box-sizing: border-box;
}
.landing .location-time {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: #0e76bc;
  font-size: 22px;
  margin-bottom: 0.1rem;
}
html[lang=ar] .landing .location-time {
  font-size: calc(22px + 1px);
}
.landing .location-text {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: rgb(26, 28, 54);
  font-size: 13px;
  line-height: 1.2;
}
html[lang=ar] .landing .location-text {
  font-size: calc(13px + 1px);
}
.landing .content-container {
  width: 80%;
  margin: 0 auto;
}
.landing .form-wrapper {
  display: flex;
  justify-content: center;
  background-color: rgb(240, 247, 248);
  padding: 5rem 0;
}
@media all and (max-width: 1024px) {
  .landing .form-wrapper {
    padding: unset;
    padding-top: 3rem;
  }
}
.landing .form-section {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  width: 80%;
  max-width: 1200px;
}
.landing .form-image {
  flex: 1 1;
  overflow: hidden;
  min-width: 300px;
}
.landing .form-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #A3BAC6;
  padding: 0.35rem 0 0.5rem 0.35rem;
  margin-left: 1px;
}
.landing .form-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 4rem;
  min-width: 300px;
}
@media all and (max-width: 1024px) {
  .landing .form-container {
    padding: unset;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.landing .form-container h2 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  color: rgb(0, 0, 1);
  font-size: 32px;
  margin-bottom: 1rem;
}
html[lang=ar] .landing .form-container h2 {
  font-size: calc(32px + 1px);
}
.landing .form-container p {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.5;
  width: 65%;
  margin: 0 auto 2rem;
}
html[lang=ar] .landing .form-container p {
  font-size: calc(16px + 1px);
}
.landing .form-container form {
  width: 90%;
  margin: 0 auto;
}
.landing .form-group {
  margin-bottom: 0.5rem;
}
.landing .form-group input,
.landing .form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid black;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
}
html[lang=ar] .landing .form-group input,
html[lang=ar] .landing .form-group textarea {
  font-size: calc(1rem + 1px);
}
.landing .form-group textarea {
  resize: none;
  height: 100px;
  margin-top: 0.75rem;
}
.landing button[type=submit] {
  padding: 0.75rem 1.5rem;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: white;
  font-size: 18px;
  background-color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
html[lang=ar] .landing button[type=submit] {
  font-size: calc(18px + 1px);
}
.landing button[type=submit]:hover {
  background-color: rgb(20, 130, 170);
}
.landing .sticky-footer {
  position: fixed; /* Sticks to the viewport */
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #0B1C26;
  text-align: center;
  padding: 0.5rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  z-index: 1000; /* Ensures it appears above other elements */
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;
}
html[lang=ar] .landing .sticky-footer {
  font-size: calc(16px + 1px);
}
.landing .sticky-footer img {
  width: 100px;
  height: auto;
}
.landing .sticky-footer-logos {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.landing .sticky-footer-text {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 17px;
}
html[lang=ar] .landing .sticky-footer-text {
  font-size: calc(14px + 1px);
}
.landing .register-button {
  background: #FFFFFF;
  color: #002E31;
  font-size: 1.2384rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 3.625rem;
  width: 13.1875rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
html[lang=ar] .landing .register-button {
  font-size: calc(1.2384rem + 1px);
}
@media (max-width: 1024px) {
  .landing .location-container {
    flex-direction: row;
    align-items: center;
  }
  .landing .location-item {
    width: 50%;
    margin-bottom: 2rem;
  }
  .landing .form-section {
    width: 90%;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .landing .handover-details {
    flex-direction: column;
    align-items: center;
    margin: 3rem auto 0;
  }
  .landing .handover-item {
    border-right: none;
    border-bottom: 1px solid black;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .landing .handover-item:last-child {
    border-bottom: none;
  }
  .landing .wrapper {
    width: 95%;
  }
  .landing .form-section {
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;
  }
}
@media (max-width: 768px) and (max-width: 1024px) {
  .landing .form-section {
    padding: unset;
  }
}
@media (max-width: 768px) {
  .landing .form-image,
  .landing .form-container {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .landing .developer_logo {
    width: 100px;
  }
  .landing .project_logo {
    width: 150px;
  }
  .landing .handover-title,
  .landing .handover-year,
  .landing .handover-subtitle {
    font-size: 18px;
  }
  html[lang=ar] .landing .handover-title,
  html[lang=ar] .landing .handover-year,
  html[lang=ar] .landing .handover-subtitle {
    font-size: calc(18px + 1px);
  }
  .landing .form-container h2 {
    font-size: 24px;
  }
  html[lang=ar] .landing .form-container h2 {
    font-size: calc(24px + 1px);
  }
  .landing .form-container p {
    font-size: 14px;
    width: 90%;
  }
  html[lang=ar] .landing .form-container p {
    font-size: calc(14px + 1px);
  }
  .landing button[type=submit] {
    font-size: 16px;
  }
  html[lang=ar] .landing button[type=submit] {
    font-size: calc(16px + 1px);
  }
  .landing .sticky-footer {
    font-size: 14px;
    padding: 0.75rem;
  }
  html[lang=ar] .landing .sticky-footer {
    font-size: calc(14px + 1px);
  }
}
.landing body {
  padding-bottom: 80px; /* Adjust according to the sticky footer's height */
}
@media all and (max-width: 1024px) {
  .landing .sticky-footer {
    gap: unset;
  }
  .landing .sticky-footer .sticky-footer-logos, .landing .sticky-footer .sticky-footer-text {
    display: none;
  }
  .landing .sticky-footer .register-button {
    width: 100%;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
.landing .error {
  color: red;
  font-size: 0.9em;
}
html[lang=ar] .landing .error {
  font-size: calc(0.9em + 1px);
}
.landing #form-container {
  scroll-margin-top: 80px; /* Adjust based on your footer's height */
}
.landing h1, .landing h2, .landing h3, .landing h4, .landing h5, .landing h6, .landing p {
  font-family: "Playfair Display", serif;
  color: black;
  text-align: center;
  font-weight: 400;
}
.landing h1 {
  font-size: 44px;
  line-height: 52px;
}
html[lang=ar] .landing h1 {
  font-size: calc(44px + 1px);
}
.landing h2 {
  font-size: 36px;
  line-height: 44px;
}
html[lang=ar] .landing h2 {
  font-size: calc(36px + 1px);
}
.landing h3 {
  font-size: 30px;
  line-height: 38px;
}
html[lang=ar] .landing h3 {
  font-size: calc(30px + 1px);
}
.landing h4 {
  font-size: 24px;
  line-height: 32px;
}
html[lang=ar] .landing h4 {
  font-size: calc(24px + 1px);
}
.landing h5 {
  font-size: 20px;
  line-height: 28px;
}
html[lang=ar] .landing h5 {
  font-size: calc(20px + 1px);
}
.landing h6 {
  font-size: 16px;
  line-height: 24px;
}
html[lang=ar] .landing h6 {
  font-size: calc(16px + 1px);
}
@media (max-width: 768px) {
  .landing .content-container {
    width: 90%;
    margin: 0 auto;
  }
  .landing h1 {
    font-size: 36px;
    line-height: 44px;
  }
  html[lang=ar] .landing h1 {
    font-size: calc(36px + 1px);
  }
  .landing h2 {
    font-size: 30px;
    line-height: 38px;
  }
  html[lang=ar] .landing h2 {
    font-size: calc(30px + 1px);
  }
  .landing h3 {
    font-size: 26px;
    line-height: 34px;
  }
  html[lang=ar] .landing h3 {
    font-size: calc(26px + 1px);
  }
  .landing h4 {
    font-size: 22px;
    line-height: 30px;
  }
  html[lang=ar] .landing h4 {
    font-size: calc(22px + 1px);
  }
  .landing h5 {
    font-size: 18px;
    line-height: 26px;
  }
  html[lang=ar] .landing h5 {
    font-size: calc(18px + 1px);
  }
  .landing h6 {
    font-size: 16px;
    line-height: 24px;
  }
  html[lang=ar] .landing h6 {
    font-size: calc(16px + 1px);
  }
  .landing p, .landing li, .landing ol li {
    font-size: 16px;
    line-height: 26px;
  }
  html[lang=ar] .landing p, html[lang=ar] .landing li, html[lang=ar] .landing ol li {
    font-size: calc(16px + 1px);
  }
}
@media (max-width: 576px) {
  .landing h1 {
    font-size: 32px;
    line-height: 40px;
  }
  html[lang=ar] .landing h1 {
    font-size: calc(32px + 1px);
  }
  .landing h2 {
    font-size: 28px;
    line-height: 36px;
  }
  html[lang=ar] .landing h2 {
    font-size: calc(28px + 1px);
  }
  .landing h3 {
    font-size: 24px;
    line-height: 32px;
  }
  html[lang=ar] .landing h3 {
    font-size: calc(24px + 1px);
  }
  .landing h4 {
    font-size: 20px;
    line-height: 28px;
  }
  html[lang=ar] .landing h4 {
    font-size: calc(20px + 1px);
  }
  .landing h5 {
    font-size: 18px;
    line-height: 26px;
  }
  html[lang=ar] .landing h5 {
    font-size: calc(18px + 1px);
  }
  .landing h6 {
    font-size: 16px;
    line-height: 24px;
  }
  html[lang=ar] .landing h6 {
    font-size: calc(16px + 1px);
  }
  .landing p, .landing li, .landing ol li {
    font-size: 15px;
    line-height: 24px;
  }
  html[lang=ar] .landing p, html[lang=ar] .landing li, html[lang=ar] .landing ol li {
    font-size: calc(15px + 1px);
  }
}
.landing img {
  display: block;
  width: 100%;
}
.landing p {
  color: black;
  text-transform: unset;
  margin: revert;
  font-size: 17px;
  line-height: 28px;
  margin: revert;
  font-family: Montserrat, sans-serif;
}
html[lang=ar] .landing p {
  font-size: calc(17px + 1px);
}
.landing p strong {
  color: var(--text-color) !important;
  font-size: 18px !important;
  color: black !important;
}
html[lang=ar] .landing p strong {
  font-size: calc(18px + 1px) !important;
}
.landing li {
  list-style-type: disc;
  list-style-position: inside;
  line-height: 2em;
  font-family: Montserrat, sans-serif;
  font-size: 17px;
  font-weight: 300;
}
html[lang=ar] .landing li {
  font-size: calc(17px + 1px);
}
.landing li strong {
  font-size: 18px !important;
}
html[lang=ar] .landing li strong {
  font-size: calc(18px + 1px) !important;
}
.landing ol li {
  font-size: 17px;
  line-height: 2em;
}
html[lang=ar] .landing ol li {
  font-size: calc(17px + 1px);
}
.landing ol li strong {
  font-size: 17px !important;
}
html[lang=ar] .landing ol li strong {
  font-size: calc(17px + 1px) !important;
}
.landing ul {
  margin-bottom: 2em;
}
.landing div.nextSection {
  margin-top: 70px;
}
.landing strong {
  font-weight: 700;
}
.landing th, .landing td {
  border: 1px solid white;
  padding: 1rem;
}

/* Styles for input fields with errors */
#formComponent {
  /* Wrapper for positioning */
  /* Tooltip styles */
  /* Tooltip arrow */
}
#formComponent input.error,
#formComponent textarea.error {
  border-color: red;
}
#formComponent .input-wrapper {
  position: relative;
}
#formComponent .input-wrapper input.error:hover::after,
#formComponent .input-wrapper textarea.error:hover::after,
#formComponent .input-wrapper input.error:focus::after,
#formComponent .input-wrapper textarea.error:focus::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -5px;
  right: -5px;
  transform: translateY(-100%);
  background-color: #f44336;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.9em;
  z-index: 10;
}
html[lang=ar] #formComponent .input-wrapper input.error:hover::after,
html[lang=ar] #formComponent .input-wrapper textarea.error:hover::after,
html[lang=ar] #formComponent .input-wrapper input.error:focus::after,
html[lang=ar] #formComponent .input-wrapper textarea.error:focus::after {
  font-size: calc(0.9em + 1px);
}
#formComponent .input-wrapper input.error:hover::before,
#formComponent .input-wrapper textarea.error:hover::before,
#formComponent .input-wrapper input.error:focus::before,
#formComponent .input-wrapper textarea.error:focus::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 10px;
  transform: translateY(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background-color: #f44336;
  z-index: 9;
}

/* Shake animation keyframes */
@keyframes shake {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-2px) rotate(-2deg);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(2px) rotate(2deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}
/* Shake class to trigger the animation */
.shake {
  animation: shake 0.5s;
}

/* Existing styles */
.langCurrSwitcher {
  z-index: 100;
  position: fixed;
  right: 1rem;
  overflow: hidden;
  top: 7rem;
  /* Ensure the shake animation doesn't interfere with other styles */
}
.langCurrSwitcher.ar {
  right: unset;
  left: 1rem;
}
@media all and (max-width: 992px) {
  .langCurrSwitcher {
    left: unset;
    right: 0;
    top: 8rem;
  }
  .langCurrSwitcher.ar {
    left: unset;
  }
}
.langCurrSwitcher .container .dropdown-button {
  cursor: pointer;
  padding: 0px 20px;
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.654px; /* 304.36% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
  background-color: #111823;
  border: 1px solid #CFAF9B;
}
html[lang=ar] .langCurrSwitcher .container .dropdown-button {
  font-size: calc(15px + 1px);
}
@media all and (max-width: 992px) {
  .langCurrSwitcher .container .dropdown-button {
    border-radius: 150px 0px 0px 150px;
    padding: 10px 10px 10px 10px;
    border-right: unset;
  }
  .langCurrSwitcher .container .dropdown-button.ar {
    border-radius: 0px 150px 150px 0px;
    padding: 10px 10px 10px 10px;
    border-left: unset;
  }
}
.langCurrSwitcher .container .dropdown-button .icon {
  margin-left: 0.5rem;
}
.langCurrSwitcher .container .dropdown-button .icon.ar {
  margin-left: unset;
  margin-right: 0.5rem;
}
@media all and (max-width: 992px) {
  .langCurrSwitcher .container .dropdown-button .icon {
    display: none;
    margin-left: unset;
  }
}
.langCurrSwitcher .container .dropdown-button .text {
  display: flex;
  align-items: center;
}
@media all and (max-width: 992px) {
  .langCurrSwitcher .container .dropdown-button .text {
    display: none;
  }
}
.langCurrSwitcher .container .dropdown-button .text .flag {
  margin-right: 0.5rem;
}
.langCurrSwitcher .container .dropdown-button .text .flag.ar {
  margin-right: unset;
  margin-left: 0.5rem;
}
.langCurrSwitcher .container .dropdown-button .flagMobile {
  display: none;
}
@media all and (max-width: 992px) {
  .langCurrSwitcher .container .dropdown-button .flagMobile {
    display: inline;
    margin-left: 15px;
  }
  .langCurrSwitcher .container .dropdown-button .flagMobile.ar {
    margin-left: unset;
    margin-right: 15px;
  }
}
.langCurrSwitcher .container .dropDown {
  padding: 0.5rem 1rem;
  background-color: #111823;
  color: white;
  border: 1px solid #CFAF9B;
}
.langCurrSwitcher .container .dropDown .listDropdownitems {
  padding-right: 1rem;
  padding-left: 1rem;
}
.langCurrSwitcher .container .dropDown .listDropdownitems.ar {
  text-align: right;
}
.langCurrSwitcher .container .dropDown .listDropdownitems .dropdown-item.ar {
  text-align: right;
}
.langCurrSwitcher .container .dropDown .listDropdownitems .dropdown-item .text {
  cursor: pointer;
  font-family: var(--lato-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 300%; /* 41.92px */
  letter-spacing: 1.6px;
}
html[lang=ar] .langCurrSwitcher .container .dropDown .listDropdownitems .dropdown-item .text {
  font-size: calc(16px + 1px);
}
.langCurrSwitcher .separator {
  width: 1px;
  height: 107px;
  background-color: rgba(207, 175, 155, 0.2);
  margin: 1rem 0;
}
.langCurrSwitcher .show {
  display: flex;
}
.langCurrSwitcher .hide {
  display: none;
}
.langCurrSwitcher.shake {
  /* Optional: Additional styles during shake */
}

div.landing_aboutDeveloper {
  max-width: 80rem;
  width: 90%;
  margin: auto;
  margin: 5rem auto;
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper {
    margin: 3rem auto;
  }
}
div.landing_aboutDeveloper div.landing_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper div.landing_wrapper {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}
div.landing_aboutDeveloper div.landing_wrapper .landing_parent {
  width: 50%;
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent {
    width: 100%;
  }
}
div.landing_aboutDeveloper div.landing_wrapper .landing_parent .landing_container {
  width: 86%;
  float: right;
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent .landing_container {
    width: 100%;
    float: unset;
  }
}
div.landing_aboutDeveloper div.landing_wrapper .landing_parent h2.landing_mainHeading {
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: black;
  font-weight: 700;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
html[lang=ar] div.landing_aboutDeveloper div.landing_wrapper .landing_parent h2.landing_mainHeading {
  font-size: calc(1.5rem + 1px);
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent h2.landing_mainHeading {
    font-size: 1.25rem;
    letter-spacing: 1.6px;
    line-height: 2rem;
  }
  html[lang=ar] div.landing_aboutDeveloper div.landing_wrapper .landing_parent h2.landing_mainHeading {
    font-size: calc(1.25rem + 1px);
  }
}
div.landing_aboutDeveloper div.landing_wrapper .landing_parent .landing_developer_description {
  text-align: left;
  width: 500px;
  color: black;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 31.768px; /* 158.839% */
  letter-spacing: 0.4px;
  text-transform: unset;
  width: 100%;
}
html[lang=ar] div.landing_aboutDeveloper div.landing_wrapper .landing_parent .landing_developer_description {
  font-size: calc(1.25rem + 1px);
}
div.landing_aboutDeveloper div.landing_wrapper .landing_parent .landing_developer_image {
  width: 250px;
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent .landing_developer_image {
    width: 40%;
  }
}
@media all and (max-width: 576px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent .landing_developer_image {
    width: 30%;
  }
}
div.landing_aboutDeveloper div.landing_wrapper .landing_parent.landing_imageParent {
  display: flex;
  justify-content: center;
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent.landing_imageParent {
    justify-content: start;
    margin-bottom: 3rem;
  }
}
@media all and (max-width: 1024px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent.landing_imageParent {
    margin-bottom: 2rem;
  }
}
div.landing_aboutDeveloper div.landing_wrapper .landing_parent .generalButton {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  text-align: center;
  margin-top: 2rem;
  letter-spacing: 1.6px;
  color: black;
}
@media all and (max-width: 576px) {
  div.landing_aboutDeveloper div.landing_wrapper .landing_parent .generalButton {
    letter-spacing: 0;
    width: 100%;
  }
}

.language-switcher {
  position: relative;
  display: inline-block;
  padding: 20px 20px 20px;
}
.language-switcher .switcher-button {
  border: 2px solid transparent;
  background: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.language-switcher .switcher-button .icon {
  margin-left: 10px;
  margin-bottom: 5px;
}
.language-switcher .switcher-button .icon.ar {
  margin-left: unset;
  margin-right: 10px;
  margin-bottom: unset;
}
.language-switcher .switcher-button .flag {
  margin-right: 10px;
  padding-bottom: 2px;
}
.language-switcher .switcher-button .flag.ar {
  margin-right: unset;
  margin-left: 10px;
}
.language-switcher .switcher-button .text {
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: var(--audrey-font-family);
  text-transform: uppercase;
}
html[lang=ar] .language-switcher .switcher-button .text {
  font-size: calc(14px + 1px);
}
.language-switcher .switcher-dropdown {
  position: absolute;
  width: 15rem;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-bg-color);
}
.language-switcher .switcher-dropdown.ar {
  left: unset;
  right: 50%;
  transform: translateX(-15%);
}
.language-switcher .switcher-dropdown .switcher-option {
  text-align: center;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  font-family: var(--lato-font-family);
  cursor: pointer;
  color: var(--light-text-color);
  display: block;
  width: 100%;
  padding: 12px 20px;
  letter-spacing: 2px;
  font-weight: 500;
}
html[lang=ar] .language-switcher .switcher-dropdown .switcher-option {
  font-size: calc(14px + 1px);
}
.language-switcher .switcher-dropdown .switcher-option:hover {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  color: white;
}

/* RTL Adjustments */
[dir=rtl] .language-switcher .switcher-dropdown {
  right: auto;
  left: 0;
}

.language-switcher-mobile {
  width: 100%;
  margin-right: 1rem;
}
.language-switcher-mobile.ar {
  margin-right: unset;
  margin-left: 1rem;
}
.language-switcher-mobile .switcher-button {
  display: flex;
  background: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.language-switcher-mobile .switcher-button .icon {
  margin-left: 5px;
}
.language-switcher-mobile .switcher-button .icon.ar {
  margin-left: unset;
  margin-right: 5px;
}
.language-switcher-mobile .switcher-button .flag {
  margin-right: 5px;
  padding-bottom: 2px;
}
.language-switcher-mobile .switcher-button .flag.ar {
  margin-right: unset;
  margin-left: 5px;
}
.language-switcher-mobile .switcher-button .text {
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: var(--audrey-font-family);
  text-transform: uppercase;
}
.language-switcher-mobile .switcher-dropdown {
  position: absolute;
  top: 3rem;
  background-color: var(--primary-bg-color);
}
.language-switcher-mobile .switcher-dropdown .switcher-option {
  text-align: center;
  font-size: 12px;
  padding: 1rem 1.5rem;
  font-family: var(--lato-font-family);
  cursor: pointer;
  color: var(--text-color);
  display: block;
  width: 100%;
  letter-spacing: 2px;
  font-weight: 400;
}
html[lang=ar] .language-switcher-mobile .switcher-dropdown .switcher-option {
  font-size: calc(12px + 1px);
}
.language-switcher-mobile .switcher-dropdown .switcher-option:nth-of-type(1) {
  border-bottom: unset;
}
.language-switcher-mobile .switcher-dropdown .switcher-option.ar {
  text-align: right;
}
.language-switcher-mobile .switcher-dropdown .switcher-option:hover {
  background: linear-gradient(262deg, rgb(172, 131, 109) 28%, rgb(240, 202, 178) 70%);
  color: white;
}

/* RTL Adjustments */
[dir=rtl] .language-switcher-mobile .switcher-dropdown {
  right: auto;
}

div.secondarySearchFields div.allFields div._commonContainer {
  width: 15%;
  margin-right: 20px;
  padding: 15px 8px;
  border: 1px solid var(--border-color);
  position: relative;
  cursor: pointer;
}
div.secondarySearchFields div.allFields div._commonContainer.ar {
  margin-right: unset;
  margin-left: 20px;
}
@media all and (max-width: 1600px) {
  div.secondarySearchFields div.allFields div._commonContainer {
    margin-right: 10px;
    width: 18%;
  }
}
@media all and (max-width: 1400px) {
  div.secondarySearchFields div.allFields div._commonContainer {
    width: 200px !important;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 768px) {
  div.secondarySearchFields div.allFields div._commonContainer {
    width: 100% !important;
    margin-bottom: 15px;
    margin-right: 0;
  }
  div.secondarySearchFields div.allFields div._commonContainer.ar {
    margin-left: unset;
  }
}

.developer {
  margin-bottom: 100px !important;
  margin-top: 150px !important;
}
.developer .___title {
  margin-bottom: 3rem;
  font-size: 32px;
}
html[lang=ar] .developer .___title {
  font-size: calc(32px + 1px);
}
.developer .mobileLogo {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  background-color: white;
  padding: 1rem;
  border-width: 3px;
  justify-self: center;
  width: 150px;
}
.developer .isMobile {
  display: none;
}
.developer ._container {
  display: flex;
  flex-flow: wrap;
  margin-top: 3.5rem;
  column-gap: 15px;
  row-gap: 35px;
  justify-content: center;
}
@media (max-width: 576px) {
  .developer ._container {
    margin-top: 2.5rem;
  }
}
.developer .responsive {
  width: 31.5%;
}
@media (max-width: 1500px) {
  .developer .responsive {
    width: 45.5%;
  }
}
@media (max-width: 980px) {
  .developer .responsive {
    width: 48%;
  }
}
@media (max-width: 768px) {
  .developer .responsive {
    width: 100%;
  }
}
.developer .__top {
  margin-top: 50px;
  color: white;
  margin-bottom: 3rem;
}
.developer .__top .seperator {
  height: 1px;
  margin-top: 1rem;
  border: 1px solid #4D5462;
}
.developer .__top .__title {
  color: #FFF;
  text-align: center;
  font-family: var(--audrey-font-family);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.654px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
html[lang=ar] .developer .__top .__title {
  font-size: calc(30px + 1px);
}
.developer .__top .__subtitle {
  color: #FFF;
  text-align: center;
  font-family: var(--lato-font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-bottom: 5rem;
  width: 80%;
}
html[lang=ar] .developer .__top .__subtitle {
  font-size: calc(20px + 1px);
}
.developer .__top .__topProjects {
  margin-top: 5rem;
  color: white;
  text-align: center;
}
@media all and (max-width: 576px) {
  .developer .__top .__topProjects {
    margin-top: 3rem;
    text-align: left;
  }
}
.developer .__top .ctaBtnParent {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
@media all and (max-width: 768px) {
  .developer .__top .ctaBtnParent {
    margin-top: 3rem;
  }
}
.developer .__top .ctaBtnParent .ctaBtn {
  width: -moz-fit-content;
  width: fit-content;
}
@media all and (max-width: 768px) {
  .developer .__top .ctaBtnParent .ctaBtn {
    padding: 1rem;
  }
}

@media (max-width: 1200px) {
  .developer .isDesktop {
    display: none;
  }
  .developer .isMobile {
    display: grid;
  }
  .developer .__top .__seperator {
    display: none;
  }
  .developer .__top .__subtitle {
    line-height: 25px;
    letter-spacing: 1.28px;
    margin-bottom: 3rem;
    font-size: 16px;
    margin-bottom: unset;
    width: 100%;
    width: calc(91% + 15px);
    text-align: center;
  }
  html[lang=ar] .developer .__top .__subtitle {
    font-size: calc(16px + 1px);
  }
  .developer .__top .container .sectionTitle {
    width: 100% !important;
  }
  .developer .__top .container .parent {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .developer .__top .__title {
    font-size: 24px;
    letter-spacing: 1.28px;
    line-height: 20px;
  }
  html[lang=ar] .developer .__top .__title {
    font-size: calc(24px + 1px);
  }
  .developer .__top .__subtitle {
    line-height: 30px;
    font-size: 16px;
    letter-spacing: 1.28px;
    margin-bottom: unset;
    text-align: left;
    width: unset;
    margin-top: 1rem;
  }
  html[lang=ar] .developer .__top .__subtitle {
    font-size: calc(16px + 1px);
  }
  .developer .__top .__subtitle.ar {
    text-align: right;
  }
}
body {
  /* Customize scrollbar width and color */
}
body ::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
}
body ::-webkit-scrollbar-track {
  background-color: var(--primary-bg-color);
}
body ::-webkit-scrollbar-thumb {
  background: radial-gradient(circle, rgb(255, 236, 224) 0%, rgb(240, 202, 178) 35%, rgb(172, 131, 109) 100%); /* Radial gradient background */
  border-radius: 6px; /* Rounded corners */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Inner shadow for depth */
}

@media all and (min-width: 992px) {
  .offplanSortBy.ar {
    width: unset;
  }
}
.offplanSortBy.secondarySearchFields div.allFields div._commonContainer {
  width: unset !important;
  margin-right: 20px !important;
  padding: 15px 8px;
  border: 1px solid var(--border-color);
  position: relative;
  cursor: pointer;
}
.offplanSortBy.secondarySearchFields div.allFields div._commonContainer.ar {
  margin-right: unset;
  margin-left: 20px;
}
@media all and (max-width: 1600px) {
  .offplanSortBy.secondarySearchFields div.allFields div._commonContainer {
    margin-right: 10px;
    width: 18%;
  }
}
@media all and (max-width: 1400px) {
  .offplanSortBy.secondarySearchFields div.allFields div._commonContainer {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 768px) {
  .offplanSortBy.secondarySearchFields div.allFields div._commonContainer {
    width: 100% !important;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .offplanSortBy.secondarySearchFields div.allFields div._commonContainer.ar {
    margin-left: unset;
  }
}
.offplanSortBy.secondarySearchFields div.allFields div._commonContainer .inputField .selectedValue {
  margin-right: 2rem;
}
.offplanSortBy.secondarySearchFields div.allFields div._commonContainer .inputField .selectedValue.ar {
  margin-right: unset;
  margin-left: 2rem;
}/*# sourceMappingURL=app.css.map */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
}

html[lang="ar"] * {
  letter-spacing: normal !important;
    --lato-font-family: Cairo;
    --audrey-font-family: Cairo;
}

:root {
  --primary-bg-color:#121823;
  --secondary-bg-color:#141b27;
  --tertiary-color:rgb(240, 202, 178);
  --secondary-border-color:#8E8E8E;
  --dark-blue:#0E141F;
  --main-heading-color:#71B4E3;
  --text-color:#fff;
  --light-text-color:rgb(196, 193, 193);
  --placeholder-color:#717171;
  --link-color:#8A8A8A;
  --lato-font-family:Lato,Helvetica, sans-serif;
  --audrey-font-family:Audrey,Helvetica, sans-serif;
  --btn-text-color:#fff;
  --border-color:#4D5462;
  --error-color:rgb(254, 100, 111);
}

a {
  text-decoration: none;
  color: var(--text-color);
}

button{
  border: none;
  outline: none;
}

input{
  outline: none;
  border: none;
}

/* lato font  */
@font-face {
  font-family: "Lato";
  font-weight: 300;
  src: url(/_next/static/media/Lato-Light_1.fcc41617.woff) format("woff"), url(/_next/static/media/Lato-Light.d28145fd.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: url(/_next/static/media/Lato-Regular_1.79029282.woff) format("woff"), url(/_next/static/media/Lato-Regular.c44e96b6.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-weight: 500;
  src: url(/_next/static/media/Lato-Medium_1.a17e80e3.woff) format("woff"), url(/_next/static/media/Lato-Medium.5c86501b.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-weight: 600;
  src: url(/_next/static/media/Lato-Semibold_1.10fe7fea.woff) format("woff"), url(/_next/static/media/Lato-Semibold.abe70492.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-weight: 700;
  src: url(/_next/static/media/Lato-Bold.df2fb385.woff) format("woff"), url(/_next/static/media/Lato-Bold.c1f9f7fb.ttf) format("truetype");
  font-display: swap;
}

/* Audrey font  */
@font-face {
  font-family: "Audrey";
  font-weight: 400;
  src:url(/_next/static/media/Audrey-Normal.59273358.woff2) format("woff2"), url(/_next/static/media/Audrey-Normal.e5060dac.woff) format("woff"), url(/_next/static/media/Audrey-Normal.feb0c2c4.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Audrey";
  font-weight: 500;
  src:url(/_next/static/media/Audrey-Medium.281a1d58.woff2) format("woff2"), url(/_next/static/media/Audrey-Medium.63d7452f.woff) format("woff"), url(/_next/static/media/Audrey-Medium.4b2f28a6.ttf) format("truetype");
  font-display: swap;
}

h1.mainHeading, h2.mainHeading, h3.mainHeading {
  color: var(--main-heading-color);
  font-size: 25px;
  font-family: var(--audrey-font-family);
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;

}
.gradientText {
  background: linear-gradient(to right, #F0CAB2 44%, #AC836D 53%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
p{
  color: var(--light-text-color);
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
}
button.globalBtn, a.globalBtn{
  width: 10rem;
  width: 13rem;
  padding: 18px 15px;
  background-color: transparent;
  color: var(--btn-text-color);
  letter-spacing: 1.6px;
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(
    circle,
    rgba(255, 236, 224, 1) 0%,
    rgba(240, 202, 178, 1) 35%,
    rgba(172, 131, 109, 1) 100%
  );
  border-image-slice: 1;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--lato-font-family);
}

.gradientBorder{
  border: 1px solid transparent; /* Set the border width and make it transparent */
  border-image: radial-gradient(
    circle,
    rgba(255, 236, 224, 1) 0%,
    rgba(240, 202, 178, 1) 35%,
    rgba(172, 131, 109, 1) 100%
  );
  border-image-slice: 1;
}

.wrapper{
  width: 85%;
  margin: 0 auto;
  max-width: 1600px;
}
html{
  scroll-behavior: smooth;
}
h2.noData {
  text-align: center;
  color: var(--main-heading-color);

}
body{
  font-family: var(--lato-font-family);
  letter-spacing: 1.6px;
  background-color: var(--secondary-bg-color);
}

@keyframes fadeIn{
  0%{
      opacity: 0;
  }100%{
      opacity: 1;
  }
}

@media all and (max-width:768px){
  h1.mainHeading, h2.mainHeading, h3.mainHeading {
    font-size: 21px;
  }
}
@media all and (max-width:360px){
  h1.mainHeading, h2.mainHeading, h3.mainHeading {
    font-size: 16px;
  }
 div.wrapper{
  width: 95%;
 }
}
.noScroll {
  overflow: hidden;
}

/* For RTL-specific adjustments */
[dir='rtl'] {
  flex-direction: row-reverse;
}

.PhoneInputCountrySelectArrow {
  color: white;
}

.PhoneInputCountrySelect {
  color: black !important;
}

.breadcrumb {
  color: white !important;
  font-family: var(--lato-font-family)
}

.breadcrumb.focus {
  font-weight: 600;
}
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.64d639f6.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

